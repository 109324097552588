export const DELAY_MOVE = 300;
export const DELAY_CPU_MOVE = 2000;
export const DELAY_CPU_THINKING = 1000;
export const DELAY_MODAL = 300;
export const MODE_SINGLE_PLAYER = 'single-player';
export const MODE_TWO_PLAYER = 'two-player';

const ASYNC_STORAGE_PREFIX = '@chess-game';
export const ASYNC_STORAGE_SINGLE_PLAYER = `${ASYNC_STORAGE_PREFIX}:${MODE_SINGLE_PLAYER}`
export const ASYNC_STORAGE_TWO_PLAYER = `${ASYNC_STORAGE_PREFIX}:${MODE_TWO_PLAYER}`;
export const ASYNC_STORAGE_SETTINGS = `${ASYNC_STORAGE_PREFIX}:settings`;
export const ASYNC_STORAGE_GAMES = `${ASYNC_STORAGE_PREFIX}:games`;

// iPhone 13 Pro Max dimensions
export const MAX_PHONE_SCREEN_DIMENSIONS = {
  width: 428,
  height: 926
};

export const INITIAL_GAMES = [];
export const INITIAL_SETTINGS = {
  difficulty: 'easy',
  flipBoard: true,
  debugMode: false,
}

export const DIFFICULTY_OPTIONS = ['easy', 'normal', 'hard'];


export const COLOR_WHITE = 'w';
export const COLOR_BLACK = 'b';

export const PIECE_PAWN = 'p';
export const PIECE_KNIGHT = 'n';
export const PIECE_BISHOP = 'b';
export const PIECE_ROOK = 'r';
export const PIECE_QUEEN = 'q';
export const PIECE_KING = 'k';

export const BOARD_LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
export const BOARD_NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8].reverse();

export const ROUTE_NAME_GAME = 'Game';

