import Chess from "chess.js";
import { createReducer } from "react-use";
import logger from 'redux-logger';
import thunk from 'redux-thunk';

const INITIAL_STATE = {
  pgn: '',
  history: [],
  currentIndex: -1
}

const ACTION_SET_PGN = "ACTION_SET_PGN";
const ACTION_SET_MOVE_INDEX = "ACTION_SET_MOVE_INDEX";
const ACTION_SET_HISTORY = "ACTION_SET_HISTORY";

function reducer(state, action) {
  switch (action.type) {
    case ACTION_SET_PGN:
      return {
        ...state,
        pgn: action.payload
      };
      case ACTION_SET_MOVE_INDEX:
        return {
          ...state,
          currentIndex: action.payload
        };
    case ACTION_SET_HISTORY:
      return {
        ...state,
        history: action.payload
      };
    default:
      throw new Error();
  }
}

const useThunkReducer = createReducer(
  thunk,
  logger
);

export const setPgn = (pgn) => {
  return async (dispatch) => {
    dispatch({ type: ACTION_SET_PGN, payload: pgn });
    const chess = new Chess();
    chess.load_pgn(pgn);
    const history = chess.history({ verbose: true });

    dispatch({ type: ACTION_SET_HISTORY, payload: history });
    dispatch({ type: ACTION_SET_MOVE_INDEX, payload: history.length - 1 });
  }
}

export const setCurrentIndex = (currentIndex) => {
  return async (dispatch) => {
    dispatch({ type: ACTION_SET_MOVE_INDEX, payload: currentIndex });
  }
}

export const useGameState = (pgn = "") => {
  return useThunkReducer(reducer, {
    ...INITIAL_STATE,
    pgn
  })
};