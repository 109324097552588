import React from 'react';
import { View, Pressable } from 'react-native-web';
import { BOARD_LETTERS as letters, BOARD_NUMBERS as numbers } from '../../constants';
import PieceReadOnly from './PieceReadOnly';

export default function LayerPiecesNext({
  pieceStyles = {},
  board,
  squareSize,
  selectedSquare,
  pendingMove,
  castleMove,
  turn,
  onSelect = () => {}
}) {
  return (
    <View style={{ position: 'absolute' }}>
      {board.map((row, numberIndex) => (
        <View key={numbers[numberIndex]} style={{ flexDirection: 'row', position: 'absolute' }}>
          {row.map((piece, letterIndex) => {
            const squareName = letters[letterIndex] + numbers[numberIndex];
            if (squareName === selectedSquare) {
              return null;
            }

            // When pendingMove, hide piece because it's overlayed with animated square
            if (squareName === pendingMove?.from) {
              return null;
            }

            // Hide piece, because it will be captured.
            if (squareName === pendingMove?.to) {
              return null;
            }

            if (squareName === castleMove?.to) {
              return null;
            }

            if (squareName === castleMove?.from) {
              return null;
            }

            if (!piece) {
              return null;
            }

            return (
              <Pressable
                style={{
                  position: 'absolute',
                  left: letterIndex * squareSize,
                  top: numberIndex * squareSize,
                  width: squareSize,
                  height: squareSize,
                }}
                onPress={() => {
                  if (turn !== piece.color) {
                    return;
                  }
                  onSelect({ from: squareName })
                }}
              >
                <PieceReadOnly
                  key={squareName}
                  type={piece.type}
                  color={piece.color}
                  size={squareSize}
                  style={{
                    ...pieceStyles
                  }}
                />
              </Pressable>
            );
          })}
        </View>
      ))}
    </View>
  )
}