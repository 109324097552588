import React from 'react';
import { View } from 'react-native-web';
import { useDesignSystem, useMaxContentWidth, useScreenData } from '../hooks';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ThemeContext } from '../contexts/ThemeContext';

export default function Card({ style, children }) {
  const { isPhone } = useScreenData();
  const maxContentWidth = useMaxContentWidth();
  const Tokens = useDesignSystem();
  const Theme = React.useContext(ThemeContext);
  const safeAreaInset = useSafeAreaInsets()

  return (
    <View
      style={[
        {
          padding: Tokens.Dimensions.Standard,
          paddingBottom: Tokens.Dimensions.Standard + safeAreaInset.bottom,
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          background: Theme.Colors.Foreground
        },
        !isPhone && {
          borderRadius: 10,
          width: maxContentWidth - Tokens.Dimensions.Standard * 2
        },
        style
      ]}
    >
      {children}
    </View>
  )
}