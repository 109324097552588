import React from 'react';

import {
  createStackNavigator,
  CardStyleInterpolators
} from '@react-navigation/stack';
import SettingsScreen from '../screens/SettingsScreen';
import SettingsGameScreen from '../screens/SettingsGameScreen';
import SettingsDisplayScreen from '../screens/SettingsDisplayScreen';
import SettingsDesignSystemScreen from '../screens/SettingsDesignSystemScreen';

// Foundations
import TypographyScreen from '../screens/DesignSystem/FoundationTypographyScreen';
import ColorsScreen from '../screens/DesignSystem/FoundationColorsScreen';
import DimensionsScreen from '../screens/DesignSystem/FoundationDimensionsScreen';

// Components
import ComponentGameScreen from '../screens/DesignSystem/ComponentGameScreen';
import ComponentChessboardScreen from '../screens/DesignSystem/ComponentChessboardScreen';
import ComponentConfettiScreen from '../screens/DesignSystem/ComponentConfettiScreen';

import ComponentIconButtonScreen from '../screens/DesignSystem/ComponentIconButtonScreen';
import ComponentButtonScreen from '../screens/DesignSystem/ComponentButtonScreen';
import ComponentIconScreen from '../screens/DesignSystem/ComponentIconScreen';
import ComponentAvatarScreen from '../screens/DesignSystem/ComponentAvatarScreen';
import ComponentActionSheetScreen from '../screens/DesignSystem/ComponentActionSheetScreen';
import ComponentContentSheetScreen from '../screens/DesignSystem/ComponentContentSheetScreen';
import ComponentTableScreen from '../screens/DesignSystem/ComponentTableScreen';

import PlaygroundScreen from '../screens/PlaygroundScreen';
import PlaceholderScreen from '../screens/PlaceholderScreen';
import { useDesignSystem } from '../hooks';
import { ThemeContext } from '../contexts/ThemeContext';
import IconButton from '../components/IconButton';

const SettingsStack = createStackNavigator();
export default function SettingsStackScreens() {
  const Tokens = useDesignSystem();
  const Theme = React.useContext(ThemeContext)

  return (
    <SettingsStack.Navigator
      screenOptions={{
        cardStyle: {
          backgroundColor: Theme.Colors.Background,
        },
        headerTitleAlign: 'center',
        headerShown: true,
        headerLeft:({ onPress }) => (
          <IconButton
            type="arrow.left"
            onPress={onPress}
            style={{
              marginTop: Theme.Dimensions.Small,
              marginLeft: Theme.Dimensions.Small
            }}
          />
        ),
        // headerMode: 'float',
        headerStyle: {
          backgroundColor: 'transparent',
          borderBottomWidth: 0,
        },
        headerTintColor: Theme.Colors.Label,
        headerTitleStyle: {
          ...Tokens.Typography.BodyLarge,
          fontWeight: 'bold',
          color: Theme.Colors.Label,
        },
        // headerTransparent: true,
        animationEnabled: true,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
      }}
    >
      <SettingsStack.Screen
        name="SettingsHome"
        component={SettingsScreen}
        options={{
          title: "Settings",
          headerLeft:({ onPress }) => (
            <IconButton
              type="xmark"
              onPress={onPress}
              style={{
                marginTop: Theme.Dimensions.Small,
                marginLeft: Theme.Dimensions.Small
              }}
            />
          ),
        }}
      />
      <SettingsStack.Screen
        name="SettingsGame"
        component={SettingsGameScreen}
        options={{ title: "Game" }}
      />
      <SettingsStack.Screen
        name="SettingsDisplay"
        component={SettingsDisplayScreen}
        options={{ title: "Display" }}
      />
      <SettingsStack.Screen
        name="Support"
        component={PlaceholderScreen}
      />
      <SettingsStack.Screen
        name="About"
        component={PlaceholderScreen}
      />
      <SettingsStack.Screen
        name="Playground"
        component={PlaygroundScreen}
        options={{ title: "Playground" }}
      />
      <SettingsStack.Screen
        name="DesignSystem"
        component={SettingsDesignSystemScreen}
        options={{ title: "Design System" }}
      />
      <SettingsStack.Screen
        name="DesignSystemTypography"
        component={TypographyScreen}
        options={{ title: "Typography" }}
      />
      <SettingsStack.Screen
        name="DesignSystemColors"
        component={ColorsScreen}
        options={{ title: "Colors" }}
      />
      <SettingsStack.Screen
        name="DesignSystemDimensions"
        component={DimensionsScreen}
        options={{ title: "Dimensions" }}
      />

      <SettingsStack.Screen
        name="ComponentGame"
        component={ComponentGameScreen}
        options={{ title: "Game" }}
      />
      <SettingsStack.Screen
        name="ComponentChessboard"
        component={ComponentChessboardScreen}
        options={{ title: "Chessboard" }}
      />
      <SettingsStack.Screen
        name="ComponentConfetti"
        component={ComponentConfettiScreen}
        options={{ title: "Confetti" }}
      />
      <SettingsStack.Screen
        name="ComponentButton"
        component={ComponentButtonScreen}
        options={{ title: "Button" }}
      />
      <SettingsStack.Screen
        name="ComponentIconButton"
        component={ComponentIconButtonScreen}
        options={{ title: "IconButton" }}
      />
      <SettingsStack.Screen
        name="ComponentIcon"
        component={ComponentIconScreen}
        options={{ title: "Icon" }}
      />
      <SettingsStack.Screen
        name="ComponentAvatar"
        component={ComponentAvatarScreen}
        options={{ title: "Avatar" }}
      />
      <SettingsStack.Screen
        name="ComponentActionSheet"
        component={ComponentActionSheetScreen}
        options={{ title: "ActionSheet" }}
      />
      <SettingsStack.Screen
        name="ComponentContentSheet"
        component={ComponentContentSheetScreen}
        options={{ title: "ContentSheet" }}
      />
      <SettingsStack.Screen
        name="ComponentTable"
        component={ComponentTableScreen}
        options={{ title: "Table" }}
      />
    </SettingsStack.Navigator>
  )
}