import React from 'react';
import { Pressable } from 'react-native-web';
import Animated from 'react-native-reanimated';
import { useScreenData } from '../hooks';
import { ThemeContext } from '../contexts/ThemeContext';

export default function Overlay({ dismissOnPress, onPress, children, style = {} }) {
  const ref = React.useRef();
  const { isPhone } = useScreenData();
  const Theme = React.useContext(ThemeContext);
  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          userSelect: 'none',
          backgroundColor: Theme.Colors.Overlay,
          zIndex: 10,
        },
        style
      ]}
    >
      <Pressable
        style={[
          {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            flex: 1,
            justifyContent: 'flex-end',
            zIndex: 100
          },
          !isPhone && {
            justifyContent: 'center',
            alignItems: 'center'
          },
          !dismissOnPress && {
            cursor: 'auto'
          }
        ]}
        ref={ref}
        onPress={(e) => {
          // only capture overlay events
          if (e.target === ref.current) {
            onPress();
          }
        }}
      >
      {children}
      </Pressable>
    </Animated.View>
  )
}