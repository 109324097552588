import React from 'react';
import { View, SectionList, Pressable } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/elements';

import Text from '../primitives/Text';
import {
  addGame,
  useSharedState
} from '../state';
import Game from '../components/Game';
import EmojiAvatar from '../components/EmojiAvatar';
import { COLOR_BLACK, COLOR_WHITE, MODE_SINGLE_PLAYER, MODE_TWO_PLAYER } from '../constants';
import moment from 'moment';
import Chess from 'chess.js';
import { ContentSheetContext } from '../contexts/ContentSheetContext';
import { createGameData } from '../utils';
import { ThemeContext } from '../contexts/ThemeContext';
import VStack from '../primitives/VStack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import IconButton from '../components/IconButton';
import Button from '../components/Button';
import useContentSheet from '../hooks/useContentSheet';

export default function HomeScreen() {
  const [sharedState, dispatch] = useSharedState();
  const { games, settings, profile } = sharedState;
  const navigation = useNavigation();
  const safeAreaInset = useSafeAreaInsets();
  const Theme = React.useContext(ThemeContext);
  const { showContentSheet } = React.useContext(ContentSheetContext);
  const { showProfileDialog } = useContentSheet();
  const headerHeight = useHeaderHeight();

  const sectionListData = [{
    title: 'Active Games',
    data: games.filter(it => !it.dateCompleted)
      .sort((a, b) => {
        if (a.dateModified > b.dateModified) {
          return 1;
        }

        if (a.dateModified < b.dateModified) {
          return -1;
        }

        return 0;
      })
      .reverse()
  }, {
    title: 'Game Over',
    data: games.filter(it => !!it.dateCompleted)
      .sort((a, b) => {
        if (a.dateCompleted > b.dateCompleted) {
          return 1;
        }

        if (a.dateCompleted < b.dateCompleted) {
          return -1;
        }

        return 0;
      })
      .reverse()
  }];

  return (
    <VStack style={{
      position: 'relative',
      flex: 1,
      paddingTop: headerHeight
    }}>
      <Pressable
        style={{
          position: 'absolute',
          padding: 0,
          top: Theme.Dimensions.Standard,
          right: Theme.Dimensions.Standard,
          zIndex: 2,
          borderRadius: 40,
          ...Theme.Shadows.Standard,
        }}
        onPress={() => {
          showProfileDialog({ showActions: true, editable: true })
        }}
      >
        <EmojiAvatar
          size={40}
          emoji={profile.emoji}
          color={profile.color}
        />
      </Pressable>
        <SectionList
          stickySectionHeadersEnabled
          maintainVisibleContentPosition={{ minIndexForVisible: 0 }} // fixes jumping on iOS
          sections={sectionListData}
          keyExtractor={(item, index) => item + index}
          style={{
            paddingBottom: safeAreaInset.bottom
          }}
          renderSectionHeader={({ section: { title, data } }) => (
            data.length > 0 && (
              <SectionHeader
                key={title}
                title={title}
                data={data}
              />
            )
          )}
          renderItem={({ item, index }) => {
            const isGameOver = item.dateCompleted;
            const chess = new Chess();
            chess.load_pgn(item?.pgn);
            const turn = chess.turn();
            const winner = turn === COLOR_BLACK ? COLOR_WHITE : COLOR_BLACK;
            const isWinner = winner === COLOR_WHITE;
            return (
              <Game
                key={item.id}
                title={isGameOver
                  ? isWinner ? 'Win' : 'Loss'
                  : item?.mode === MODE_SINGLE_PLAYER
                    ? 'Your Turn'
                    : turn === COLOR_WHITE ? 'Your Turn' : `Opponent's Turn`
                }
                body={isGameOver
                  ? `completed ${moment(item.dateCompleted).startOf('minute').fromNow()}`
                  : item.pgn === ""
                    ? `started ${moment(item.dateCreated).startOf('minute').fromNow()}`
                    : `last played ${moment(item.dateModified).startOf('minute').fromNow()}`
                }
                pgn={item.pgn}
                id={item.id}
                mode={item?.mode}
                isDeletable={!!item?.dateCompleted}
                dateFormat={settings.dateFormat}
              />
            )
          }}
        />
        <IconButton
          size={IconButton.Size.Large}
          type={IconButton.Type.Plus}
          tint={Theme.SystemColors.White}
          backgroundColor={Theme.Colors.Primary}
          style={{
            position: 'absolute',
            bottom: Theme.Dimensions.Large,
            right: Theme.Dimensions.Standard,
          }}
          onPress={() => {
            showContentSheet(
              "New Game",
              "Select a game mode",
              [
                {
                  title: "Single Player",
                  type: Button.Type.Secondary,
                  onPress: () => {
                    handleRouteToGame(
                      navigation,
                      MODE_SINGLE_PLAYER,
                      null,
                      dispatch
                    )
                  }
                },
                {
                  title: "Two Player",
                  type: Button.Type.Secondary,
                  onPress: () => {
                    handleRouteToGame(
                      navigation,
                      MODE_TWO_PLAYER,
                      null,
                      dispatch
                    );
                  }
                },
                {
                  title: "Cancel",
                  type: Button.Type.Tertiary
                }
              ]
            );
          }}
        />
    </VStack>
  )
}

function SectionHeader({ title, data }) {
  const Theme = React.useContext(ThemeContext);

  return (
    <View style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: -1,
      padding: Theme.Dimensions.Standard,
      backgroundColor: Theme.Colors.Background
    }}>
      <Text style={{
        ...Theme.Typography.Heading,
        color: Theme.Colors.Label
      }}>
        {`${title}`}
      </Text>

      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: Theme.Dimensions.Smallest,
        paddingHorizontal: Theme.Dimensions.Smaller,
        borderRadius: 12,
      }}>
        <Text style={{
          ...Theme.Typography.Body,
          color: Theme.Colors.SecondaryLabel,
          fontWeight: 'bold',

        }}>
          {data.length}
        </Text>
      </View>
    </View>
  )
}

function handleRouteToGame(navigation, mode, activeGame, dispatch) {
  if (!activeGame) {
    const game = createGameData({
      mode,
      color: 'w'
    });

    dispatch(addGame(game));

    // Route to game
    navigation.push('Game', { id: game.id });

    return;
  }

  navigation.push('Game', { id: activeGame.id });
}