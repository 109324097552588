import React from 'react';
import Text from '../primitives/Text';
import VStack from '../primitives/VStack';
import { ThemeContext } from '../contexts/ThemeContext';
import HStack from '../primitives/HStack';
import IconButton from './IconButton';
import Separator from './Separator';
import EmojiAvatar from './EmojiAvatar';
import { ContentSheetContext } from '../contexts/ContentSheetContext';
import useContentSheet from '../hooks/useContentSheet';
import { useSharedState } from '../state';

export default function Profile({
  name,
  email,
  winCount,
  lossCount,
  drawCount,
  editable = false,
  // navigation
}) {
  const [sharedState] = useSharedState();
  const { profile: { color, emoji }} = sharedState;
  const Theme = React.useContext(ThemeContext);
  const { dismissContentSheet } = React.useContext(ContentSheetContext);
  const { showEditProfileDialog } = useContentSheet();

  return (
    <VStack>
      <VStack style={{
        alignItems: 'center'
      }}>
        <VStack style={{
          position: 'relative',
        }}>
          <EmojiAvatar
            emoji={emoji}
            color={color}
            style={{
              marginBottom: Theme.Dimensions.Small
            }}
          />
          {editable && (
            <IconButton
              onPress={() => {
                dismissContentSheet()
                // Setup a callback on dismissContentSheet so that we don't need setTimeouts to sequence animations
                setTimeout(() => {
                  showEditProfileDialog()
                  // navigation.navigate('EditProfile');
                }, 300)
              }}
              type={IconButton.Type.Pencil}
              size={IconButton.Size.Small}
              style={{
                position: 'absolute',
                bottom: 20,
                right: -4,
                borderRadius: 28,
                ...Theme.Shadows.Standard
              }}
            />
          )}
        </VStack>
        <Text
          style={{
            ...Theme.Typography.Title
          }}
        >
          {name}
        </Text>
        <Text
          style={{
            ...Theme.Typography.Subtitle,
            color: Theme.Colors.SecondaryLabel
          }}
        >
          {email}
        </Text>
      </VStack>
      <HStack style={{
        paddingTop: Theme.Dimensions.Standard,
        justifyContent: 'center',
      }}>
        <ProfileStat label="Wins" value={winCount} />
        <Separator />
        <ProfileStat label="Losses" value={lossCount} />
        <Separator />
        <ProfileStat label="Draws" value={drawCount} />
      </HStack>
    </VStack>
  )
}

function ProfileStat({
  label,
  value
}) {
  const Theme = React.useContext(ThemeContext);

  return (
    <VStack style={{
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: Theme.Dimensions.Larger / 2
    }}>
      <Text style={{
        ...Theme.Typography.Heading
      }}>{value}</Text>
      <Text style={{
        ...Theme.Typography.BodySmall,
        color: Theme.Colors.SecondaryLabel
      }}>{label}</Text>
    </VStack>
  )
}