import React from 'react';
import { ScrollView, View } from "react-native-web";
import Table from "../../components/Table"
import { ThemeContext } from "../../contexts/ThemeContext";

export default function ColorScreen() {
  const Theme = React.useContext(ThemeContext);

  const sections = [{
    title: 'Theme',
    colors: Theme.Colors
  }, {
    title: 'System Colors',
    colors: Theme.SystemColors
  }]

  return (
    <ScrollView style={{
      paddingTop: Theme.Dimensions.Large
    }}>
      {sections.map((it) => (
        <View key={it.title} style={{
          marginBottom: Theme.Dimensions.Large
        }}>
          <Table type="inset" title={it.title}>
            {Object.entries(it.colors).map(([color, value]) => (
              <Table.Item
                key={color+value}
                title={color}
                renderLeftComponent={() => (
                  <Square color={value} />
                )}
                value={value}
              />
            ))}
          </Table>
        </View>
      ))}
    </ScrollView>
  )
}

function Square({ size = 24, color = 'black' }) {
  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: 3,
        backgroundColor: color
      }}
    />
  )
}