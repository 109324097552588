import React from 'react';
import { Animated, View, Pressable } from 'react-native-web';
import {
  // useFocusEffect,
  useRoute
} from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/elements';
import Reanimated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Chessboard from '../components/Chessboard/Chessboard';
// import CapturedPieces from '../components/CapturedPieces';

import {
  COLOR_BLACK,
  COLOR_WHITE,
  MODE_SINGLE_PLAYER
} from '../constants';
import {
  useDesignSystem,
  useScreenData,
  useMaxContentWidth,
  useChess,
  useGameDialogs
} from '../hooks';
import { updateGame, useSharedState } from '../state';
import { findGameById } from '../utils';
import ScreenWrapper from '../components/ScreenWrapper';
import { useMeasure, usePrevious } from 'react-use';
import { ThemeContext } from '../contexts/ThemeContext';
import Confetti from '../components/Confetti';
import IconButton from '../components/IconButton';
import HStack from '../primitives/HStack';
import useContentSheet from '../hooks/useContentSheet';
import EmojiAvatar from '../components/EmojiAvatar';

export default function GameScreen() {
  const Tokens = useDesignSystem();
  const maxContentWidth = useMaxContentWidth()
  const route = useRoute();
  const { showGameOverDialog, showGameActionsDialog } =  useGameDialogs();
  const [showConfetti, setShowConfetti] = React.useState(false);
  const { isPhone } = useScreenData();
  const [sharedState, dispatch] = useSharedState();
  const { profile } = sharedState;
  const playerOne = {
    emoji: profile.emoji || 'skull',
    color: profile.color || 'blue',
    stats: {
      wins: 0,
      losses: 0,
      draws: 0
    }
  }
  const playerTwo = {
    emoji: "robot_face",
    color: "#E2E2E2",
    stats: {
      wins: 0,
      losses: 0,
      draws: 0
    }
  }

  const {
    games,
    settings
  } = sharedState;
  const { bubbleColor } = settings;
  const Theme = React.useContext(ThemeContext);
  const currentGame = findGameById(games, route?.params?.id);
  const isGameOver = !!currentGame?.dateCompleted;
  const [pendingMove, setPendingMove] = React.useState(false);

  const headerHeight  = useHeaderHeight();
  //tbh if you keep these
  const pgn = currentGame?.pgn || "";
  const chess = useChess(pgn);
  const isSinglePlayer = (currentGame?.mode || MODE_SINGLE_PLAYER) === MODE_SINGLE_PLAYER
  const history = chess.history({ verbose: true });
  const hasHistory = history.length !== 0;
  const safeAreaInset = useSafeAreaInsets()

  // // Show GameOver Dialog when returning to a completed game
  // const isFirstRender = React.useRef(true);
  // // TODO: add context menu clean up (maybe on context menu component itself)
  // useFocusEffect(
  //   React.useCallback(() => {
  //     if (isFirstRender.current === false) {
  //       return;
  //     }

  //     isFirstRender.current = false
  //     if (isGameOver) {
  //       setTimeout(() => {
  //         showGameOverDialog();
  //       }, 600);
  //     }

  //   }, [isGameOver, showGameOverDialog])
  // )

  return (
    <ScreenWrapper
      style={[
        safeAreaInset.bottom !== 0 && {
          marginBottom: Tokens.Dimensions.Standard
        },
        {
          paddingTop: headerHeight
        }
      ]}
    >
      <HStack style={{
        position: 'absolute',
        top: Theme.Dimensions.Small,
        right: Theme.Dimensions.Small,
        zIndex: 2
      }}>
        {(hasHistory && !isGameOver) && (
          <IconButton
            type={IconButton.Type.GoBackward}
            style={{
              marginRight: Theme.Dimensions.Small
            }}
            onPress={() => {
              chess.undo();

              // Undo a second time is single player
              // otherwise cpu will automatically move
              // again
              if (isSinglePlayer) {
                chess.undo();
              }

              dispatch(updateGame({
                ...currentGame,
                pgn: chess.pgn()
              }));
            }}
          />
        )}
        <IconButton
          type={IconButton.Type.Line3Horizontal}
          onPress={() => {
            if (isGameOver) {
              showGameOverDialog();

              return;
            }

            showGameActionsDialog()
          }}
        />
      </HStack>
      <View style={{
        top: 0,
        zIndex: 300
      }}>
        {showConfetti && <Confetti numberOfPieces={200}/>}
      </View>
      <View
        style={[
          {
            flex: 1,
            justifyContent: 'flex-end',
          },
            !isPhone && {
              justifyContent: 'center',
              alignItems: 'center'
            }
          ]}
        >
        <View style={{
          flex: 1,
          justifyContent: 'center'
        }}>
          <Avatars
            pgn={pgn}
            pendingMove={pendingMove}
            bubbleColor={bubbleColor}
            playerOne={playerOne}
            playerTwo={playerTwo}
          />
        </View>
        {/* <GameInformation
          color={COLOR_BLACK}
          history={history}
          player={playerTwo}
        /> */}
        <Chessboard
          style={{
            width: maxContentWidth,
            height: maxContentWidth,
            overflow: 'hidden',
            background: Tokens.Colors.ChessboardBackground
          }}
          pgn={pgn}
          onPickupPiece={() => {
            // console.log('onPickupPiece')
            setPendingMove(true);
          }}
          onDropPiece={({ success }) => {
            if (!success) {
              // console.log('onDropPiece')
              setPendingMove(false)
            }
          }}
          onChangeTurn={() => {
            // console.log('onChangeTurn')
            setPendingMove(false);
          }}
          onGameOver={() => {
            alert();
          }}
          showConfetti={() => {
            setShowConfetti(true);
          }}
        />
        {/* <GameInformation
          color={COLOR_WHITE}
          history={history}
          player={playerOne}
        /> */}
      </View>
    </ScreenWrapper>
  )
}

function Avatars({
  pgn,
  pendingMove,
  bubbleColor,
  playerOne,
  playerTwo,
}) {
  const chess = useChess(pgn);
  const turn = chess.turn();
  const inCheck = chess.in_check()
  const { showProfileDialog } = useContentSheet();

  return (
    <View style={{
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Pressable onPress={() => showProfileDialog({ showActions: false, editable: false })}>
        <Avatar
          color={COLOR_WHITE}
          turn={turn}
          pgn={pgn}
          pendingMove={pendingMove}
          bubbleColor={bubbleColor}
          player={playerOne}
        />
      </Pressable>
      <Avatar
        color={COLOR_BLACK}
        turn={turn}
        pgn={pgn}
        inCheck={inCheck}
        pendingMove={pendingMove}
        bubbleColor={bubbleColor}
        player={playerTwo}
      />
    </View>
  )
}

function Avatar({
  color,
  turn,
  pgn,
  bubbleColor,
  pendingMove = false,
  player = {},
  style = {}
}) {
  const [zIndex, setZIndex] = React.useState(0);
  const TRANSLATE_X_FROM = 0;
  const TRANSLATE_X_TO = color === COLOR_WHITE ? -20 : 20
  // const SCALE_FROM = turn === color ? 1 : 0.7;
  const SCALE_FROM = 0.8;
  const SCALE_TO = 0.8;
  const translateX = React.useRef(new Animated.Value(TRANSLATE_X_FROM)).current
  const scale = React.useRef(new Animated.Value(SCALE_FROM)).current
  // const [sharedState] = useSharedState();
  const Theme = React.useContext(ThemeContext);

  React.useEffect(() => {
    if (pendingMove === true) {
      Animated.parallel([
        Animated.spring(translateX, {
          toValue: TRANSLATE_X_TO,
          useNativeDriver: false
        }),
        Animated.spring(scale, {
          toValue: SCALE_TO,
          useNativeDriver: false
        })
      ]).start();

      return;
    } else {
      Animated.parallel([
        Animated.spring(translateX, { toValue: TRANSLATE_X_FROM, useNativeDriver: false }),
        Animated.spring(scale, {
          toValue: SCALE_FROM,
          useNativeDriver: false
        })
      ]).start()
    }
    setZIndex(turn === color ? 1 : 0);
  }, [pendingMove, turn, SCALE_FROM, TRANSLATE_X_TO, scale, translateX, setZIndex, color])

  const animationStyles = {
    transform: [
      { translateX: translateX },
      { scale: scale }
    ],
    zIndex: zIndex
  }

  const avatarSize = 120;
  const chess = useChess(pgn);

  return (
    <Animated.View
      style={[
        {
          position: 'relative',
          width: avatarSize,
          height: avatarSize,
          borderRadius: avatarSize,
          justifyContent: 'center',
          alignItems: 'center',
        },
        style,
        animationStyles
      ]}
    >
      {color === COLOR_WHITE && (
        <EmojiAvatar
          emoji={player.emoji}
          color={player.color}
          size={avatarSize}
          style={{
            borderWidth: 5,
            borderColor: 'white',
            ...Theme.Shadows.Avatar
          }}
        />
      )}
      {color === COLOR_BLACK && (
        <EmojiAvatar
          emoji={player.emoji}
          color={player.color}
          size={avatarSize}
          style={{
            borderWidth: 5,
            borderColor: 'white',
            ...Theme.Shadows.Avatar
          }}
        />
      )}
      <Message
        pgn={pgn}
        isVisible={turn !== color}
        color={color}
        message={getAvatarMessage(chess)}
        pendingMove={pendingMove}
        bubbleColor={bubbleColor}
      />
    </Animated.View>
  )
}

function getAvatarMessage(chess) {
  if (chess.in_checkmate()) {
    return "Checkmate!"
  }

  if (chess.in_check()) {
    return "Check!"
  }

  return null
}


function Message({ pgn, isVisible, bubbleColor }) {
  const Tokens = useDesignSystem();
  const [textRef, { height: textHeight}] = useMeasure();

  const opacity = useSharedValue(0);
  const scale = useSharedValue(0);
  const chess = useChess(pgn);

  const message = React.useRef(getAvatarMessage(chess))

  const prevIsVisible = usePrevious(isVisible);

  React.useEffect(() => {
    // console.log({
    //   isVisible,
    //   'message.current': message.current
    // })

    if (isVisible && prevIsVisible === false) {
      message.current = getAvatarMessage(chess);
    }

    if (isVisible && message.current) {
      opacity.value = withSpring(1)
      scale.value = withSpring(1)
      return;
    }

    opacity.value = withSpring(0)
    scale.value = withSpring(0)
    return;
  }, [isVisible, pgn, chess, opacity, prevIsVisible, scale])

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
      transform: [{
        scale: scale.value
      }]
    }
  });

  return (
    <Reanimated.Text
      ref={textRef}
      style={[{
        position: 'absolute',
        // top: -textHeight,
        // left: -(textWidth / 2),
        bottom: -(textHeight * 1.5),
        paddingVertical: Tokens.Dimensions.Smaller,
        paddingHorizontal: Tokens.Dimensions.Small,
        borderRadius: 20,
        backgroundColor: bubbleColor === 'blue'
          ? '#1982FC' // blue
          : '#43CC47', // green
        ...Tokens.Shadows.Elevation24,

        ...Tokens.Typography.Body,
        fontWeight: '500',
        color: 'white',
        zIndex: 3,
      }, animatedStyle]}
    >
      {message.current}
    </Reanimated.Text>
  )
}

// function GameInformation({ color, history, player = {} }) {
//   const Theme = React.useContext(ThemeContext)
//   // const [sharedState] = useSharedState();
//   // const { profile } = sharedState;

//   return (
//     <HStack style={{
//       alignItems: 'center',
//       padding: Theme.Dimensions.Small
//     }}>
//       <EmojiAvatar
//         emoji={player.emoji}
//         color={player.color}
//         size={40}
//         style={{
//           ...Theme.Shadows.Avatar
//         }}
//       />
//       <CapturedPieces
//         history={history}
//         color={color === COLOR_BLACK ? COLOR_WHITE : COLOR_BLACK}
//       />
//     </HStack>
//   )
// }