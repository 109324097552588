import React, { useMemo } from 'react';
import { Animated } from 'react-native-web';
import { useChess, useGameDialogs, useMaxContentWidth } from '../../hooks';
import {
  useFlipAnimation,
  // useCpuTurnEffect,
  // useGameOverEffect
} from "./hooks";
import LayerPieces from './LayerPieces';
import LayerSquares from './LayerSquares';
import LayerHighlight from './LayerHighlight';
import { useEffectOnce, useInterval } from 'react-use';
import { COLOR_BLACK, MODE_SINGLE_PLAYER } from '../../constants';
import { cpuSelectMove, findGameById } from '../../utils';
import { updateGame, useSharedState } from '../../state';
import { useRoute } from '@react-navigation/native';

export default function Chessboard({
  gesturesEnabled = true,
  size,
  pgn = "",
  onPickupPiece = () => {},
  onDropPiece = () => {},
  onChangeTurn = () => {},
  showConfetti = () => {}
}) {
  const [selectedPiece, setSelectedPiece] = React.useState(null);
  const [readyForInteraction, setReadyForInteraction] = React.useState(true);
  const maxContentWidth = useMaxContentWidth();
  const [sharedState, dispatch] = useSharedState();
  const { games, settings } = sharedState;
  const cpuSpeed = settings.cpuSpeed;
  const route = useRoute();
  const game = findGameById(games, route?.params?.id);
  const mode = game.mode;

  const chess = useChess(pgn);
  const board = chess.board();
  const moves = chess.moves({ verbose: true });
  const turn = chess.turn();
  const [turnBeforeInteraction, setTurnBeforeInteraction] = React.useState(turn);
  const inCheck = chess.in_check();
  const isGameOver = chess.game_over();
  const history = chess.history({ verbose: true });
  const lastMove = history && history.length && history[history.length - 1];
  const boardSize = size || maxContentWidth;
  const squareSize = boardSize / 8;
  const [boardAnimationStyles, pieceAnimationStyles] = useFlipAnimation(chess, setReadyForInteraction);

  const [queue, setQueue] = React.useState([]);
  const { showGameOverDialog } = useGameDialogs();

  const Queue = {
    SHOW_GAME_OVER_DIALOG: 'SHOW_GAME_OVER_DIALOG',
    CPU_TURN: 'CPU_TURN',
    CPU_THINKING: 'CPU_THINKING',
    SKIP_TICK: 'SKIP_TICK',
    SHOW_CONFETTI: 'SHOW_CONFETTI'
  };

  useInterval(() => {
    const currentTask = queue[0];

    function clearCurrentTask(queue) {
      const nextQueue = [...queue]
      nextQueue.shift();
      setQueue(nextQueue);
    }

    // console.log(`task:${currentTask}`)
    switch (currentTask) {
      case Queue.SKIP_TICK:
        clearCurrentTask(queue)
        return;
      case Queue.SHOW_GAME_OVER_DIALOG:
        showGameOverDialog();
        clearCurrentTask(queue)
        return;
      case Queue.SHOW_CONFETTI:
        showConfetti();
        clearCurrentTask(queue)
        return;
      case Queue.CPU_THINKING:
        onPickupPiece()
        clearCurrentTask(queue);
        return;
      case Queue.CPU_TURN:
        const cpuMove = cpuSelectMove(chess.moves({ verbose: true }));
        chess.move(cpuMove);
        onDropPiece({ success: true });
        dispatch(updateGame({
          ...game,
          pgn: chess.pgn()
        }));
        clearCurrentTask(queue)
        return;
      default:
        return;
    }
  }, 1000);

  const GAME_OVER_CONFETTI_TASKS = useMemo(() => [
    Queue.SKIP_TICK,
    Queue.SHOW_CONFETTI,
    Queue.SKIP_TICK,
    Queue.SKIP_TICK,
    Queue.SKIP_TICK,
    Queue.SHOW_GAME_OVER_DIALOG
  ], [Queue.SHOW_GAME_OVER_DIALOG, Queue.SHOW_CONFETTI, Queue.SKIP_TICK]);
  const CPU_TURN_TASKS = useMemo(() => [
    Queue.SKIP_TICK,
    Queue.CPU_THINKING,
    ...(Array.from({ length: cpuSpeedToNumber(cpuSpeed)}).map(it => Queue.SKIP_TICK)),
    Queue.CPU_TURN
  ], [cpuSpeed, Queue.CPU_THINKING, Queue.CPU_TURN, Queue.SKIP_TICK]);

  useEffectOnce(() => {
    if (isGameOver) {
      setQueue([Queue.SHOW_GAME_OVER_DIALOG]);
      // setQueue(GAME_OVER_CONFETTI_TASKS)
      return;
    }

    const isCpuTurn = mode === MODE_SINGLE_PLAYER && chess.turn() === COLOR_BLACK;
    if (isCpuTurn) {
      setQueue([
        ...queue,
        ...CPU_TURN_TASKS
      ]);
      return;
    }
  }, [chess, mode])

  // useCpuTurnEffect(chess, onPickupPiece);

  // turn change effect
  React.useEffect(() => {
    // not ready for interaction
    if (!readyForInteraction) {
      return;
    }

    // turn did not change
    if (turn === turnBeforeInteraction) {
      return;
    }

    // turn changed -- invoked the callback
    onChangeTurn();

    // Setup CPU turn tasks.
    if (mode === MODE_SINGLE_PLAYER && turn === COLOR_BLACK) {
      setQueue([
        ...queue,
        ...CPU_TURN_TASKS
      ]);
    }

    if (chess.game_over()) {
      setQueue([
        ...queue,
        ...GAME_OVER_CONFETTI_TASKS
      ])

      dispatch(updateGame({
        ...game,
        pgn: chess.pgn(),
        dateCompleted: Date.now()
      }))
    }

    // update turn for next interaction
    setTurnBeforeInteraction(turn);
  }, [CPU_TURN_TASKS, GAME_OVER_CONFETTI_TASKS, dispatch, game, queue, readyForInteraction, mode, turn, chess, turnBeforeInteraction, setReadyForInteraction, onChangeTurn])

  // TODO: move logic into component
  const activeBoard = board.map(it => it.map(piece => {
    if (isGameOver) {
      return null;
    }

    if (piece?.color === turn) {
      return piece;
    }

    return null
  }));

  // TODO: move logic into component
  const inactiveBoard = board.map(it => it.map(piece => {
    if (isGameOver) {
      return piece;
    }

    if (piece?.color !== turn) {
      return piece;
    }

    return null
  }));

  return (
    <Animated.View
      style={[
        {
          position: 'relative',
          width: boardSize,
          height: boardSize,
        },
        boardAnimationStyles,
      ]}
    >
      <LayerHighlight
        board={board}
        squareSize={squareSize}
        moves={moves}
        pgn={pgn}
        turn={turn}
        inCheck={inCheck}
        selectedPiece={selectedPiece}
        lastMove={lastMove}
      />
      <LayerSquares
        board={board}
        squareSize={squareSize}
        pgn={pgn}
        turn={turn}
      />
      <LayerPieces
        pieceStyles={pieceAnimationStyles}
        board={inactiveBoard}
        squareSize={squareSize}
        moves={moves}
        pgn={pgn}
        turn={turn}
      />
      <LayerPieces
        isActiveLayer
        pieceStyles={pieceAnimationStyles}
        gesturesEnabled={gesturesEnabled}
        isGameOver={isGameOver}
        board={activeBoard}
        squareSize={squareSize}
        moves={moves}
        pgn={pgn}
        turn={turn}
        selectedPiece={selectedPiece}
        setSelectedPiece={setSelectedPiece}
        setReadyForInteraction={setReadyForInteraction}
        onPickupPiece={onPickupPiece}
        onDropPiece={onDropPiece}
      />
    </Animated.View>
  )
}

export function ChessboardReadOnly({
  size,
  pgn = "",
  style = {}
}) {
  const maxContentWidth = useMaxContentWidth();
  const boardSize = size || maxContentWidth;
  const squareSize = boardSize / 8;
  const chess = useChess(pgn);
  const board = chess.board();
  const turn = chess.turn();
  const moves = chess.moves({ verbose: true });
  const inCheck = chess.in_check();
  const history = chess.history({ verbose: true });
  const lastMove = history && history.length && history[history.length - 1];

  return (
    <Animated.View
      style={[{
        position: 'relative',
        width: boardSize,
        height: boardSize,
      }, style]}
    >
      <LayerHighlight
        board={board}
        squareSize={squareSize}
        moves={moves}
        pgn={pgn}
        turn={turn}
        inCheck={inCheck}
        lastMove={lastMove}
      />
      <LayerSquares
        board={board}
        squareSize={squareSize}
        pgn={pgn}
        turn={turn}
      />
      {/* <LayerPieces
        board={board}
        squareSize={squareSize}
        moves={moves}
        pgn={pgn}
        turn={turn}
      /> */}
    </Animated.View>
  )
}

function cpuSpeedToNumber(cpuSpeed) {
  switch (cpuSpeed) {
    case 'slow':
      return 2;
    case 'normal':
      return 1;
    case 'fast':
      return 0;
    default:
      return 1;
  }
}