import React from 'react';
import { Pressable, View } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { Fireworks } from 'fireworks-js/dist/react'


export default function FireworksScreen() {
  const navigation = useNavigation();

  return (
    <Pressable
      onPress={() => navigation.goBack()}
    >
      <View>
        <Fireworks
          options={{
            speed: 3
          }}
          style={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            // background: 'rgba(0, 0, 0, 0.1)'
          }}
        />
      </View>
    </Pressable>
  )
}