import { v4 as uuidv4 } from 'uuid';
import parseColor from 'color-parse';

export const Device = {
  height: 780,
  width: 360
};

function evaluateAllMoves(moves) {
  return moves.map(evaluateMove);
}

function evaluateMove(move) {
  if (move.captured) {
    return 1;
  }
  return 0;
}

export function cpuSelectMove(moves) {
  const evaluatedMoves = evaluateAllMoves(moves);

  const maxScore = Math.max(...evaluatedMoves);
  const minScore = Math.min(...evaluatedMoves);

  // randomize move if no advantage
  if (maxScore === minScore) {
    return moves[Math.floor(Math.random() * moves.length)];
  }

  return evaluatedMoves.reduce((acc, score, index) => {
    if (score < maxScore) {
      return acc;
    }

    if (score === maxScore) {
      return moves[index];
    }

    return acc;
  }, null);

}


export function createGameData({ mode = 'single-player', color = 'w', pgn = '' }) {
  return {
    id: uuidv4(),
    color,
    mode,
    pgn,
    dateCreated: Date.now(),
    dateModified: Date.now(),
    dateCompleted: null
  }
}

export function findGameById(games, id) {
  return games.find(it => it.id === id);
}

export async function sleep(ms) {
  return new Promise(r => setTimeout(r, ms));
}

export function getReasonString(chess) {
  const inCheckmate = chess.in_checkmate();
  const inStalemate = chess.in_stalemate();
  const insufficientMaterial = chess.insufficient_material();
  const inThreefoldRepetition = chess.in_threefold_repetition();

  let reason;
  if (inCheckmate) {
    reason = 'checkmate'
  } else if (inStalemate) {
    reason = 'stalemate'
  } else if (insufficientMaterial) {
    reason = 'insufficient material'
  } else if (inThreefoldRepetition) {
    reason = 'threefold repetition'
  } else {
    reason = 'forfeit'
  }


  return reason;
}

export function withAlpha(rgbaString, alpha = 1) {
  const { values: [r, g, b ] } = parseColor(rgbaString);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}