import React from 'react';
import { View, TouchableHighlight } from 'react-native-web';
import { ReactComponent as BackIcon } from '../images/icons/chevron.backward.svg';
import { ReactComponent as CloseIcon } from '../images/icons/multiply.svg';
import { ReactComponent as MenuIcon } from '../images/icons/ellipsis.svg';
import { ReactComponent as SettingsIcon } from '../images/icons/gearshape.fill.svg';
import { useDesignSystem } from '../hooks';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ThemeContext } from '../contexts/ThemeContext';

export default function Header({
  onLeftButtonPress,
  leftButtonIcon = "chevron.backward",
  onRightButtonPress,
  rightButtonIcon = "ellipsis",
  title,
  style = {}
}) {
  const navigation = useNavigation();
  const route = useRoute();
  const Theme = React.useContext(ThemeContext);

  const Tokens = useDesignSystem();
  function isModal(routeName) {
    if (routeName === 'Settings') {
      return true;
    }
    return false;
  }

  return (
    <View style={[{
      flexDirection: 'row',
      paddingVertical: Tokens.Dimensions.Standard,
      paddingHorizontal: Tokens.Dimensions.Small,
    }, style]}>
      <IconButton
        type={leftButtonIcon}
        style={{ marginRight: 'auto', color: Theme.Colors.Label }}
        onPress={onLeftButtonPress || (() => {
          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.replace('Home');
        })
      }/>
      {!isModal(route?.name) && onRightButtonPress && (
        <IconButton
          type={rightButtonIcon}
          style={{
            marginLeft: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
          }}
          onPress={onRightButtonPress || (() => {
            navigation.canGoBack()
              ? navigation.goBack()
              : navigation.replace('Home');
          })
        }/>
      )}
    </View>
  );
}

function IconButton({ type = 'chevron.backward', style = {}, onPress = () => {} }) {
  let Icon;
  if (type === 'chevron.backward') {
    Icon = BackIcon;
  } else if (type === 'multiply') {
    Icon = CloseIcon;
  } else if (type === 'gearshape') {
    Icon = SettingsIcon;
  } else {
    Icon = MenuIcon
  }

  return (
    <TouchableHighlight
      underlayColor="#ddd"
      style={[
        {
          borderRadius: '100%',
          overflow: 'hidden'
        },
        style
      ]}
      onPress={onPress}
    >
      <View>
        <Icon style={{
          width: 36,
          height: 36
        }}/>
      </View>
    </TouchableHighlight>
  )

}