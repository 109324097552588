import React from 'react';
import { setGames, useSharedState } from '../state';
import Profile from '../components/Profile';
import EditAvatarSheet from '../components/EditAvatarSheet';
import Button from '../components/Button';
import { ContentSheetContext } from '../contexts/ContentSheetContext';
import { ThemeContext } from '../contexts/ThemeContext';

export default function useContentSheet() {
  const [, dispatch] = useSharedState();
  const { showContentSheet } = React.useContext(ContentSheetContext);
  const Theme = React.useContext(ThemeContext)


  function showResetGameDataDialog() {
    showContentSheet(
      "Reset Game Data",
      "This will delete all games and achievements",
      [
        {
          title: "Reset",
          type: 'destructive',
          onPress: () => {
            dispatch(setGames([]))
          }
        },
        {
          title: "Cancel",
          type: Button.Type.Tertiary,
        }
      ]
    );
  }

  function showEditProfileDialog() {
    showContentSheet(
      "Avatar",
      "Customize your avatar",
      [
        {
          title: "Done",
          type: Button.Type.Secondary,
          onPress: () => {
            showProfileDialog({ showActions: true, editable: true });
          }
        }
      ],
      {
        children: (
          <EditAvatarSheet />
        ),
        onDismiss: () => {
          showProfileDialog({ showActions: true, editable: true });
        }
      }
    )
  }

  function showProfileDialog({ editable = false, showActions = false } = {}) {
    const buttons = !showActions
      ? []
      : [
        // {
        //   title: "Achievements",
        //   type: Button.Type.Secondary,
        //   onPress: () => {
        //     alert('TODO')
        //   }
        // },
        {
          title: "Reset Game Data",
          type: Button.Type.Secondary,
          tint: Theme.Colors.Danger,
          onPress: () => {
            showResetGameDataDialog()
          }
        },
        {
          title: "Done",
          type: Button.Type.Tertiary,
          onPress: () => { }
        },
      ];
    showContentSheet(
      null,
      null,
      buttons,
      {
        children: (
          <Profile
            name="Naoufal Kadhom"
            email="hi@naoufal.com"
            winCount={88}
            lossCount={4}
            drawCount={11}
            editable={editable}
          />
        )
      }
    )
  }

  return {
    showProfileDialog,
    showEditProfileDialog,
  }
}