import React from 'react';
import { View } from "react-native-web";
import Button from '../../components/Button';
import { ThemeContext } from '../../contexts/ThemeContext';
import { useGameDialogs } from '../../hooks';

export default function ComponentActionSheetScreen() {
  const Theme = React.useContext(ThemeContext);
  const { showEditProfileDialog, showProfileDialog, showResignDialog, showGameActionsDialog, showGameOverDialog, showInstantReplayDialog } = useGameDialogs();
  // const { showContentSheet } = React.useContext(ContentSheetContext);

  return (
    <View style={{ flex: 1, padding: Theme.Dimensions.Standard }}>
      <Button
        type={Button.Type.Secondary}
        title="New Game"
        style={{
          marginBottom: Theme.Dimensions.Standard,
        }}
        onPress={() => {

        }}
      />
      <Button
        type={Button.Type.Secondary}
        title="Instant Replay"
        style={{
          marginBottom: Theme.Dimensions.Standard,
        }}
        onPress={() => {
          showInstantReplayDialog()
        }}
      />
      <Button
        type={Button.Type.Secondary}
        title="Resign"
        style={{
          marginBottom: Theme.Dimensions.Standard,
        }}
        onPress={() => {
          showResignDialog()
        }}
      />
      <Button
        type={Button.Type.Secondary}
        title="Game Over"
        style={{
          marginBottom: Theme.Dimensions.Standard,
        }}
        onPress={() => {
          showGameOverDialog()
        }}
      />
      <Button
        type={Button.Type.Secondary}
        title="Game Paused"
        style={{
          marginBottom: Theme.Dimensions.Standard,
        }}
        onPress={() => {
          showGameActionsDialog()
        }}
      />
      <Button
        type={Button.Type.Secondary}
        title="Profile"
        style={{
          marginBottom: Theme.Dimensions.Standard,
        }}
        onPress={() => {
          showProfileDialog();
        }}
      />
      <Button
        type={Button.Type.Secondary}
        title="Edit Avatar"
        style={{
          marginBottom: Theme.Dimensions.Standard,
        }}
        onPress={() => {
          showEditProfileDialog();
        }}
      />
    </View>
  )
}

