import { Pressable } from 'react-native-web';
import React from "react";

import Text from "../primitives/Text";
import { ThemeContext } from "../contexts/ThemeContext";

export default function IconButton({
  type = IconButton.Type.Ellipsis,
  size = IconButton.Size.Standard,
  tint,
  backgroundColor,
  withShadow = true,
  style = {},
  onPress = () => {}
}) {
  const Theme = React.useContext(ThemeContext);

  const icon = (() => {
    switch (type) {
      case IconButton.Type.ArrowLeft:
        return '􀄪';
      case IconButton.Type.ChevronLeft:
        return '􀆉';
      case IconButton.Type.ChevronRight:
        return '􀆊';
      case IconButton.Type.ChevronRight2:
        return '􀰫';
      case IconButton.Type.Plus:
        return '􀅼';
      case IconButton.Type.XMark:
        return '􀆄';
      case IconButton.Type.Ellipsis:
        return '􀍠';
      case IconButton.Type.Checkmark:
        return '􀆅';
      case IconButton.Type.Gear:
        return '􀍟';
      case IconButton.Type.Line3Horizontal:
        return '􀌇';
      case IconButton.Type.Crown:
        return '􀦅';
      case IconButton.Type.GoBackward:
        return '􀎀';
      case IconButton.Type.Clock:
        return '􀐫';
      case IconButton.Type.ClockArrowCirclePath:
        return '􀣔';
      case IconButton.Type.Pencil:
        return '􀈊';
      default:
        return '' // null
    }
  })();

  const buttonSize = (() => {
    switch (size) {
      case IconButton.Size.Small:
        return 30;
      case IconButton.Size.Standard:
        return 40;
      case IconButton.Size.Large:
        return 60;
      default:
        return 40;
    }
  })()

  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => ([{
        justifyContent: 'center',
        alignItems: 'center',
        height: buttonSize,
        width: buttonSize,
        borderRadius: buttonSize,
        backgroundColor: backgroundColor || style.backgroundColor || Theme.Colors.Foreground,
      },
      withShadow && Theme.Shadows.Standard,
      pressed && Theme.Shadows.None,
      style
    ])}
    >
      <Text style={{
        ...Theme.Typography.Icon,
        color: tint || Theme.Colors.Label,
        fontWeight: 'bold',
        fontSize: buttonSize / 2,
      }}>
        {icon}
      </Text>
    </Pressable>
  )
}

IconButton.Type = {
  ArrowLeft: 'arrow.left',
  ChevronLeft: 'chevron.left',
  ChevronRight: 'chevron.right',
  ChevronRight2: 'chevron.right.2',
  Plus: 'plus',
  XMark: 'xmark',
  Ellipsis: 'ellipsis',
  Checkmark: 'checkmark',
  Gear: 'gear',
  Line3Horizontal: 'line.3.horizontal',
  Crown: 'crown',
  GoBackward: 'gobackward',
  Clock: 'clock',
  ClockArrowCirclePath: 'clock.arrow.circlepath',
  Pencil: 'pencil'
};

IconButton.Size = {
  Small: 'small',
  Standard: 'standard',
  Large: 'large'
}