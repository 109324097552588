import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Pressable, View } from 'react-native-web';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useChess, useMaxContentWidth } from '../../hooks';
import LayerSquares from './LayerSquares';
// import LayerHighlight from './LayerHighlight';
import LayerPiecesNext from './LayerPiecesNext';

import { BOARD_LETTERS as letters, BOARD_NUMBERS as numbers, COLOR_BLACK, COLOR_WHITE, PIECE_ROOK,
  // COLOR_WHITE
} from '../../constants';
import PieceReadOnly from './PieceReadOnly';
// import { cpuSelectMove } from '../../utils';
import VStack from '../../primitives/VStack';
import { setCurrentIndex, setPgn, useGameState } from '../../state/game';
import Chess from 'chess.js';
import IconButton from '../IconButton';
import Square from './Square';
import { ThemeContext } from '../../contexts/ThemeContext';
import { usePrevious } from 'react-use';

function ChessboardNext({
  size,
  initialPgn = "",
  style = {}
}, ref) {
  const [state, dispatch] = useGameState(initialPgn);
  const [isAnimatingMove, setIsAnimatingMove] = React.useState(false);
  const { pgn, history, currentIndex } = state;
  const chessboardRef = React.useRef();
  const [presentedPgn, setPresentedPgn] = React.useState(pgn);
  const chess = useChess(presentedPgn);
  const [pendingMove, setPendingMove] = React.useState();
  const [selectedSquare, setSelectedSquare] = React.useState();
  const [highlightMove, setHighlightMove] = React.useState();

  const maxContentWidth = useMaxContentWidth();
  const boardSize = size || maxContentWidth;
  const squareSize = boardSize / 8;

  const board = chess.board();
  const turn = chess.turn();
  const moves = chess.moves({ verbose: true });
  const [isTimeTraveling, setIsTimeTraveling] = React.useState(history.length - 1 !== currentIndex);

  const scale = useSharedValue(1);
  const clockButtonTranslateX = useSharedValue(48);
  const animateIntoTimeTravelMode = React.useCallback(() => {
    setSelectedSquare(null)
    setIsTimeTraveling(true);
    scale.value = withTiming(0.85)
    clockButtonTranslateX.value = withTiming(0, null, (finished) => {
      if (!finished) {
        return;
      }
    });
  }, [scale, clockButtonTranslateX])

  const animateOutOfTimeTravelMode = React.useCallback(() => {
    clockButtonTranslateX.value = withTiming(48, null, (finished) => {
      if (!finished) {
        return;
      }

    });
    scale.value = withTiming(1, null, (finished) => {
      if (!finished) {
        return;
      }

      setIsTimeTraveling(false)
    })
  }, [clockButtonTranslateX, setIsTimeTraveling, scale])

  useImperativeHandle(ref, () => ({
    getCurrentIndex: () => currentIndex,
    getTotalMoves: () => history.length,
    isTimeTraveling: () => isTimeTraveling,

    stepBack: () => {
      if (isAnimatingMove) {
        return;
      }

      if (history.length === 0) {
        return;
      }

      if (currentIndex === -1) {
        return;
      }

      animateIntoTimeTravelMode();
      const move = history[currentIndex];
      const undoMove = getUndoMove(move);

      undoMove.onComplete = () => {
        dispatch(setCurrentIndex(currentIndex - 1))

        const chess = new Chess();
        chess.load_pgn(presentedPgn);
        chess.undo();

        const nextPresentedPgn = chess.pgn();
        setPresentedPgn(nextPresentedPgn);
        setIsAnimatingMove(false)
      }

      setIsAnimatingMove(true)
      setPendingMove(undoMove);
      setHighlightMove(history[currentIndex - 1]);
    },
    stepForward: (callback = () => {}) => {
      if (isAnimatingMove) {
        return;
      }

      if (history.length === 0) {
        return;
      }

      if (currentIndex === history.length - 1) {
        return;
      }

      if (currentIndex === history.length - 2) {
        animateOutOfTimeTravelMode();
      }

      const move = history[currentIndex + 1];

      move.onComplete = () => {
        dispatch(setCurrentIndex(currentIndex + 1))

        const chess = new Chess();
        chess.load_pgn(presentedPgn);
        chess.move(move);

        const nextPresentedPgn = chess.pgn();
        setPresentedPgn(nextPresentedPgn);
        setIsAnimatingMove(false)
        callback();
      }

      setIsAnimatingMove(true)
      setPendingMove(move);
      setHighlightMove(move);
    },
    stepToFront: () => {
      ref.current.stepForward(() => {
        if (ref.current.isTimeTraveling()) {
          ref.current.stepToFront();
        }
      })
    },
    undo: () => {
      if (isAnimatingMove) {
        return;
      }

      if (history.length === 0) {
        return;
      }

      if (currentIndex !== history.length - 1) {
        return;
      }

      // Cannot undo when CPU turn
      const undoMove = getUndoMove(history[history.length - 1])

      undoMove.onComplete = () => {
        const chess = new Chess();
        chess.load_pgn(presentedPgn);
        chess.undo();

        const nextPgn = chess.pgn();
        dispatch(setPgn(nextPgn))
        setPresentedPgn(nextPgn)
      }

      setIsAnimatingMove(true)
      setPendingMove(undoMove);
      setHighlightMove(history[currentIndex - 1]);
    }
  }), [
    isAnimatingMove,
    presentedPgn,
    dispatch,
    history,
    currentIndex,
    animateIntoTimeTravelMode,
    animateOutOfTimeTravelMode,
    isTimeTraveling,
    ref
  ]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { scale: scale.value }
      ]
    }
  });

  const clockButtonAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: clockButtonTranslateX.value }
      ]
    }
  })

  const castleMove = getCastleMove(pendingMove)
  return (
    <VStack>
      <Animated.View style={[
        {
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1
        },
        clockButtonAnimatedStyle
      ]}>
        <IconButton
          // size={IconButton.Size.Small}
          type={IconButton.Type.ChevronRight2}
          onPress={() => {
            ref?.current?.stepToFront()
          }}
        />
      </Animated.View>
      <Animated.View
        ref={chessboardRef}
        style={[
          {
            position: 'relative',
            width: boardSize,
            height: boardSize,
          },
          style,
          animatedStyle
        ]}
      >
        <LayerHighlightLastMove
          board={board}
          squareSize={squareSize}
          highlightMove={highlightMove}
        />
        <LayerHighlightLastMove
          board={board}
          squareSize={squareSize}
          highlightMove={{ from: selectedSquare }}
        />
        {/* <LayerHighlight
          board={board}
          squareSize={squareSize}
          moves={moves}
          pgn={highlightedPgn}
          turn={turn}
          inCheck={inCheck}
          selectedPiece={pendingMove?.from}
          lastMove={lastMove}
          // showPossibleMoves={!isTimeTraveling}
          // showSelectedPiece={!isTimeTraveling}
        /> */}
        <LayerSquares
          board={board}
          squareSize={squareSize}
          pgn={presentedPgn}
          turn={turn}
        />
        <LayerPiecesNext
          board={board}
          squareSize={squareSize}
          moves={moves}
          pgn={presentedPgn}
          turn={turn}
          onSelect={(move) => {
            if (presentedPgn !== pgn) {
              alert('game not live');

              return;
            }
            // showPossibleMoves(true);
            setSelectedSquare(move?.from)
            setPendingMove(move)
          }}
          pendingMove={pendingMove}
          castleMove={castleMove}
        />
        <LayerHighlightPossibleMoves
          board={board}
          moves={chess.moves({ square: pendingMove?.from, verbose: true })}
          squareSize={squareSize}
          pendingMove={pendingMove}
          isVisible={!isTimeTraveling && !isAnimatingMove}
        />
        {pendingMove && (
          <LayerAnimatedPieces
            pendingMove={pendingMove}
            castleMove={castleMove}
            squareSize={squareSize}
            board={board}
            onMoveEnd={() => {
              setPendingMove(null);
              pendingMove.onComplete();
              setIsAnimatingMove(false);
            }}
          />
        )}
        {pendingMove?.from && (
          <LayerMovePieceTo
            pgn={pgn}
            dispatch={dispatch}
            squareSize={squareSize}
            setPendingMove={setPendingMove}
            setHighlightMove={setHighlightMove}
            // setShowPossibleMoves={setShowPossibleMoves}
            setIsAnimatingMove={setIsAnimatingMove}
            setPresentedPgn={setPresentedPgn}
            setSelectedSquare={setSelectedSquare}
            board={board}
            moves={chess.moves({ square: pendingMove?.from, verbose: true })}
          />
        )}

      </Animated.View>
    </VStack>
  )
}

function LayerMovePieceTo({
  pgn = "",
  dispatch,
  squareSize,
  board,
  moves,
  setPresentedPgn,
  setPendingMove,
  setHighlightMove,
  setIsAnimatingMove,
  setSelectedSquare
}) {
  const squares = moves.map(it => {
    return getSquareIndexesFromSquareName(board, it.to)
  });

  return (
    <>
      {squares.map(it => {
        return (
          <Pressable
            key={it.squareName}
            onPress={() => {
              const move = moves.find(move => move.to === it.squareName);

              move.onComplete = () => {
                const chess = new Chess();
                chess.load_pgn(pgn);
                chess.move(move);

                const nextPgn = chess.pgn();
                dispatch(setPgn(nextPgn));
                setPresentedPgn(nextPgn)
              }

              setIsAnimatingMove(true);
              setPendingMove(move);
              setHighlightMove(move);
              setSelectedSquare(null)
            }}
            style={{
              position: 'absolute',
              top: it.numberIndex * squareSize,
              left: it.letterIndex * squareSize,
              width: squareSize,
              height: squareSize
            }}
          />
        )
      })}
    </>
  );
}

function LayerAnimatedPieces({
  squareSize,
  board,
  pendingMove,
  onMoveEnd,
  castleMove
}) {
  return (
    <>
      <AnimatedCapturedPiece
        piece={pendingMove.captured}
        pendingMove={pendingMove}
        squareSize={squareSize}
        color={pendingMove.color === COLOR_WHITE ? COLOR_BLACK : COLOR_WHITE}
        board={board}
      />
      {castleMove && (
        <AnimatedPiece
          squareSize={squareSize}
          from={castleMove?.from}
          to={castleMove?.to}
          piece={castleMove?.piece}
          color={castleMove?.color}
          board={board}
        />
      )}
      <AnimatedPiece
        squareSize={squareSize}
        from={pendingMove?.from}
        to={pendingMove?.to}
        piece={pendingMove?.piece}
        color={pendingMove?.color}
        board={board}
        onMoveEnd={onMoveEnd}
      />
    </>
  )
}

function AnimatedPiece({
  board,
  squareSize,
  onMoveEnd = () => {},
  color = 'w',
  from,
  to,
  piece,
  style
}) {
  const squareData = getSquareIndexesFromSquareName(
    board,
    from
  );
  const toSquareData = getSquareIndexesFromSquareName(
    board,
    to
  );

  const translateY = useSharedValue(squareData.numberIndex * squareSize);
  const translateX = useSharedValue(squareData.letterIndex * squareSize);

  // Needed for when a user clicks to a different square
  const previousFrom = usePrevious(from)
  useEffect(() => {
    if (from === previousFrom) {
      return;
    }

    translateY.value = squareData.numberIndex * squareSize;
    translateX.value = squareData.letterIndex * squareSize;
  }, [squareData, squareSize, from, previousFrom, translateX, translateY]);

  const animatePiece = React.useCallback(() => {
    const config = {
      duration: 300,
      easing: Easing.bezier(0.5, 0.01, 0, 1)
    };
    translateY.value = withTiming(
      toSquareData?.numberIndex * squareSize,
      config
    );

    translateX.value = withTiming(
      toSquareData?.letterIndex * squareSize,
      config,
      (finished) => {
        if (finished) {
          onMoveEnd()
        }
      }
    );
  }, [
    onMoveEnd,
    translateY,
    translateX,
    squareSize,
    toSquareData?.letterIndex,
    toSquareData?.numberIndex
  ]);

  useEffect(() => {
    if (!to) {
      return;
    }

    animatePiece();
  }, [
    to,
    animatePiece
  ])

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      { translateX: translateX.value },
      { translateY: translateY.value }
    ]
  }));

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          width: squareSize,
          height: squareSize
        },
        style,
        animatedStyle,
      ]}
    >
      <PieceReadOnly
        type={piece || squareData?.piece?.type}
        color={squareData?.piece?.color}
        size={squareSize}
      />
    </Animated.View>
  )
}


function AnimatedCapturedPiece({ piece, board, color, pendingMove, squareSize }) {
  if (!piece) {
    return null;
  }

  const toSquareData = getSquareIndexesFromSquareName(
    board,
    pendingMove?.isUndo ? pendingMove?.from : pendingMove?.to
  );

  return (
    <View style={{
      position: 'absolute',
      top: toSquareData.numberIndex * squareSize,
      left: toSquareData.letterIndex * squareSize,
    }}>
      <PieceReadOnly
        type={piece}
        color={color}
        size={squareSize}
      />
    </View>
  )
}

function getSquareIndexesFromSquareName(board, squareName) {
  if (!squareName) {
    return;
  }

  const [letter, number] = squareName.split('');

  const letterIndex = letters.reduce((acc, it, index) => {
    if (it === letter) {
      return index
    }

    return acc;
  }, null)
  const numberIndex = numbers.reduce((acc, it, index) => {
    if (it === parseInt(number)) {
      return index
    }

    return acc;
  }, null);

  return {
    squareName,
    letter,
    letterIndex,
    number,
    numberIndex,
    piece: board[numberIndex][letterIndex]
  }
}

export default forwardRef(ChessboardNext);

function getUndoMove(move) {
  const undoMove = {
    ...move,
    from: move.to,
    to: move.from,
    isUndo: true
  };

  return undoMove;
}

function LayerHighlightLastMove({ board, highlightMove, squareSize }) {
  const Theme = React.useContext(ThemeContext);

  return (
    <View style={{ position: 'absolute' }}>
      {board.map((row, numberIndex) => (
        <View key={numbers[numberIndex]} style={{ flexDirection: 'row' }}>
          {row.map((piece, letterIndex) => {
            // const filteredMoves = moves.filter(it => it.from === selectedPiece);
            const squareName = letters[letterIndex] + numbers[numberIndex];
            const isPartOfHighlightedMove = [highlightMove?.from, highlightMove?.to].includes(squareName);

            let color;
            if (isPartOfHighlightedMove) {
              color = Theme.Colors.ChessboardSquareLastMove
            }

            return (
              <Square
                key={squareName}
                name={squareName}
                size={squareSize}
                color={color}
              />
            );
          })}
        </View>
      ))}
    </View>
  )
}

function LayerHighlightPossibleMoves({
  board,
  moves,
  pendingMove,
  squareSize,
  isVisible
}) {
  const Theme = React.useContext(ThemeContext);

  if (!isVisible) {
    return null;
  }

  return (
    <View style={{
      position: 'absolute',
      pointerEvents: 'none'
    }}>
      {board.map((row, numberIndex) => (
        <View key={numbers[numberIndex]} style={{ flexDirection: 'row' }}>
          {row.map((piece, letterIndex) => {
            // const filteredMoves = moves.filter(it => it.from === selectedPiece);
            const squareName = letters[letterIndex] + numbers[numberIndex];
            const isPossibleMove = moves.reduce((acc, move) => {
              if (move.to === squareName) {
                return true;
              }
              return acc;
            }, false);

            let color;

            if (isPossibleMove) {
              color = Theme.Colors.ChessboardSquarePossibleMove
            }

            return (
              <View
                key={squareName}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: squareSize,
                  height: squareSize
                }}
              >
                <View style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: color
                }}
                />
              </View>
              // <Square
              //   key={squareName}
              //   name={squareName}
              //   size={squareSize}
              //   color={color}
              // />
            );
          })}
        </View>
      ))}
    </View>
  )
}

function getCastleMove(pendingMove) {
  const isWhite = pendingMove?.color === COLOR_WHITE;
  const isQueenSideCastle = pendingMove?.san === 'O-O-O';
  const isKingSideCastle = pendingMove?.san === 'O-O';
  const isUndo = pendingMove?.isUndo;

  const baseMove = {
    isUndo: pendingMove?.isUndo,
    color: pendingMove?.color,
    piece: PIECE_ROOK
  }

  if (isWhite && isKingSideCastle) {
    return {
      ...baseMove,
      from: isUndo ? 'f1' : 'h1',
      to: isUndo ? 'h1' : 'f1',
    }
  }

  if (isWhite && isQueenSideCastle) {
    return {
      ...baseMove,
      from: isUndo ? 'd1' : 'a1',
      to: isUndo ? 'a1' : 'd1'
    }
  }

  if (!isWhite && isKingSideCastle) {
    return {
      ...baseMove,
      from: isUndo ? 'f8' : 'h8',
      to: isUndo ? 'h8' : 'f8'
    }
  }

  if (!isWhite && isQueenSideCastle) {
    return {
      ...baseMove,
      from: isUndo ? 'd8' : 'a8',
      to: isUndo ? 'a8' : 'd8'
    }
  }

  return null;
}