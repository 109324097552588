import React from 'react';
import { View, Pressable } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { useDesignSystem, useMaxContentWidth, useScreenData } from '../hooks';

export default function ModalWrapper({ children }) {
  return (
    <Overlay>
      <Card>
        {children}
      </Card>
    </Overlay>
  )
}

function Card({ style, children }) {
  const { isPhone, height } = useScreenData();
  const maxContentWidth = useMaxContentWidth();
  const Tokens = useDesignSystem();

  return (
    <View
      style={[
        {
          flex: 1,
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          background: '#f0f0f0'
        },
        !isPhone && {
          borderRadius: 10,
          width: maxContentWidth - Tokens.Dimensions.Standard * 2,
          maxHeight: height / 2,
          minHeight: 100, // Here so that we don't see a blank page when creating a new screen,
        },
        style
      ]}
    >
      {children}
    </View>
  )
}

function Overlay({ children }) {
  const navigation = useNavigation();
  const ref = React.useRef();
  const { isPhone } = useScreenData();

  return (
    <Pressable
      ref={ref}
      style={[
        {
          flex: 1,
        },
        !isPhone && {
          alignItems: 'center',
          justifyContent: 'center'
        }
      ]}
      onPress={(e) => {
        if (e.target === ref.current) {
          navigation.goBack()
        }
      }}
    >
      {children}
    </Pressable>
  )
}