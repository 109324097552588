import React from 'react';
import {
  Text,
  Pressable,
  View } from "react-native-web"
import { useDesignSystem, useScreenData } from "../hooks";
import Card from "./Card"
import Overlay from "./Overlay";
import Animated, { useSharedValue } from 'react-native-reanimated';
import {
  withTiming,
  useAnimatedStyle,
  Easing
} from 'react-native-reanimated';

import { ContextMenuContext } from '../contexts/ContextMenuContext';
import { useMeasure } from 'react-use';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ThemeContext } from '../contexts/ThemeContext';

export default function ContextMenu() {
  const Theme = React.useContext(ThemeContext);
  const Tokens = useDesignSystem();
  const { contextMenu, dismissContextMenu } = React.useContext(ContextMenuContext)
  const [pointerEvents, setPointerEvents] = React.useState('none');
  const { height: windowHeight } = useScreenData();
  const safeAreaInset = useSafeAreaInsets();

  const {
    isVisible,
    title,
    message,
    buttons = [],
    options = {}
  } = contextMenu;

  const {
    cancelable = false,
    // background = 'none'
  } = options;

  const [ref, { height: contentHeight, top: contentTop }] = useMeasure();
  const overlayOpacity = useSharedValue(0);
  const translateY = useSharedValue(0);
  const config = {
    duration: 400,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
  };

  const animatedContentStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withTiming(
            translateY.value,
            config
          )
        }
      ]
    };
  });
  const animatedOverlayStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(
        overlayOpacity.value,
        config
      ),
      pointerEvents
    }
  });

  React.useEffect(() => {
    translateY.value = 0;

    function animateIn() {
      overlayOpacity.value = 1;
      const offset = safeAreaInset.bottom > 0
        ? safeAreaInset.bottom
        : Tokens.Dimensions.Small

      translateY.value = -(contentHeight + offset);
    }

    function animateOut() {
      translateY.value = 0;
      overlayOpacity.value = 0;
    }

    setPointerEvents(isVisible ? 'auto' : 'none');
    if (isVisible) {
      animateIn();
    } else {
      animateOut();
    }
  }, [
    isVisible,
    overlayOpacity,
    translateY,
    contentHeight,
    contentTop,
    Tokens.Dimensions.Small,
    safeAreaInset.bottom
  ])

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // userSelect: 'none',
        zIndex: 1,
        pointerEvents
      }}
    >
      <Overlay
        style={animatedOverlayStyle}
        onPress={() => {
          if (cancelable === false) {
            return;
          }

          dismissContextMenu();
        }}
      />
      <Animated.View
        ref={ref}
        style={[{
          position: 'absolute',
          top: windowHeight,
          left: 0,
          right: 0,
          zIndex: 11,
          paddingHorizontal: Tokens.Dimensions.Small,
          paddingBottom: safeAreaInset.bottom > 0
            ? safeAreaInset.bottom
            : Tokens.Dimensions.Small
        },
        animatedContentStyle
      ]}
      >
        {(title || message) && (
          <Card style={{
            borderRadius: 10,
            marginBottom: Tokens.Dimensions.Smaller,
            paddingTop: Tokens.Dimensions.Small,
            paddingBottom: Tokens.Dimensions.Small,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {title && (
              <Text style={{
                ...Theme.Typography.Heading,
                paddingBottom: Tokens.Dimensions.Smallest,
                textAlign: 'center',
                color: Theme.Colors.Label
              }}>
                {title}
              </Text>
            )}
            {message && (
              <Text style={{
                ...Theme.Typography.Body,
                color: Theme.Colors.Label,
                textAlign: 'center'
              }}>{message}</Text>
            )}
          </Card>
        )}
        <Card style={{
          borderRadius: 10,
          padding: 0,
          paddingBottom: 0,
          overflow: 'hidden'
        }}>
          {buttons.map((it, index) => (
            <Action
              key={it.text}
              text={it.text}
              disabled={it.disabled}
              onPress={() => {
                dismissContextMenu();
                setTimeout(() => {
                  it.onPress()
                }, 300);
              }}
              color={it.type === 'destructive' ? Theme.Colors.Danger : null}
              style={index !== buttons.length - 1 && {
                borderBottomWidth: 1,
                borderColor: Theme.Colors.Separator
              }}
            />
          ))}
        </Card>
        {cancelable && (
          <Card style={{
            borderRadius: 10,
            marginTop: Tokens.Dimensions.Smaller,
            padding: 0,
            paddingBottom: 0,
            overflow: 'hidden'
          }}>
            <Action
              text="Cancel"
              color={Theme.Colors.Link}
              onPress={() => {
                dismissContextMenu();
              }}
            />
          </Card>
        )}
      </Animated.View>
    </View>
  )
}

function Action({
  text = "",
  onPress = () => {},
  style = {},
  color,
  disabled = false
}) {
  const Tokens = useDesignSystem();
  const Theme = React.useContext(ThemeContext);

  const textStyle = {
    ...Tokens.Typography.Settings,
    color: color || Theme.Colors.Label,
    padding: Tokens.Dimensions.Small,
    textAlign: 'center',
    marginHorizontal: Tokens.Dimensions.Standard,
  };

  if (disabled) {
    return (
      <View style={{
        cursor: 'not-allowed',
        background: Theme.Colors.Foreground
      }}>
        <Text style={[
          textStyle,
          style,
          { color: Theme.Colors.TertiaryLabel },
        ]}>
          {text}
        </Text>
      </View>
    )
  }

  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => ({
        background: Theme.Colors.Foreground,
        opacity: pressed ? 0.7 : 1
      })}
    >
      <Text style={[
        textStyle,
        style
      ]}>
        {text}
      </Text>
    </Pressable>
  );
}