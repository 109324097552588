import React from 'react';
import { Pressable, View } from 'react-native-web';
import { useEffectOnce } from 'react-use';
import { ThemeContext } from '../contexts/ThemeContext';
import { useChess, useMaxContentWidth } from '../hooks';
import ChessboardReadOnly from './Chessboard/ChessboardReadOnly';

export default function InstantReplay({
  pgn = "",
  startFromEnd = false
}) {
  const [allPgns, setAllPgns] = React.useState([""]);
  const [pgnIndex, setPgnIndex] = React.useState(0);
  const Theme = React.useContext(ThemeContext);
  const maxContentWidth = useMaxContentWidth();
  const chess = useChess(pgn);

  useEffectOnce(() => {
    const allPgns = chess.history({ verbose: true }).reverse().reduce((acc, it) => {
      chess.undo();
      const pgn = chess.pgn();

      return [...acc, pgn];
    }, [chess.pgn()]).reverse();

    setAllPgns(allPgns);

    if (startFromEnd) {
      setPgnIndex(allPgns.length - 1);
    }
  });

  const size = maxContentWidth - (Theme.Dimensions.Larger * 2);
  return (
    <View style={{
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <View style={{
        position: 'relative',
        marginTop: Theme.Dimensions.Large,
      }}>
        <Pressable
          style={{
            zIndex: 2,
            position: 'absolute',
            top: 0,
            left: 0,
            height: size,
            width: size / 2,
          }}
          onLongPress={() => {
            // skip to first move
            setPgnIndex(0)
          }}
          onPress={() => {
            if (pgnIndex === 0)  {
              return;
            }

            setPgnIndex(pgnIndex - 1)
          }}
        />
        <Pressable
          style={{
            zIndex: 2,
            position: 'absolute',
            top: 0,
            right: 0,
            height: size,
            width: size / 2,
            padding: 20
          }}
          onLongPress={() => {
            // skip to last move
            setPgnIndex(allPgns.length - 1)
          }}
          onPress={() => {
            if (pgnIndex === allPgns.length - 1)  {
              return;
            }

            setPgnIndex(pgnIndex + 1)
          }}
        />
        <ChessboardReadOnly
          size={size}
          pgn={allPgns[pgnIndex]}
        />
      </View>
    </View>
  )
}