import React from 'react';
import { useSharedState, ACTION_SET_STATE, INITIAL_STATE, setSettings } from '../state';
import { ScrollView, Switch, View } from 'react-native-web';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ContextMenuContext } from '../contexts/ContextMenuContext';
import Table from '../components/Table';
import { useNavigation } from '@react-navigation/native';
import { ThemeContext } from '../contexts/ThemeContext';

export default function SettingsScreen() {
  const navigation = useNavigation();
  const [sharedState, dispatch] = useSharedState();
  const { showContextMenu } = React.useContext(ContextMenuContext)
  const { settings } = sharedState;
  const Theme = React.useContext(ThemeContext);

  return (
    <View style={{
      flex: 1,
    }}>
      <ScrollView style={{
        paddingTop: 20
      }}>
        <Table
          title="General"
          type="inset"
          style={{
            marginBottom: 40
          }}
        >
          <Table.Item
            title="Game"
            onPress={() => navigation.navigate('SettingsGame')}
          />
          <Table.Item
            title="Display"
            onPress={() => navigation.navigate('SettingsDisplay')}
          />
          <Table.Item
            title="Sounds"
            renderRightComponent={() => (
              <Switch
                value={settings.soundsEnabled}
                onValueChange={(e) => {
                  dispatch(setSettings({
                    ...settings,
                    soundsEnabled: !settings.soundsEnabled
                  }));
                }}
              />
            )}
          />
        </Table>
        <Table
          title="About"
          type="inset"
          style={{
            marginBottom: 40
          }}
        >
          <Table.Item
            title="Support"
            onPress={() => navigation.navigate('Support')}
          />
          <Table.Item
            title="About"
            onPress={() => navigation.navigate('About')}
          />
        </Table>
        <Table
          title="Danger Zone"
          type="inset"
          style={{
            marginBottom: 40
          }}
        >
          {[
            <Table.Item
              title="Reset All Data"
              tint={Theme.Danger}
              onPress={(async () => {
                showContextMenu(
                  "Reset All Data",
                  "This will delete all game data and reset all settings.",
                  [
                    {
                      text: "Reset",
                      type: 'destructive',
                      onPress: async () => {
                        await AsyncStorage.clear();
                        dispatch({
                          type: ACTION_SET_STATE,
                          payload: INITIAL_STATE,
                        });

                        window.location.replace('/')
                      }
                    },
                  ]
                );
              })}
            />
          ]}
        </Table>
        {/* {process.env.NODE_ENV === 'development' && ( */}
        {(
          <Table
            title="Developer Mode"
            type="inset"
            style={{
              marginBottom: 40
            }}
          >
            <Table.Item
              title="Developer Playground"
              onPress={() => navigation.navigate('Playground')}
            />
            <Table.Item
              title="Design System"
              onPress={() => navigation.navigate('DesignSystem')}
            />
          </Table>
        )}
      </ScrollView>
    </View>
  )
}

