
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useContext, createContext, createElement } from 'react';
import { createReducer } from 'react-use';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

const ASYNC_STORAGE_PREFIX = '@chess-game';
export const ASYNC_STORAGE_STATE = `${ASYNC_STORAGE_PREFIX}:state`;

export const INITIAL_GAMES = [];
export const INITIAL_SETTINGS = {
  // General
  avatar: 'naoufal',
  // General
  soundsEnabled: false,
  // Game
  difficulty: 'easy',
  cpuSpeed: 'fast',
  flipBoardEnabled: true,

  // Display
  theme: 'light',
  matchSystemColorScheme: true,
  bubbleColor: 'green',
  avatarShape: 'round',
  dateFormat: 'relative',
  // Debug
  debugMode: process.env.NODE_ENV === 'development' ? true : false
};
export const INITIAL_PROFILE = {
  emoji: 'skull',
  color: '#E2E2E2'
}

export const INITIAL_STATE = {
  settings: INITIAL_SETTINGS,
  games: INITIAL_GAMES,
  profile: INITIAL_PROFILE
};

export const ACTION_SET_STATE = 'ACTION_SET_STATE';
const ACTION_SET_SETTINGS = 'ACTION_SET_SETTINGS';
const ACTION_ADD_GAME = 'ACTION_ADD_GAME';
const ACTION_UPDATE_GAME = 'ACTION_UPDATE_GAME';
const ACTION_UPDATE_SETTINGS = 'ACTION_UPDATE_SETTINGS';
const ACTION_UPDATE_PROFILE = 'ACTION_UPDATE_PROFILE';

const ACTION_SET_GAMES = 'ACTION_SET_GAMES';

function reducer(state, action) {
  switch (action.type) {
    case ACTION_SET_STATE:
      return action.payload;
    case ACTION_ADD_GAME:
      return {
        ...state,
        games: [
          ...state.games,
          action.payload
        ]
      };
    case ACTION_SET_GAMES:
      return {
        ...state,
        games: action.payload
        };
    case ACTION_UPDATE_GAME:
      const nextGames = state.games.map(it => {
        if (it.id !== action.payload.id) {
          return it;
        }

        return { ...it, ...action.payload };
      });

      return {
        ...state,
        games: nextGames
      };
    case ACTION_SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
    case ACTION_UPDATE_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload
        }
      };
    case ACTION_UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload
        }
      };
    default:
      throw new Error()
  }
}

const useThunkReducer = createReducer(
  thunk,
  logger
);

function createReducerContext(reducer, defaultInitialState) {
  const context = createContext(undefined);
  const providerFactory = (props, children) => createElement(context.Provider, props, children);

  const ReducerProvider = ({ children, initialState }) => {
    const state = useThunkReducer(
      reducer,
      initialState !== undefined ? initialState : defaultInitialState
    );
    return providerFactory({ value: state }, children);
  };

  const useReducerContext = () => {
    const state = useContext(context);
    if (state == null) {
      throw new Error(`useReducerContext must be used inside a ReducerProvider.`);
    }
    return state;
  };

  return [useReducerContext, ReducerProvider, context];
};

export const [
  useSharedState,
  SharedStateProvider
] = createReducerContext(reducer, INITIAL_STATE);

export const updateProfile = (nextProfile) => {
  return async (dispatch, getState) => {
    dispatch({ type: ACTION_UPDATE_PROFILE, payload: nextProfile });

    await AsyncStorage.setItem(
      ASYNC_STORAGE_STATE,
      JSON.stringify(getState())
    );
  }
}

export const setSettings = (nextSettings) => {
  return async (dispatch, getState) => {
    dispatch({ type: ACTION_SET_SETTINGS, payload: nextSettings });

    await AsyncStorage.setItem(
      ASYNC_STORAGE_STATE,
      JSON.stringify(getState())
    );
  }
}

export const addGame = (game) => {
  return async (dispatch, getState) => {
    dispatch({ type: ACTION_ADD_GAME, payload: game });

    await AsyncStorage.setItem(
      ASYNC_STORAGE_STATE,
      JSON.stringify(getState())
    );
  }
}

export const setGames = (games) => {
  return async (dispatch, getState) => {
    dispatch({ type: ACTION_SET_GAMES, payload: games });

    await AsyncStorage.setItem(
      ASYNC_STORAGE_STATE,
      JSON.stringify(getState())
    );
  }
}

export const updateGame = (game) => {
  return async (dispatch, getState) => {
    const nextGame = {
      ...game,
      dateModified: Date.now()
    };

    dispatch({ type: ACTION_UPDATE_GAME, payload: nextGame });

    await AsyncStorage.setItem(
      ASYNC_STORAGE_STATE,
      JSON.stringify(getState())
    );
  }
}