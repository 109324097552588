import React from "react";

import { ScrollView } from "react-native-web";
import Icon from "../../components/Icon";
import { ThemeContext } from "../../contexts/ThemeContext";
import Table from "../../components/Table";

export default function ComponentIconScreen() {
  const Theme = React.useContext(ThemeContext);

  return (
    <ScrollView style={{
      paddingTop: Theme.Dimensions.Large
    }}>
      <Table
        type="inset"
        title="Types"
        style={{
          marginBottom: Theme.Dimensions.Standard
        }}
      >
        {Icon.Types.map(it => (
          <Table.Item
            key={it}
            title={it}
            renderRightComponent={() => (
              <Icon
                key={it}
                type={it}
                tint={Theme.Colors.Label}
                style={{
                  marginHorizontal: -Theme.Dimensions.Smaller,
                  marginVertical: -Theme.Dimensions.Smaller
                }}
              />
            )}
          />
        ))}
      </Table>
      <Table
        type="inset"
        title="Props"
        style={{
          marginBottom: Theme.Dimensions.Standard
        }}
      >
        <Table.Item
          title="onPress"
          renderRightComponent={() => (
            <Icon
              onPress={() => alert('onPress') }
              style={{
                marginHorizontal: -Theme.Dimensions.Smaller,
                marginVertical: -Theme.Dimensions.Smaller
              }}
            />
          )}
        />
        <Table.Item
          title="tint"
          renderRightComponent={() => (
            <Icon
              tint={Theme.Colors.Primary}
              style={{
                marginHorizontal: -Theme.Dimensions.Smaller,
                marginVertical: -Theme.Dimensions.Smaller
              }}
            />
          )}
        />
        <Table.Item
          title="withBackground"
          renderRightComponent={() => (
            <Icon
              tint={Theme.Colors.Primary}
              withBackground={true}
              style={{
                marginHorizontal: -Theme.Dimensions.Smaller,
                marginVertical: -Theme.Dimensions.Smaller
              }}
            />
          )}
        />
      </Table>
    </ScrollView>
  )
}