import React from 'react';
import { View, Image } from 'react-native';
// import { ThemeContext } from '../contexts/ThemeContext';
import { useSharedState } from '../state';

const melissaAvatarSource = require('../images/avatars/mel.png');
const naoufalAvatarSource = require('../images/avatars/naoufal.png');

export default function Avatar({ size = 40, style = {}, type = "user" }) {
  const [sharedState] = useSharedState();
  // const Theme = React.useContext(ThemeContext);
  const { settings } = sharedState;
  const { avatar } = settings;
  const source = avatar === 'melissa'
    ? melissaAvatarSource
    : naoufalAvatarSource;


  const imageSize = (() => {
    switch (size) {
      case Avatar.Size.Small:
        return 28;
      case Avatar.Size.Standard:
        return 40;
      case Avatar.Size.Large:
        return 60;
      case Avatar.Size.ExtraLarge:
        return 100;
      default:
        return 40;
    }
  })()
  if (type === 'bot') {
    return (
      <View
        style={{
          width: imageSize,
          height: imageSize,
          borderRadius: imageSize,
          backgroundColor: '#bbb',
          ...style
        }}
      />
    )
  }
  return (
    <Image
      source={source}
      style={{
        width: imageSize,
        height: imageSize,
        borderRadius: imageSize,
        // ...Theme.Shadows.Standard,
        ...style
      }}
    />
  );
}

Avatar.Size = {
  Small: 'small',
  Standard: 'standard',
  Large: 'large',
  ExtraLarge: 'extra-large'
}