import React from 'react';
import { View, Text, Appearance } from 'react-native-web';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  NavigationContainer, useNavigationContainerRef
} from '@react-navigation/native'
import { useEffectOnce } from 'react-use';
import PortraitOnlyScreen from './screens/PortraitOnlyScreen';
import { ContextMenuProvider } from './contexts/ContextMenuContext';
import { ContentSheetProvider } from './contexts/ContentSheetContext';
import { ACTION_SET_STATE, ASYNC_STORAGE_STATE, INITIAL_STATE, setSettings, useSharedState } from './state';

import MainStackScreens, { linking } from './navigation/MainStack';
import { ContextMenuContext } from './contexts/ContextMenuContext';
import { ContentSheetContext } from './contexts/ContentSheetContext';

import "./App.css"
import "./fonts.css"
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useScreenData } from './hooks';
import { SharedStateProvider } from './state';

import { ThemeProvider } from './contexts/ThemeContext';

function App() {
  const { isStandalone } = useScreenData();
  const navigationRef = useNavigationContainerRef();

  return (
    <View
      className="App"
      style={[
        {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          userSelect: 'none'
        },
        isStandalone && {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }
      ]}
    >
      <SharedStateProvider>
        <SafeAreaProvider>
          <ThemeProvider>
            <ContextMenuProvider navigationRef={navigationRef}>
              <ContentSheetProvider navigationRef={navigationRef}>
                <Navigation ref={navigationRef} />
              </ContentSheetProvider>
            </ContextMenuProvider>
          </ThemeProvider>
        </SafeAreaProvider>
      </SharedStateProvider>
    </View>
  )
}

const Navigation = React.forwardRef((props, ref) => {
  const { isPhone, isPortrait } = useScreenData();
  const { dismissContextMenu } = React.useContext(ContextMenuContext)
  const { dismissContentSheet } = React.useContext(ContentSheetContext)
  const isReady = useInitApp();

  if (!isReady) {
    return <Text>loading...</Text>
  }

  if (isPhone && !isPortrait) {
    return <PortraitOnlyScreen />
  }

  return (
    <NavigationContainer
      {...props}
      ref={ref}
      linking={linking}
      fallback={<Text>loading...</Text>}
      onStateChange={(e) => {
        dismissContextMenu();
        dismissContentSheet();
      }}
    >
      <MainStackScreens />
    </NavigationContainer>
  )
})


function useInitApp() {
  const [isReady, setIsReady] = React.useState(false);
  const [sharedState, dispatch] = useSharedState();
  const { settings } = sharedState;

  // Fetch initial state on refresh
  useEffectOnce(() => {
    // Set colorSceheme when app starts
    async function fetchInitialState() {
      const initialState = await AsyncStorage.getItem(ASYNC_STORAGE_STATE);

      let nextInitialState = initialState
        ? JSON.parse(initialState)
        : INITIAL_STATE;

      if (nextInitialState.settings.matchSystemColorScheme) {
        nextInitialState.settings.theme = Appearance.getColorScheme()
      }

      dispatch({
        type: ACTION_SET_STATE,
        payload: nextInitialState
      });

      setIsReady(true);
    }

    fetchInitialState();
  })

  React.useEffect(() => {
    function handleAppearanceChange({ colorScheme }) {
      if (settings.matchSystemColorScheme) {
        dispatch(setSettings({
          ...settings,
          theme: colorScheme
        }));
      }
    }

    Appearance.addChangeListener(handleAppearanceChange)

    return () => Appearance.removeChangeListener(handleAppearanceChange)
  }, [settings, dispatch]);

  return isReady;
}

export default App;