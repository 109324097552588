import React from "react";

import { ScrollView } from "react-native-web";
import Text from '../../primitives/Text';
import VStack from "../../primitives/VStack";
import { ThemeContext } from "../../contexts/ThemeContext";
import Button from '../../components/Button';

export default function ComponentButtonScreen() {
  const Theme = React.useContext(ThemeContext);

  return (
    <ScrollView style={{ paddingTop: Theme.Dimensions.Large }}>
      <Text style={{
        ...Theme.Typography.Heading,
        marginHorizontal: Theme.Dimensions.Standard,
      }}>
        Types
      </Text>
      <VStack
        style={{
          margin: Theme.Dimensions.Standard
        }}
      >
        {Object.keys(Button.Type).map(it => (
          <Button
            key={it}
            title={it}
            type={Button.Type[it]}
            style={{
              marginBottom: Theme.Dimensions.Standard
            }}
          />
        ))}
      </VStack>
      <Text style={{
        ...Theme.Typography.Heading,
        marginHorizontal: Theme.Dimensions.Standard,
      }}>
        Disabled
      </Text>
      <VStack
        style={{
          margin: Theme.Dimensions.Standard
        }}
      >
        <Button
          disabled
          title="Disabled"
          style={{
            marginBottom: 20
          }}
        />
      </VStack>
    </ScrollView>
  )
}