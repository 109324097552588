import React from 'react';
import { Pressable, View, Text, SectionList } from 'react-native-web';
import emojiJson from 'emoji-datasource/emoji.json'
import { useMeasure } from 'react-use';
import { ThemeContext } from '../contexts/ThemeContext';
import EmojiImage from './EmojiImage';

const ITEM_HEIGHT = 50;
const HEADER_HEIGHT = 50;

const emojiData = [
  "Smileys & Emotion",
  "People & Body",
  "Animals & Nature",
  "Food & Drink",
  "Activities",
  "Travel & Places",
  "Objects",
  "Symbols",
  "Flags",
  // "Component",
].map(it => ({
  title: it,
  data: emojiJson
    .filter(emoji => emoji.category === it)
    .sort((a, b) => {
      return (a.sort_order > b.sort_order) ? 1 : -1;
    })
}));

const categories = emojiData.map(it => it.title);

export default function EmojiPicker({ onSelect = () => {}, style = {} }) {
  const scrollViewRef = React.useRef();
  const sectionScrollPositionRef = React.useRef({});

  return (
    <View style={[{ flex: 1 }, style]}>
      <EmojiNavigation
        categories={categories}
        sectionScrollPositionRef={sectionScrollPositionRef}
        scrollViewRef={scrollViewRef}
      />
      <EmojiScrollView
        scrollViewRef={scrollViewRef}
        sectionScrollPositionRef={sectionScrollPositionRef}
        onSelect={onSelect}
      />
    </View>
  )
}

function EmojiScrollView({ scrollViewRef, sectionScrollPositionRef, onSelect }) {
  const [ref, { width }] = useMeasure();
  const Theme = React.useContext(ThemeContext);

  const sections = emojiData.map(it => ({
    title: it.title,
    data: it.data.reduce((acc, it) => {
      // create first group
      if (acc.length === 0) {
        return [[it]];
      }

      if (acc[acc.length - 1].length === 6) {
        return [...acc, [it]];
      }

      acc[acc.length - 1].push(it);

      return acc;
    }, [])
  }))


  const ITEM_WIDTH = width / 6;
  const renderItem = React.useCallback(({ item, index, section }) => (
    <View
      key={section.title + index.toString()}
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingHorizontal: Theme.Dimensions.Standard
      }}
    >
      {item.map(it => (
        <Pressable
          key={it.short_name}
          style={{
            height: ITEM_HEIGHT,
            width: ITEM_WIDTH
          }}
          onPress={() => {
            onSelect(it.short_name)
          }}
        >
          <EmojiImage key={it.name} name={it.short_name} size={32} />
        </Pressable>
      ))}
    </View>

  ), [ITEM_WIDTH, onSelect, Theme.Dimensions.Standard]);
  const renderSectionHeader = React.useCallback(({ section: { title } }) => (
    <View
      ref={ref}
      key={title}
      style={{
        justifyContent: 'center',
        height: HEADER_HEIGHT,
        paddingHorizontal: Theme.Dimensions.Small,
      }}
    >
      <Text
        style={{
          ...Theme.Typography.BodySmall,
          color: Theme.Colors.SecondaryLabel,
        }}
      >
        {title}
      </Text>
    </View>
  ), [ref, Theme.Colors.SecondaryLabel, Theme.Dimensions.Small, Theme.Typography.BodySmall])
  const keyExtractor = React.useCallback((group, index) => {
    return group[0].category + index;
  }, [])


  return (
    <SectionList
      ref={scrollViewRef}
      // initialNumToRender={5}
      sections={sections}
      keyExtractor={keyExtractor}
      renderSectionHeader={renderSectionHeader}
      renderItem={renderItem}
      getItemLayout={(item, index, foo) => {
        return {
          length: ITEM_HEIGHT,
          offset: (ITEM_HEIGHT * index),
          index
        }
      }}
      style={{
        marginBottom: -Theme.Dimensions.Small,
        // paddingBottom: 120
      }}
    />
  )
}

function EmojiNavigation({
  sectionScrollPositionRef,
  categories,
  scrollViewRef
}) {
  const Theme = React.useContext(ThemeContext);

  return (
    <View style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: Theme.Dimensions.Standard,
      borderBottomWidth: 1,
      borderColor: Theme.Colors.QuaternaryLabel
    }}>
      {categories.map((it, index) => (
        <Pressable
          key={it}
          onPress={() => {
            scrollViewRef.current.scrollToLocation({
              animated: false,
              sectionIndex: index,
              itemIndex: 0,
              viewOffset: HEADER_HEIGHT
            });
          }}
        >
          <Text
            style={{
              ...Theme.Typography.Icon,
              fontSize: 17,
              paddingVertical: Theme.Dimensions.Small,
              color: Theme.Colors.SecondaryLabel
            }}
          >
            {getIconForCategory(it)}
          </Text>
        </Pressable>
      ))}
    </View>
  )
}

function getIconForCategory(category) {
  switch (category) {
    case "Smileys & Emotion":
      return '􀎸';
    case "People & Body":
      return '􀉩';
    case "Animals & Nature":
      return '􀾞';
    case "Food & Drink":
      return '􀥲';
    case "Activities":
      return '􀛸';
    case "Travel & Places":
      return '􀮅';
    case "Objects":
      return '􀛭';
    case "Symbols":
      return '􀃨';
    case "Flags":
      return '􀋉';
    default:
      return 'X'
  }
}