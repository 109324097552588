import React from 'react';
import {
  ScrollView,
  Pressable,
  View
} from 'react-native-web';
import { ThemeContext } from '../contexts/ThemeContext';
import ContentSheet from '../components/ContentSheet';
import Button from '../components/Button';
import VStack from '../primitives/VStack';
import EmojiPicker from '../components/EmojiPicker';
import EmojiImage from '../components/EmojiImage';
import { useNavigation } from '@react-navigation/native';


const AVATAR_SIZE = 100;
export default function EditProfileScreen() {
  const navigation = useNavigation();

  const [color, setColor] = React.useState('#E2E2E2');
  const [
    emoji,
    setEmoji
  ] = React.useState('skull');
  // const [nickname, setNickname] = React.useState('naoufal');
  const Theme = React.useContext(ThemeContext);
  const scrollViewRef = React.useRef();

  const COLORS = [
    '#E2E2E2',
    '#D1EECE',
    '#F8EAB3',
    '#F3DCB0',
    '#EBBFBB',
    '#EFCBD4',
    '#D7D1F5',
    '#D4E9F9'
  ];

  // const offset = (width / 2 - AVATAR_SIZE / 2);
  return (
    <ContentSheet
      title="Avatar"
      message="Customize your avatar"
      isVisible={true}
      isRoute={true}
      buttons={[
        {
          title: 'Done',
          type: Button.Type.Secondary,
          onPress: () => {
            navigation.goBack();
          }
        }
      ]}
      style={{
        background: `linear-gradient(180deg, ${color === '#E2E2E2' ? 'white' : color}, #fff 75%)`,
      }}
    >
      <VStack style={{
        marginTop: Theme.Dimensions.Standard,
        marginHorizontal: -Theme.Dimensions.Standard,
      }}>
        <ScrollView
          ref={scrollViewRef}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          scrollEventThrottle={100}
          onScroll={(e) => {
            const offset = e.nativeEvent.contentOffset.x
            const index = parseInt(offset / (AVATAR_SIZE + Theme.Dimensions.Standard));

            if (color === COLORS[index]) {
              return;
            }
            setColor(COLORS[index]);
          }}
        >
          {COLORS.map((it, index) => (
            <Pressable
              key={it}
              style={{
                paddingLeft: Theme.Dimensions.Standard
              }}
              onPress={() => {
                // setColor(it)

                scrollViewRef.current.scrollTo({
                  y: 0,
                  x: ((AVATAR_SIZE + Theme.Dimensions.Standard) * index)
                })
              }}
            >
              <AvatarBackground color={it} emoji={emoji} />
            </Pressable>
          ))}
          <View style={{
            width: Theme.Dimensions.Standard + (AVATAR_SIZE + Theme.Dimensions.Standard) * 2
          }}/>
        </ScrollView>

        {/* <FlatList
          ref={listRef}
          showsHorizontalScrollIndicator={false}
          onScroll={(e) => {
            const offset = e.nativeEvent.contentOffset.x
            const index = parseInt(offset / (AVATAR_SIZE + Theme.Dimensions.Standard));

            setColor(COLORS[index]);
          }}
          horizontal
          pagingEnabled
          keyExtractor={item => item}
          data={COLORS}
          renderItem={({ item, index }) => (
            <Pressable
              key={item}
              style={{
                paddingLeft: Theme.Dimensions.Standard
              }}
              onPress={() => {
                // setColor(item)

                listRef.current.scrollToOffset({
                  offset: ((AVATAR_SIZE + Theme.Dimensions.Standard) * index)
                })
              }}
            >
              <AvatarBackground color={item} emoji={emoji} />
            </Pressable>
          )}
          ListFooterComponent={() => (
            <View style={{
              width: width - AVATAR_SIZE - Theme.Dimensions.Standard,
              // height: 30,
              // background: 'red'
            }} />
          )}
        /> */}
        <View style={{
          width: 10,
          height: 10,
          borderRadius: 10,
          background: 'black',
          marginTop: Theme.Dimensions.Small,
          // marginTop: -(AVATAR_SIZE / 2),
          marginLeft: Theme.Dimensions.Standard + AVATAR_SIZE / 2 - (10 / 2)
        }} />
        <EmojiPicker
          onSelect={setEmoji}
          style={{
            marginTop: Theme.Dimensions.Standard,
            maxHeight: 260
          }}
        />
      </VStack>
    </ContentSheet>
  )
}

function AvatarBackground({ color, emoji }) {
  // const Theme = React.useContext(ThemeContext);
  const size = AVATAR_SIZE;

  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: size,
        backgroundColor: color,
        borderWidth: 4,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <EmojiImage name={emoji} size={50} />
    </View>
  )
}