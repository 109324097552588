import React from "react";
import { View } from "react-native-web";
import { ThemeContext } from "../contexts/ThemeContext";

export default function Separator({ style }) {
  const Theme = React.useContext(ThemeContext);

  return (
    <View
      style={[{
        minWidth: 1,
        minHeight: 1,
        backgroundColor: Theme.Colors.Background,
        // opacity: 0.6
      }, style]}
    />
  )
}