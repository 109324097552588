import React from 'react';
import { View } from 'react-native-web';
import Header from '../components/Header';
import { useNavigation, useRoute } from '@react-navigation/native';

import { useDesignSystem } from '../hooks';
import ModalWrapper from '../components/ModalWrapper';
import SettingsItem from '../components/SettingsItem';
import { updateGame, useSharedState } from '../state';
import { findGameById } from '../utils';
import { MODE_SINGLE_PLAYER, MODE_TWO_PLAYER } from '../constants';

const PGN_PRESET_END_GAME = "1. c4 a6 2. c5 a5 3. d3 Na6 4. e4 Nxc5 5. g3 Nxd3+ 6. Qxd3 g5 7. Nf3 g4 8. Nd4 c5 9. Nb5 Ra7 10. Be2 Qc7 11. Bxg4 Qxg3 12. hxg3 d5 13. O-O dxe4 14. Qxe4 Bxg4 15. Qxg4 a4 16. Rd1 Ra6";
// const PGN_PRESET_GAME_OVER = '';

export default function DebugGameScreen() {
  const navigation = useNavigation();
  const route = useRoute();
  const [sharedState, dispatch] = useSharedState();
  const { games } = sharedState;
  const currentGame = findGameById(games, route?.params?.id);
  const Tokens = useDesignSystem();

  return (
    <ModalWrapper>
      <Header
        title="debug game"
        rightButtonLabel="done"
        onRightButtonPress={async () => {
          navigation.goBack();
        }}
      />
      <View>
        <SettingsItem
          label="show game over dialog"
          textStyle={{ color: Tokens.Colors.Red }}
          onPress={ () => {
            navigation.goBack();

            setTimeout(() => {
              navigation.navigate('GameOverDialog', {
                id: route?.params?.id
              })
            }, 300);
          }}
        />
        <SettingsItem
          label="set end game state"
          onPress={() => {
            dispatch(updateGame({
              ...currentGame,
              pgn: PGN_PRESET_END_GAME
            }));

            setTimeout(() => {
              navigation.goBack();
            }, 300);
          }}
        />
        <SettingsItem
          label="set single player mode"
          onPress={() => {
            dispatch(updateGame({
              ...currentGame,
              mode: MODE_SINGLE_PLAYER
            }));

            setTimeout(() => {
              navigation.goBack();
            }, 300);
          }}
        />
        <SettingsItem
          label="set two player mode"
          onPress={() => {
            dispatch(updateGame({
              ...currentGame,
              mode: MODE_TWO_PLAYER
            }));

            setTimeout(() => {
              navigation.goBack();
            }, 300);
          }}
        />
        {/* <SettingsItem
          label="set game over state"
          onPress={() => {
            dispatch(updateGame({
              ...currentGame,
              pgn: PGN_PRESET_END_GAME
            }));

            setTimeout(() => {
              navigation.navigate(ROUTE_NAME_GAME, {
                id: route?.params?.id
              });
            }, 300);
          }}
        /> */}
      </View>
    </ModalWrapper>
  )
}