import React from 'react';
import {
  createStackNavigator,
  CardStyleInterpolators
} from '@react-navigation/stack';

import HomeScreen from '../screens/HomeScreen';
import DebugScreen from '../screens/DebugScreen';
import DebugGameScreen from '../screens/DebugGameScreen'
import DesignSystemScreen from '../screens/DesignSystemScreen';
import GameScreen from '../screens/GameScreen';
import InstantReplayScreen from '../screens/InstantReplayScreen';
import FireworksScreen from '../screens/FireworksScreen';
import PlaygroundScreen from '../screens/PlaygroundScreen';
import EditProfileScreen from '../screens/EditProfileScreen';
import SettingsStackScreens from './SettingsStack';
import IconButton from '../components/IconButton';

import { ThemeContext } from '../contexts/ThemeContext';
import { usePresentationTokens } from '../hooks';

const MainStack = createStackNavigator();
export const linking = {
  prefixes: ['https://chess.naoufal.dev'],
  config: {
    screens: {
      Menu: '',
      Game: '/Game',
      EditProfile: '/EditProfile',
      InstantReplay: '/InstantReplay',
      History: '/History',
      Fireworks: '/Fireworks',
      Debug: '/Debug',

      Settings: {
        initialRouteName: 'SettingsHome',
        screens: {
          SettingsHome: '/Settings',
          SettingsGame: '/Settings/Game',
          SettingsDisplay: '/Settings/Display',
          Support: '/Settings/Support',
          About: '/Settings/About',
          DesignSystem: '/Settings/DesignSystem',
          Playground: '/Settings/Playground',

          // Foundations
          DesignSystemTypography: '/Settings/DesignSystem/Foundations/Typography',
          DesignSystemColors: '/Settings/DesignSystem/Foundations/Colors',
          DesignSystemDimensions: '/Settings/DesignSystem/Foundations/Dimensions',

          // Component
          ComponentGame: '/Settings/DesignSystem/Component/Game',
          ComponentChessboard: '/Settings/DesignSystem/Component/Chessboard',
          ComponentConfetti: '/Settings/DesignSystem/Component/Confetti',

          ComponentButton: '/Settings/DesignSystem/Component/Button',
          ComponentIconButton: '/Settings/DesignSystem/Component/IconButton',
          ComponentIcon: '/Settings/DesignSystem/Component/Icon',
          ComponentAvatar: '/Settings/DesignSystem/Component/Avatar',
          ComponentActionSheet: '/Settings/DesignSystem/Component/ActionSheet',
          ComponentContentSheet: '/Settings/DesignSystem/Component/ContentSheet',
          ComponentTable: '/Settings/DesignSystem/Component/Table',
        }
      }
    }
  },
};

export default function MainStackScreens() {
  const Theme = React.useContext(ThemeContext);
  const PresentationTokens = usePresentationTokens();

  return (
    <MainStack.Navigator
      initialRouteName="Menu"
      screenOptions={{
        headerShown: false,
        animationEnabled: true,
        headerTitle: '',
        headerTintColor: Theme.Colors.Label,
        headerTransparent: true,
        cardStyle: {
          backgroundColor: Theme.Colors.Background
        },
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
      }}
    >
      <MainStack.Screen
        name="Menu"
        component={HomeScreen}
        options={({ navigation, route }) => ({
          headerShown: true,
          headerLeft:() => (
            <IconButton
              type="gear"
              onPress={() => navigation.navigate('Settings')}
              style={{
                marginTop: Theme.Dimensions.Small,
                marginLeft: Theme.Dimensions.Small
              }}
            />
          ),
          animationTypeForReplace: "pop"
        })}
      />
      <MainStack.Screen
        name="Settings"
        component={SettingsStackScreens}
        options={{
          gestureEnabled: true,
          ...PresentationTokens.ModalScreen
        }}
      />
      <MainStack.Screen
        name="Debug"
        component={DebugScreen}
        options={{
          ...PresentationTokens.ModalScreen
        }}
      />
      <MainStack.Screen
        name="EditProfile"
        component={EditProfileScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
          presentation: 'transparentModal',
          cardStyle: {
            backgroundColor: 'transparent'
          },
        }}
      />
      <MainStack.Screen
        name="DebugGame"
        component={DebugGameScreen}
        options={{
          ...PresentationTokens.ModalScreen
        }}
      />
      <MainStack.Screen
        name="DesignSystem"
        component={DesignSystemScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
        }}
      />
      <MainStack.Screen
        name="Game"
        component={GameScreen}
        options={{
          headerShown: true,
          headerLeft:({ onPress }) => (
            <IconButton
              type="arrow.left"
              onPress={onPress}
              style={{
                marginTop: Theme.Dimensions.Small,
                marginLeft: Theme.Dimensions.Small
              }}
            />
          ),
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
        }}
      />
      <MainStack.Screen
        name="InstantReplay"
        component={InstantReplayScreen}
        options={({ navigation }) => ({
          cardStyle: {
            backgroundColor: 'white'
          },
          headerShown: true,
          headerLeft: null,
          headerRight:({ onPress }) => (
            <IconButton
              type="xmark"
              size={IconButton.Size.Small}
              onPress={() => navigation.goBack()}
              withShadow={false}
              backgroundColor={Theme.Colors.Background}
              tint={Theme.Colors.SecondaryLabel}
              style={{
                marginTop: Theme.Dimensions.Small,
                marginRight: Theme.Dimensions.Small
              }}
            />
          ),
          cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
        })}
      />
      <MainStack.Screen
        name="Fireworks"
        component={FireworksScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
          presentation: 'transparentModal'
        }}
      />
      <MainStack.Screen
        name="Playground"
        component={PlaygroundScreen}
      />
    </MainStack.Navigator>
  );
}