import React from 'react';
import { Animated } from 'react-native-web';
import { usePrevious } from 'react-use';
import { useNavigation, useRoute } from '@react-navigation/native';
import { COLOR_BLACK, DELAY_MOVE, MODE_TWO_PLAYER, COLOR_WHITE, DELAY_CPU_MOVE, DELAY_CPU_THINKING } from '../../../constants';
import { cpuSelectMove, findGameById, getReasonString } from '../../../utils';
import { updateGame, useSharedState } from '../../../state';
import { useGameDialogs } from '../../../hooks';

export function useFlipAnimation(chess, setReadyForInteraction) {
  const route = useRoute();
  const [sharedState] = useSharedState();

  const { games } = sharedState;
  const game = findGameById(games, route?.params?.id);
  const mode = game.mode;
  const turn = chess.turn();
  const shouldFlip = (turn === COLOR_BLACK && mode === MODE_TWO_PLAYER);
  const [flipPieces, setFlipPieces] = React.useState(shouldFlip ? true : false)

  const firstRender = React.useRef(true);
  const rotateAnim = React.useRef(
    new Animated.Value(shouldFlip ? 1 : 0)
    ).current;
  const scaleAnim = React.useRef(
    new Animated.Value(1)
  ).current;

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const shouldFlip = turn === COLOR_BLACK;
    if (mode !== MODE_TWO_PLAYER ) {
      return;
    }


    setReadyForInteraction(false);
    Animated.spring(scaleAnim, {
      toValue: 0.8,
      useNativeDriver: false
    }).start(() => {
      // Flip pieces after scale animation
      setFlipPieces(shouldFlip);

      Animated.sequence([
        Animated.spring(rotateAnim, {
          toValue: shouldFlip ? 1 : 0,
          useNativeDriver: false
        }),
        Animated.spring(scaleAnim, {
          toValue: 1,
          useNativeDriver: false
        })
      ])
      .start(() => {
        setReadyForInteraction(true);
      });
    })
  }, [mode, turn, rotateAnim, scaleAnim, setReadyForInteraction]);

  const rotate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg']
  });

  const boardAnimationStyles = {
    transform: [
      { rotate },
      { scale: scaleAnim }
    ]
  };
  const pieceStyles = {
    transform: [
      { rotate: flipPieces ? '180deg' : '0deg' }, // not animated
    ]
  };

  return [boardAnimationStyles, pieceStyles];
};

export function useCpuTurnEffect(chess, onPickupPiece) {
  const route = useRoute();
  const [sharedState, dispatch] = useSharedState();
  const [moveInProgress, setMoveInProgress] = React.useState(false)

  const { games } = sharedState;
  const game = findGameById(games, route?.params?.id);
  const mode = game.mode;
  const turn = chess.turn();
  const isGameCompleted = !!game?.dateCompleted

  const history = chess.history({ verbose: true });
  const prevHistory = usePrevious(history);

  React.useEffect(() => {
    if (moveInProgress) {
      return;
    }

    if (!(history && prevHistory)) {
      return
    }

    // Game is complted
    if (isGameCompleted) {
      return;
    }

    // Game is over -- no need to CPU move
    if (chess.game_over()) {
      return;
    }

    // No CPU moves in two player games
    if (mode === MODE_TWO_PLAYER) {
      return;
    }


    // White faces up by default --- no need to flip the board
    if (turn === COLOR_WHITE) {
      return;
    }
    const cpuMove = cpuSelectMove(chess.moves({ verbose: true }));

    setMoveInProgress(true);
    setTimeout(() => {
      onPickupPiece()

      setTimeout(() => {
        chess.move(cpuMove);

        dispatch(updateGame({
          ...game,
          pgn: chess.pgn()
        }));

        // Reset move in progress
        setMoveInProgress(false);
      }, DELAY_CPU_THINKING);
    }, DELAY_CPU_MOVE);
  }, [dispatch, game, onPickupPiece, chess, history, isGameCompleted, mode, prevHistory, turn, moveInProgress]);
}

export function useGameOverEffect(chess) {
  const navigation = useNavigation();
  const route = useRoute();
  const [sharedState, dispatch] = useSharedState();

  const { games } = sharedState;
  const game = findGameById(games, route?.params?.id);
  const { showGameOverDialog } = useGameDialogs();

  const dialogAlreadyTriggered = React.useRef(false);
  React.useEffect(() => {
    const isGameCompleted = !!game?.dateCompleted
    const isGameOver = chess.game_over();

    // Game is not completed
    if (isGameCompleted) {
      return;
    }

    // Game is not over
    if (!isGameOver) {
      return
    }

    // Dialog already triggered
    // Needed otherwise will recursively update state
    if (dialogAlreadyTriggered.current) {
      return;
    }

    // Save game as completed
    dispatch(updateGame({
      ...game,
      pgn: chess.pgn(),
      dateCompleted: Date.now()
    }));

    const reason = getReasonString(chess);

    setTimeout(() => {
      showGameOverDialog(reason)
      // navigation.navigate('GameOverDialog', {
      //   id: route?.params?.id,
      //   reason
      // });
    }, DELAY_MOVE * 4);

    // Set dialog triggered
    dialogAlreadyTriggered.current = true;
  }, [chess, dispatch, game, navigation, route?.params?.id, showGameOverDialog])
}