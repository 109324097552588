import React from 'react';
import { View } from 'react-native-web';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SettingsItem from '../components/SettingsItem';
import Header from '../components/Header';

import { useDesignSystem } from '../hooks';
import ModalWrapper from '../components/ModalWrapper';
import { useSharedState, ACTION_SET_STATE, INITIAL_STATE} from '../state';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function DebugScreen() {
  const Tokens = useDesignSystem();
  const [, dispatch] = useSharedState();
  const safeAreaInset = useSafeAreaInsets()

  return (
    <ModalWrapper>
      <Header leftButtonIcon="multiply" />
      <View>
        <SettingsItem
          label="clear async storage"
          textStyle={{ color: Tokens.Colors.Red }}
          onPress={async () => {
            await AsyncStorage.clear();
            dispatch({
              type: ACTION_SET_STATE,
              payload: INITIAL_STATE,
            });

            window.location.replace('/')
          }}
        />
        <pre>
          {JSON.stringify(safeAreaInset, null, 2)}
        </pre>
      </View>
    </ModalWrapper>
  )
}

