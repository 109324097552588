import React from 'react';
import { Animated, Pressable, PanResponder } from 'react-native-web';
import { updateGame, useSharedState } from '../../state';
import Chess from 'chess.js';

import { useRoute } from '@react-navigation/native';
import { findGameById } from '../../utils';
import {
  BOARD_LETTERS as letters,
  BOARD_NUMBERS as numbers,
  MODE_TWO_PLAYER
} from '../../constants';
import { useGameDialogs } from '../../hooks';
import PieceReadOnly from './PieceReadOnly';

const SCALE_FROM = 1;
const SCALE_TO = 2.5;
const ROTATE_FROM = 0;
const ROTATE_TO = 1;

export default function Piece({
  gesturesEnabled = false,
  squareName,
  type,
  color,
  size,
  turn,
  moves = [],
  pgn = "",
  setSelectedPiece = () => {},
  setReadyForInteraction,
  onPickup,
  onDrop,
  style = {}
}) {
  const [sharedState, dispatch] = useSharedState();
  const { games } = sharedState;
  const route = useRoute();
  const { showPromotionDialog } =  useGameDialogs();
  const currentGame = findGameById(games, route?.params?.id);
  // TODO: get moves for piece

  // https://medium.com/react-native-london/2-the-pan-responder-react-native-animations-8-mins-f4197774d2e1
  const pan = React.useRef(new Animated.ValueXY()).current;
  const scale = React.useRef(new Animated.Value(SCALE_FROM)).current;
  const rotate = React.useRef(new Animated.Value(ROTATE_FROM)).current;

  const isFlipped = style?.transform
    ?.find(it => Object.keys(it).includes('rotate'))?.rotate !== '0deg';
  const panResponder = React.useMemo(
    () => PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        setSelectedPiece(squareName)
        pan.setOffset({
          x: pan.x._value,
          y: pan.y._value
        });

        Animated.parallel([
          Animated.spring(scale, { toValue: SCALE_TO, useNativeDriver: false }),
          Animated.spring(rotate, { toValue: ROTATE_TO, useNativeDriver: false })
        ]).start();
        onPickup();
        setReadyForInteraction(false);
      },
      onPanResponderMove: (e, gestureState) => {
        const modifiedGestureState = {
          ...gestureState,
          ...(!isFlipped
            ? {}
            : {
              dx: gestureState.dx - (gestureState.dx * 2),
              dy: gestureState.dy - (gestureState.dy * 2)
            }
          )
        };

        return Animated.event(
          [
            null,
            { dx: pan.x, dy: pan.y }
          ],
          { useNativeDriver: false }
        )(e, modifiedGestureState)
      },
      onPanResponderRelease: () => {
        const { x, y } = pan;

        function animateBackToOrigin() {
          onDrop({ success: false });
          setSelectedPiece(null);
          setReadyForInteraction(true)

          Animated.parallel([
            Animated.spring(scale, { toValue: SCALE_FROM, useNativeDriver: false }),
            Animated.spring(rotate, { toValue: ROTATE_FROM, useNativeDriver: false }),
            Animated.spring(
              pan,
              {
                toValue: { x: 0, y: 0 },
                useNativeDriver: false
              },
            )
          ]).start();
        }

        const offsets = getSquareOffets(squareName, letters, numbers);

        const centerPosition = getCenterOfSquare(size, {
          x: x._value,
          y: y._value
        });

        if (!isSquareOnBoard(size, centerPosition.x, centerPosition.y, offsets)) {
          animateBackToOrigin();

          return;
        }

        const nextSquareOffsets = getNextSquareOffsets(size, centerPosition.x, centerPosition.y);
        const currentSquareIndexes = getIndexesFromSquareName(squareName);
        const nextSquareName = getSquareFromIndexes({
          letterIndex: currentSquareIndexes.letterIndex + nextSquareOffsets.x,
          numberIndex: currentSquareIndexes.numberIndex + nextSquareOffsets.y,
        });

        const isValidMove = moves
          .find(it => it.from === squareName && it.to === nextSquareName);

        if (!isValidMove) {
          animateBackToOrigin();
          return;
        }

        Animated.parallel([
          Animated.spring(scale, { toValue: SCALE_FROM, useNativeDriver: false }),
          Animated.spring(rotate, { toValue: ROTATE_FROM, useNativeDriver: false }),
          Animated.spring(
            pan,
            {
              toValue: { x: nextSquareOffsets.x * size, y: nextSquareOffsets.y * size },
              useNativeDriver: false
            },
          )
        ]).start(async () => {
          const chess = new Chess()
          chess.load_pgn(pgn);

          const moves = chess.moves({ square: squareName, verbose: true });
          const move = moves.find(it => it.from === squareName && it.to === nextSquareName);

          // TODO: merge with onPress handler in Square.js
          function executeMove(move) {
            chess.move(move)
            dispatch(updateGame({
              ...currentGame,
              pgn: chess.pgn()
            }))

            setSelectedPiece(null);


            // in two player mode, the board still needs to flip
            // so the board is not ready for interaction after dropping a move
            if (currentGame?.mode === MODE_TWO_PLAYER) {
              return;
            }

            setReadyForInteraction(true);
          }

          const isPromotion = moves.piece === chess.PAWN && (move.to.includes('8'));
          if (isPromotion) {
            showPromotionDialog(move, executeMove)

            return;
          }

          executeMove(move);
        });
      }
    }),
    [showPromotionDialog, rotate, scale, isFlipped, setSelectedPiece, pgn, currentGame, moves, pan, size, squareName, dispatch, onPickup, onDrop, setReadyForInteraction]
  );

  return (
    <Animated.View
      style={[{
        position: 'absolute',
        transform: [
          { translateX: pan.x },
          { translateY: pan.y }
        ]
      }]}
      {...(gesturesEnabled && turn === color
        ? panResponder.panHandlers
        : {}
      )}
    >
      <Pressable
        onPress={() => {
          setSelectedPiece(squareName);
          onPickup()


          // readyForInteraction needs to be set to false in two player mode
          // because otherwise onTurnChange will get called before we finish
          // rotating the board.
          if (currentGame?.mode === MODE_TWO_PLAYER) {
            setReadyForInteraction(false)
          }

          // TODO:
          // - Add support for clicking from one to another
          // - Need to add press support for empty quares and setSelectedPiece to null
        }}
        style={[{
          justifyContent: 'center',
          alignItems: 'center',
          width: size,
          height: size,
          cursor: turn === color
            ? 'pointer'
            : 'auto',
        },
        style]}
      >
        <Animated.View style={{
          transform: [
            { scale: scale },
            {
              rotate: rotate.interpolate({
                inputRange: [0, 1],
                outputRange: ['0deg', ['10deg', '-10deg'][Math.floor(Math.random() * 2)]]
              })
            }
          ]
        }}>
          <PieceReadOnly
            size={size}
            type={type}
            color={color}
          />
        </Animated.View>
      </Pressable>
    </Animated.View>
  );
}

function getCenterOfSquare(size, offset = {}) {
  const centerOffset = size / 2;

  return {
    x: offset.x + centerOffset,
    y: offset.y + centerOffset
  }
}

function getSquareOffets(square, letters, numbers) {
  const left = letters.reduce((acc, letter, index) => {
    if (square.includes(letter)) {
      return index;
    }

    return acc;
  }, 0);

  const top = numbers.reduce((acc, number, index) => {
    if (square.includes(number)) {
      return index;
    }

    return acc;
  }, 0);

  return {
    top,
    right: numbers.length - 1 - left,
    bottom: letters.length - 1 - top,
    left,
  };
}

function isSquareOnBoard(size, x, y, offsets) {
  const boardOffsetLeft = -offsets.left * size;
  const boardOffsetRight = offsets.right * size + size;
  const boardOffsetTop = -offsets.top * size;
  const boardOffsetBottom = offsets.bottom * size + size;

  if (x < boardOffsetLeft) {
    return false;
  }

  if (x > boardOffsetRight) {
    return false;
  }

  if (y < boardOffsetTop) {
    return false;
  }

  if (y > boardOffsetBottom) {
    return false;
  }

  return true;
}

function getNextSquareOffsets(size, x, y) {
  return {
    x: Math.floor(x / size),
    y: Math.floor(y / size)
  };
}

function getIndexesFromSquareName(squareName) {
  const [letter, numberString] = squareName.split('');

  const xIndex = letters.reduce((acc, it, index) => {
    if (it.includes(letter)) {
      return index;
    }

    return acc;
  }, 0)

  const yIndex = numbers.reduce((acc, it, index) => {
    if (it.toString().includes(numberString)) {
      return index;
    }

    return acc;
  }, 0)

  return {
    letterIndex: xIndex,
    numberIndex: yIndex
  }
}

function getSquareFromIndexes(indexes) {
  const { letterIndex, numberIndex } = indexes;

  return letters[letterIndex] + numbers[numberIndex];
}