import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMediaQuery } from 'react-responsive';
import {
  ASYNC_STORAGE_SETTINGS,
  INITIAL_SETTINGS,
  MAX_PHONE_SCREEN_DIMENSIONS,
  PIECE_BISHOP,
  PIECE_KNIGHT,
  PIECE_QUEEN,
  PIECE_ROOK
} from '../constants';
import { Dimensions } from 'react-native-web';
import { CardStyleInterpolators } from '@react-navigation/stack';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import {
  COLOR_WHITE
} from '../constants';
import { addGame, updateGame, useSharedState } from '../state';
import { createGameData, findGameById } from '../utils';
import { ContextMenuContext } from '../contexts/ContextMenuContext';
import { ContentSheetContext } from '../contexts/ContentSheetContext';
import Button from '../components/Button';
import InstantReplay from '../components/InstantReplay';
import { ThemeContext } from '../contexts/ThemeContext';

const Chess = require('chess.js');

export function useScreenData() {
  const [isPhone, setIsPhone] = React.useState(false);
  const [isPortrait, setIsPortrait] = React.useState(true);
  const [isStandalone, setIsStandalone] = React.useState(false);
  const [width, setWidth] = React.useState(false);
  const [height, setHeight] = React.useState(false);

  const isPortraitInitial = useMediaQuery(
    { query: '(orientation: portrait)'},
    undefined,
    (isPortrait) => {
      setIsPortrait(isPortrait);
    }
  );
  const isStandaloneInitial = useMediaQuery(
    { query: '(display-mode: standalone)' },
    undefined,
    (isStandalone) => {
      setIsStandalone(isStandalone);
    }
  )

  const isPhoneInitial = useMediaQuery({
    maxDeviceWidth: MAX_PHONE_SCREEN_DIMENSIONS.width,
    maxDeviceHeight: MAX_PHONE_SCREEN_DIMENSIONS.height
  }, undefined, (isPhone) => {
    setIsPhone(isPhone)
  });
  const windowSize = Dimensions.get('window');

  React.useEffect(() => {
    function windowChangeHandler({ window: windowSize }) {
      setWidth(windowSize.width);
      setHeight(windowSize.height);
    }

    setIsPhone(isPhoneInitial);
    setIsPortrait(isPortraitInitial);
    setIsStandalone(isStandaloneInitial)
    setWidth(windowSize.width);
    setHeight(windowSize.height);

    Dimensions.addEventListener('change', windowChangeHandler)

    return function cleanup() {
      Dimensions.removeEventListener('change', windowChangeHandler)
    }
  }, [isPhoneInitial, isPortraitInitial, isStandaloneInitial, windowSize.width, windowSize.height])

  return {
    isPhone,
    isPortrait,
    isStandalone,
    width,
    height,
  }
}

export function useDesignSystem() {
  const fontFamily = 'ui-rounded, "SF Pro Rounded", system-ui, san-serif';

  const Tokens = {
    Typography: {
      Title: {
        fontFamily,
        fontSize: 36,
        fontWeight: '800',
        letterSpacing: -2
      },
      Heading: {
        fontFamily,
        fontSize: 28,
        fontWeight: '800',
        letterSpacing: -1
      },
      SubHeading: {
        fontFamily,
        fontSize: 24,
        fontWeight: '800',
        letterSpacing: -1
      },
      BodyLarge: {
        fontFamily,
        fontSize: 18
      },
      BodyLargeHeavy: {
        fontFamily,
        fontSize: 18,
        fontWeight: '800'
      },
      Body: {
        fontFamily,
        fontSize: 16
      },
      BodyHeavy: {
        fontFamily,
        fontSize: 16,
        fontWeight: '800'
      },
      BodySmall: {
        fontFamily,
        fontSize: 14
      },
      BodySmallHeavy: {
        fontFamily,
        fontSize: 14,
        fontWeight: '800'
      },
      Tag: {
        fontFamily,
        fontSize: 12
      },
      Menu: {
        fontFamily,
        fontSize: 24,
        fontWeight: '700',
        letterSpacing: -1.1
      },
      Settings: {
        fontFamily,
        fontSize: 18,
        fontWeight: '700'
      },
      Button: {
        fontFamily,
        fontWeight: 'bold',
        fontSize: 18,
        letterSpacing: -0.5,
      }
    },
    Colors: {
      // Foundational Colors
      Transparent: 'transparent',
      White: 'rgba(255, 255, 255, 1)',
      WhiteDisabled: 'rgba(255, 255, 255, 0.25)',
      Black: 'rgba(0, 0, 0, 1)',
      BlackDisabled: 'rgba(0, 0, 0, 0.25)',
      Red: 'rgba(255, 0, 0, 1)',
      RedDisabled: 'rgba(255, 0, 0, 0.25)',
      Blue: 'rgba(0, 0, 255, 1)',
      BlueDisabled: 'rgba(0, 0, 255, 0.25)',
      Yellow: 'rgba(255, 255, 0, 1)',
      YellowDisabled: 'rgba(255, 255, 0, 0.25)',

      LetterPressBackground: '#F0EFEC',
      LetterPressBorder: '#545454',
      LetterPressText: '#676767',
      LetterPressBlue: '#00A2FF',
      // LetterPressBlueLight: '#78c9f6',
      LetterPressRed: 'rgba(255, 67, 47, 1))', //'#ff432F',
      LetterPressRedLight: '#f8998e'
    },
    Dimensions: {
      Smallest: 4,
      Smaller: 8,
      Small: 16,
      Standard: 24,
      Large: 32,
      Larger: 48,
      Largest: 64
    },
    Shadows: {
      None: {
        boxShadow: 'none',
      },
      /* Shadow 1dp */
      Elevation1: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
      },
      /* Shadow 2dp */
      Elevation2: {
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)',
      },
      /* Shadow 3dp */
      Elevation3: {
        boxShadow: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
      },
      /* Shadow 4dp */
      Elevation4: {
        boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)',
      },
      /* Shadow 6dp */
      Elevation6: {
        boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)',
      },
      /* Shadow 8dp */
      Elevation8: {
        boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',
      },
      /* Shadow 9dp */
      Elevation9: {
        boxShadow: '0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20)',
      },
      /* Shadow 12dp */
      Elevation12: {
        boxShadow: '0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)',
      },
      /* Shadow 16dp */
      Elevation16: {
        boxShadow: '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20)',
      },
      /* Shadow 24dp */
      Elevation24: {
        boxShadow: '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20)'
      }
    }
  }

  const Functional = {
    Typography: {},
    Colors: {
      // Functional Colors
      ChessboardBackground: 'rgba(0, 0, 0, 0.05)',
      Primary: Tokens.Colors.Black,
      Destructive: Tokens.Colors.LetterPressRedLight,
      Debug: Tokens.Colors.Blue,
      SettingsAccent: '#006FF8',
      Heading: '#222',
      Body: '#4848',
    }
  }

  return {
    ...Tokens,
    Functional
  };
}

export function useMaxContentWidth() {
  const { isPhone, width } = useScreenData();

  return isPhone
    ? width :
    MAX_PHONE_SCREEN_DIMENSIONS.width;
}

export function usePresentationTokens() {
  const { isPhone } = useScreenData();

  const PresentationTokens = {
    ModalScreen: {
      cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
      presentation: !isPhone && 'transparentModal'
    },
    DialogScreen: {
      cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
      presentation: 'transparentModal'
    },
  }

  return PresentationTokens;
}

export function useSettings() {
  const [settings, setSettings] = React.useState(null);

  useFocusEffect(
    React.useCallback(() => {
      async function getInitialValue() {
        const settingsString = await AsyncStorage.getItem(ASYNC_STORAGE_SETTINGS);

        // Set initial settings
        if (settingsString === null) {
          setSettings(INITIAL_SETTINGS);

          return;
        }

        setSettings(JSON.parse(settingsString));
      }

      getInitialValue();
    }, [])
  )

  React.useEffect(() => {
    async function setAsyncStorageSettings(nextValue) {
      if (nextValue === null) {
        return;
      }

      const nextValueString = JSON.stringify(nextValue);

      await AsyncStorage.setItem(ASYNC_STORAGE_SETTINGS, nextValueString);
    }

    setAsyncStorageSettings(settings)
  }, [settings]);

  return [
    (settings ? settings : INITIAL_SETTINGS),
    setSettings
  ];
}

export function useChess(pgn = "") {
  return React.useMemo(() => {
    const chess = new Chess();
    chess.load_pgn(pgn);

    return chess;
  }, [pgn]);
}

export function useGameDialogs() {
  const Theme = React.useContext(ThemeContext);
  const { showContextMenu } = React.useContext(ContextMenuContext)
  const { showContentSheet } = React.useContext(ContentSheetContext)
  const route = useRoute();
  const navigation = useNavigation();
  const [sharedState, dispatch] = useSharedState();
  const { games } = sharedState;
  const currentGame = findGameById(games, route?.params?.id)
  const winner = route?.params?.winner || COLOR_WHITE;
  const reason = route?.params?.reason || 'checkmate';
  const isGameOver = !!currentGame?.dateCompleted;

  function showGameOverDialog(options = {}) {
    showContentSheet(
      "Game Over",
      `${winner ? 'Black' : 'White'} wins by ${reason}`,
      [
        {
          title: "Instant Replay",
          type: Button.Type.Secondary,
          onPress: () => {
            showInstantReplayDialog();
            // navigation.navigate('InstantReplay', {
            //   id: route?.params?.id
            // })
          }
        },
        {
          title: "Rematch",
          onPress: () => {
            navigation.goBack();

            setTimeout(() => {
              const newGame = createGameData({
                mode: currentGame.mode,
                color: COLOR_WHITE
              });

              dispatch(addGame(newGame))
              navigation.navigate(
                'Game',
                { id: newGame.id }
              )
            }, 500);
          }
        }
      ]
    );
  }

  function showGameActionsDialog() {
    showContentSheet(
      "Game Paused",
      null,
      // "Select an game option",
      [
        {
          title: "Settings",
          type: Button.Type.Secondary,
          onPress: () => {
            navigation.navigate('Settings', {
              screen: 'SettingsGame',
              params: { title: 'Game Settings' }
            });
          }
        },
        {
          title: "Instant Replay",
          type: Button.Type.Secondary,
          onPress: () => {
            showInstantReplayDialog();
            // navigation.navigate('InstantReplay', {
            //   id: route?.params?.id,
            //   startFromEnd: true
            // });
          }
        },
        {
          title: "Resign",
          type: Button.Type.Secondary,
          disabled: isGameOver,
          tint: Theme.Colors.Danger,
          onPress: () => {
            showResignDialog()
          },
        },
        {
          title: "Cancel",
          type: Button.Type.Tertiary,
        }
      ]
    )
  }

  function showResignDialog() {
    showContentSheet(
      "Resign",
      "Are you sure you want to resign?",
      [
        {
          title: "Resign",
          type: 'destructive',
          onPress: () => {
            const updatedGame = {
              ...currentGame,
              dateCompleted: Date.now()
            }

            dispatch(updateGame(updatedGame))

            setTimeout(() => {
              showGameOverDialog();
            }, 300);
          }
        },
        {
          title: "Cancel",
          type: Button.Type.Tertiary,
        }
      ]
    );
  }

  function showPromotionDialog(move, executeMove = () => {}) {
    const promotionOptions = [
      {
        text: 'Queen',
        piece: PIECE_QUEEN,
      },
      {
        text: 'Knight',
        piece: PIECE_KNIGHT,
      },
      {
        text: 'Rook',
        piece: PIECE_ROOK,
      },
      {
        text: 'Bishop',
        piece: PIECE_BISHOP,
      },
    ]

    showContextMenu(
      "Pawn Promotion",
      "Select a piece to replace your pawn with.",
      promotionOptions.map(it => ({
        text: it.text,
        onPress: () => {
          const nextMove = {
            from: move.from,
            to: move.to,
            promotion: it.piece
          }

          executeMove(nextMove);
        }
      })),
      { cancelable: false}
    );
  }

  function showInstantReplayDialog() {
    showContentSheet(
      "Instant Replay",
      "Tap the board to step through moves",
      [
        {
          title: "Done",
          type: Button.Type.Secondary
        }
      ],
      {
        children: (
          <InstantReplay
            pgn={currentGame?.pgn}
            startFromEnd={!isGameOver}
          />
        )
      }
    )
  }



  return {
    showGameOverDialog,
    showGameActionsDialog,
    showResignDialog,
    showPromotionDialog,
    showInstantReplayDialog
  }
}