import { View, Text } from 'react-native-web';

export default function Square({
  size,
  color,
  name,
  showName = false,
  style = {},
  textStyle = {}
}) {
  return (
    <View
      key={name}
      style={[{
        width: size,
        height: size,
        backgroundColor: color
      }, style]}
    >
      {showName && (
        <Text
          style={[{
            fontSize: 10,
            padding: 2,
            color: 'rgba(0, 0, 0, 0.3)'
          }, textStyle]}
        >
            {name}
        </Text>
      )}
    </View>
  )
}
