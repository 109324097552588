import React from 'react';
import { View } from 'react-native-web';
import { BOARD_LETTERS as letters, BOARD_NUMBERS as numbers } from '../../constants';
import PieceReadOnly from './PieceReadOnly';

export default function LayerPiecesInactive({
  pieceStyles = {},
  board,
  squareSize
}) {
  return (
    <View style={{ position: 'absolute' }}>
      {board.map((row, numberIndex) => (
        <View key={numbers[numberIndex]} style={{ flexDirection: 'row', position: 'absolute' }}>
          {row.map((piece, letterIndex) => {
            const squareName = letters[letterIndex] + numbers[numberIndex];

            if (!piece) {
              return null;
            }

            return (
              <PieceReadOnly
                key={squareName}
                type={piece.type}
                color={piece.color}
                size={squareSize}
                style={{
                  position: 'absolute',
                  left: letterIndex * squareSize,
                  top: numberIndex * squareSize,
                  width: squareSize,
                  height: squareSize,
                  ...pieceStyles
                }}
              />
            );
          })}
        </View>
      ))}
    </View>
  )
}