import React from 'react';
import { ScrollView, View, Text } from "react-native-web";
import VStack from "../../primitives/VStack"
import Table from "../../components/Table";
import { ThemeContext } from "../../contexts/ThemeContext";

const fontFamily = 'ui-rounded, "SF Pro Rounded", system-ui, sans-serif';

export default function ComponentTableScreen() {
  const Theme = React.useContext(ThemeContext);

  return (
    <ScrollView style={{
      paddingTop: Theme.Dimensions.Large
    }}>
      <VStack style={{ marginBottom: 40 }}>
        <HeadingText>Table: Plain</HeadingText>
        <Table
          type="plain"
          title="Title"
          description="Description"
        >
          <Table.Item title="Item" />
          <Table.Item
            title="Item"
            renderRightComponent={() => <Square />}
          />
          <Table.Item
            title="Item"
            renderLeftComponent={() => <Square />}
          />
          <Table.Item
            title="Item"
            subtitle="Subtitle"
            renderLeftComponent={() => <Square />}
            renderRightComponent={() => <Square />}
            onPress={() => alert('onPress handler')}
          />
        </Table>
      </VStack>
      <VStack style={{ marginBottom: 40 }}>
      <HeadingText>Table: Inset Grouped</HeadingText>
      <Table
        title="Title"
        description="Description"
        type="inset"
      >
        <Table.Item title="Item" />
        <Table.Item
          title="Item"
          renderRightComponent={() => <Square />}
        />
        <Table.Item
          title="Item"
          renderLeftComponent={() => <Square />}
        />
        <Table.Item
          title="Item"
          subtitle="Subtitle"
          renderLeftComponent={() => <Square />}
          renderRightComponent={() => <Square />}
          onPress={() => alert('onPress handler')}
        />
      </Table>
      </VStack>
    </ScrollView>
  )
}

function Square() {
  const Theme = React.useContext(ThemeContext);

  return (
    <View
      style={{
        width: 20,
        height: 20,
        borderRadius: 3,
        backgroundColor: Theme.Colors.Primary
      }}
    />
  )
}

function HeadingText({ children }) {
  const Theme = React.useContext(ThemeContext);

  return (
    <Text style={{
      paddingHorizontal: Theme.Dimensions.Standard,
      paddingBottom: Theme.Dimensions.Standard,
      fontFamily,
      fontSize: 18,
      fontWeight: 'bold',
    }}>
      {children}
    </Text>
  )
}