import React from "react";
import Confetti from "../../components/Confetti";
import VStack from "../../primitives/VStack";

export default function ComponentGameScreen() {
  return (
    <VStack style={{
      flex: 1,
      justifyContent: 'center'
    }}>
      <Confetti />
    </VStack>
  )
}