import { forwardRef } from 'react';
import { View } from 'react-native';

const VStack = forwardRef(({ children, style = {} }, ref) => (
  <View ref={ref} style={[{
    flexDirection: 'column'
  }, style]}>
    {children}
  </View>
));

export default VStack;