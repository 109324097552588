import React from 'react';
import { Animated, View, Pressable } from 'react-native-web';
import Reanimated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';

import {
  COLOR_BLACK,
  COLOR_WHITE
} from '../constants';
import {
  useDesignSystem,
  useChess,
} from '../hooks';
import { useMeasure, usePrevious } from 'react-use';
import { ThemeContext } from '../contexts/ThemeContext';
import useContentSheet from '../hooks/useContentSheet';
import EmojiAvatar from '../components/EmojiAvatar';

export default function Avatars({
  pgn,
  pendingMove,
  bubbleColor,
  playerOne,
  playerTwo,
}) {
  const chess = useChess(pgn);
  const turn = chess.turn();
  const inCheck = chess.in_check()
  const { showProfileDialog } = useContentSheet();

  return (
    <View style={{
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Pressable onPress={() => showProfileDialog({ showActions: false, editable: false })}>
        <Avatar
          color={COLOR_WHITE}
          turn={turn}
          pgn={pgn}
          pendingMove={pendingMove}
          bubbleColor={bubbleColor}
          player={playerOne}
        />
      </Pressable>
      <Avatar
        color={COLOR_BLACK}
        turn={turn}
        pgn={pgn}
        inCheck={inCheck}
        pendingMove={pendingMove}
        bubbleColor={bubbleColor}
        player={playerTwo}
      />
    </View>
  )
}

function Avatar({
  color,
  turn,
  pgn,
  bubbleColor,
  pendingMove = false,
  player = {},
  style = {}
}) {
  const [zIndex, setZIndex] = React.useState(0);
  const TRANSLATE_X_FROM = 0;
  const TRANSLATE_X_TO = color === COLOR_WHITE ? -20 : 20
  // const SCALE_FROM = turn === color ? 1 : 0.7;
  const SCALE_FROM = 0.8;
  const SCALE_TO = 0.8;
  const translateX = React.useRef(new Animated.Value(TRANSLATE_X_FROM)).current
  const scale = React.useRef(new Animated.Value(SCALE_FROM)).current
  // const [sharedState] = useSharedState();
  const Theme = React.useContext(ThemeContext);

  React.useEffect(() => {
    if (pendingMove === true) {
      Animated.parallel([
        Animated.spring(translateX, {
          toValue: TRANSLATE_X_TO,
          useNativeDriver: false
        }),
        Animated.spring(scale, {
          toValue: SCALE_TO,
          useNativeDriver: false
        })
      ]).start();

      return;
    } else {
      Animated.parallel([
        Animated.spring(translateX, { toValue: TRANSLATE_X_FROM, useNativeDriver: false }),
        Animated.spring(scale, {
          toValue: SCALE_FROM,
          useNativeDriver: false
        })
      ]).start()
    }
    setZIndex(turn === color ? 1 : 0);
  }, [pendingMove, turn, SCALE_FROM, TRANSLATE_X_TO, scale, translateX, setZIndex, color])

  const animationStyles = {
    transform: [
      { translateX: translateX },
      { scale: scale }
    ],
    zIndex: zIndex
  }

  const avatarSize = 120;
  const chess = useChess(pgn);

  return (
    <Animated.View
      style={[
        {
          position: 'relative',
          width: avatarSize,
          height: avatarSize,
          borderRadius: avatarSize,
          justifyContent: 'center',
          alignItems: 'center',
        },
        style,
        animationStyles
      ]}
    >
      {color === COLOR_WHITE && (
        <EmojiAvatar
          emoji={player.emoji}
          color={player.color}
          size={avatarSize}
          style={{
            borderWidth: 5,
            borderColor: 'white',
            ...Theme.Shadows.Avatar
          }}
        />
      )}
      {color === COLOR_BLACK && (
        <EmojiAvatar
          emoji={player.emoji}
          color={player.color}
          size={avatarSize}
          style={{
            borderWidth: 5,
            borderColor: 'white',
            ...Theme.Shadows.Avatar
          }}
        />
      )}
      <Message
        pgn={pgn}
        isVisible={turn !== color}
        color={color}
        message={getAvatarMessage(chess)}
        pendingMove={pendingMove}
        bubbleColor={bubbleColor}
      />
    </Animated.View>
  )
}

function getAvatarMessage(chess) {
  if (chess.in_checkmate()) {
    return "Checkmate!"
  }

  if (chess.in_check()) {
    return "Check!"
  }

  return null
}


function Message({ pgn, isVisible, bubbleColor }) {
  const Tokens = useDesignSystem();
  const [textRef, { height: textHeight}] = useMeasure();

  const opacity = useSharedValue(0);
  const scale = useSharedValue(0);
  const chess = useChess(pgn);

  const message = React.useRef(getAvatarMessage(chess))

  const prevIsVisible = usePrevious(isVisible);

  React.useEffect(() => {
    // console.log({
    //   isVisible,
    //   'message.current': message.current
    // })

    if (isVisible && prevIsVisible === false) {
      message.current = getAvatarMessage(chess);
    }

    if (isVisible && message.current) {
      opacity.value = withSpring(1)
      scale.value = withSpring(1)
      return;
    }

    opacity.value = withSpring(0)
    scale.value = withSpring(0)
    return;
  }, [isVisible, pgn, chess, opacity, prevIsVisible, scale])

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
      transform: [{
        scale: scale.value
      }]
    }
  });

  return (
    <Reanimated.Text
      ref={textRef}
      style={[{
        position: 'absolute',
        // top: -textHeight,
        // left: -(textWidth / 2),
        bottom: -(textHeight * 1.5),
        paddingVertical: Tokens.Dimensions.Smaller,
        paddingHorizontal: Tokens.Dimensions.Small,
        borderRadius: 20,
        backgroundColor: bubbleColor === 'blue'
          ? '#1982FC' // blue
          : '#43CC47', // green
        ...Tokens.Shadows.Elevation24,

        ...Tokens.Typography.Body,
        fontWeight: '500',
        color: 'white',
        zIndex: 3,
      }, animatedStyle]}
    >
      {message.current}
    </Reanimated.Text>
  )
}
