import React, {useMemo} from 'react'
import Animated from 'react-native-reanimated'
import {View, Dimensions, StyleSheet } from 'react-native'

const COLORS = [
  'rgb(149, 58, 255)',
  'rgb(255, 195, 41)',
  'rgb(255, 101, 26),',
  'rgb(123, 92, 255)',
  'rgb(76, 126, 255)',
  'rgb(71, 192, 255),',
  'rgb(255, 47, 39)',
  'rgb(255, 91, 134)',
  'rgb(233, 122, 208)'
];
const SHAPES = [
  'square',
  'circle',
  'rectangle'
];
const POSITION = [
  'foreground',
  'background'
]

const CONFETTI_SIZE = 16

const createConfetti = (numberOfPieces) => {
  const {width: screenWidth} = Dimensions.get('screen')

  return [...new Array(numberOfPieces)].map((_, i) => {
    const clock = new Animated.Clock()
    const shape = SHAPES[i % SHAPES.length];
    const position = POSITION[Math.floor(Math.random() * POSITION.length)];
    const xVel = Math.random() * 400 - 200;
    const yVel = (Math.random() * 150 + 150);
    const angleVel = (i % 8 ? 50 : 100) * Math.PI;
    const delay = Math.floor(i / 10) * 0.15;

    return {
      key: i,
      // Spawn confetti from two different sources, a quarter
      // from the left and a quarter from the right edge of the screen.
      x: new Animated.Value(
        screenWidth * (i % 2 ? 0.25 : 0.75) - CONFETTI_SIZE / 2
      ),

      y: new Animated.Value(-100),
      angle: new Animated.Value(0),
      xVel: new Animated.Value(xVel),
      yVel: new Animated.Value(
        position === 'background' ? yVel * 2 : yVel // background particles should move 2x because they're scaled down 2x
      ),
      angleVel: new Animated.Value(angleVel),
      delay: new Animated.Value(delay),
      elasticity: Math.random() * 0.3 + 0.1,
      color: COLORS[i % COLORS.length],
      clock,


      // Modifiers
      // xVel: new Animated.Value(Math.random() * 400 - 200),
      // yVel: new Animated.Value(Math.random() * 150 + 150),
      // angleVel: new Animated.Value((Math.random() * 8 - 1.5) * Math.PI),
      shape,
      position,
      // delay: new Animated.Value(Math.floor(i / 10) * 0.3),
    }
  })
}

const Confetti = ({
  numberOfPieces = 300
}) => {
  const confetti = useMemo(() => createConfetti(numberOfPieces), [numberOfPieces])

  return (
    <View pointerEvents="none" style={StyleSheet.absoluteFill}>
      {confetti.map(
        ({
          key,
          x,
          y,
          angle,
          xVel,
          yVel,
          angleVel,
          color,
          elasticity,
          delay,
          clock,

          shape,
          position
        }) => {
          return (
            <React.Fragment key={key}>
              <Animated.Code>
                {() => {
                  const {
                    startClock,
                    stopClock,
                    set,
                    add,
                    sub,
                    divide,
                    diff,
                    multiply,
                    cond,
                    clockRunning,
                    greaterThan,
                    lessThan,
                  } = Animated
                  const { width: screenWidth, height: screenHeight } = Dimensions.get('window')

                  const timeDiff = diff(clock)
                  const dt = divide(timeDiff, 1000)
                  const dy = multiply(dt, yVel)
                  const dx = multiply(dt, xVel)
                  const dAngle = multiply(dt, angleVel)

                  return cond(
                    clockRunning(clock),
                    [
                      cond(
                        greaterThan(delay, 0),
                        [set(delay, sub(delay, dt))],
                        [
                          set(y, add(y, dy)),
                          set(x, add(x, dx)),
                          set(angle, add(angle, dAngle)),
                        ]
                      ),
                      cond(greaterThan(x, screenWidth - CONFETTI_SIZE), [
                        set(x, screenWidth - CONFETTI_SIZE),
                        set(xVel, multiply(xVel, -elasticity)),
                      ]),
                      cond(lessThan(x, 0), [
                        set(x, 0),
                        set(xVel, multiply(xVel, -elasticity)),
                      ]),
                      cond(greaterThan(y, screenHeight + 100), [
                        stopClock(clock)
                      ])
                    ],
                    [startClock(clock), timeDiff]
                  )
                }}
              </Animated.Code>

              <Animated.View
                style={[
                  styles.confettiContainer,
                  {
                    transform: [
                      { translateX: x },
                      { translateY: y },
                      { rotateZ: Animated.concat(angle, 'deg') },
                      { rotateX: Animated.concat(angle, 'deg') },
                      { rotateY: Animated.concat(angle, 'deg') },
                    ]
                  },
                ]}
              >
                <View
                  style={[
                    styles.confetti,
                    {
                      backgroundColor: color,
                      ...getConfettiSize(shape, position),
                      transform: [
                        { scale: shape === 'rectangle' ? 1: 0.75 }
                      ]
                    },
                  ]}
                />
              </Animated.View>
            </React.Fragment>
          )
        }
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  confettiContainer: {
    position: 'absolute',
    top: -CONFETTI_SIZE * 2,
    left: 0,
    overflow: 'hidden',
    zIndex: 200,
  },
  confetti: {
    width: CONFETTI_SIZE * 1.5,
    height: CONFETTI_SIZE * 0.75,
  },
})

export default Confetti

function getConfettiSize(shape = 'square', position = 'foreground') {
  const CIRLE_SIZE = CONFETTI_SIZE * 0.75;
  const SQUARE_SIZE = CONFETTI_SIZE * 0.75;
  const positionMultiplier = position === 'foreground' ? 1: 0.75;
  const opacity = position === 'background' ? 0.5 : 1;

  if (shape === 'circle') {
    return {
      width: CIRLE_SIZE * positionMultiplier,
      height: CIRLE_SIZE * positionMultiplier,
      borderRadius: CIRLE_SIZE * positionMultiplier,
      opacity
    };
  }

  if (shape === 'rectangle') {
    return {
      width: CONFETTI_SIZE * positionMultiplier * 1.25,
      height: CONFETTI_SIZE * positionMultiplier * 0.5,
      opacity
    }
  }

  return {
    width: SQUARE_SIZE * positionMultiplier,
    height: SQUARE_SIZE * positionMultiplier,
    opacity
  }
}