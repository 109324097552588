import React, { useImperativeHandle } from 'react';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withDelay, withTiming } from 'react-native-reanimated';
import { View, Pressable } from 'react-native-web';
import { useMeasure } from 'react-use';
import ChessboardReadOnly from '../components/Chessboard/ChessboardReadOnly';
import EmojiAvatar from './EmojiAvatar';
import { useNavigation } from '@react-navigation/native';
import { setGames, useSharedState } from '../state';
import { useDesignSystem, useMaxContentWidth } from '../hooks';
import { ContextMenuContext } from '../contexts/ContextMenuContext';
import Text from '../primitives/Text';
import { ThemeContext } from '../contexts/ThemeContext';

export default React.forwardRef(function({
  id,
  title,
  body,
  pgn = "",
  isDeletable = false
}, ref) {
  const navigation = useNavigation();
  const [sharedState, dispatch] = useSharedState();
  const { games, profile } = sharedState;
  const Tokens = useDesignSystem();
  const Theme = React.useContext(ThemeContext)
  const maxContentWidth = useMaxContentWidth();
  const { showContextMenu } = React.useContext(ContextMenuContext);
  const boardSize = maxContentWidth / 4;

  // Reanimated
  const opacity = useSharedValue(1);
  const height = useSharedValue(0);
  const [measureRef, { height: contentHeight }] = useMeasure();

  const config = {
    duration: 500,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
  };

  React.useEffect(() => {
    height.value = contentHeight + Tokens.Dimensions.Standard;
    opacity.value = 1
  }, [contentHeight, height, opacity, Tokens.Dimensions.Standard]);

  function onRemove(callback = () => {}) {
    opacity.value = withTiming(0, config);
    height.value = withDelay(
      300,
      withTiming(0, config, () => {
        callback()
      }),
    )
  }

  useImperativeHandle(ref, () => ({
    onRemove
  }))

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
      height: height.value
    }
  })

  return (
    <Animated.View
      ref={ref}
      style={[
        {
          overflow: 'hidden',
        },
        animatedStyle
      ]}
    >
      <Pressable
        ref={measureRef}
        onPress={() => navigation.navigate('Game', {
          id
        })}
        onLongPress={() => {
          if (!isDeletable) {
            return;
          }

          showContextMenu(
            null,
            null,
            [{
              text: 'Remove Game',
              type: 'destructive',
              onPress: () => {
                onRemove(() => {
                  const nextGames = games.filter(it => it.id !== id);

                  dispatch(setGames(nextGames));
                });
              }
            }]
          )
        }}
        style={{
          flexDirection: 'row',
          paddingHorizontal: Tokens.Dimensions.Standard,
          paddingBottom: Tokens.Dimensions.Standard,
        }}
      >
        <ChessboardReadOnly
          pgn={pgn}
          size={boardSize}
          style={{ pointerEvents: 'none' }}
        />
        <View style={{
          flex: 1,
          justifyContent: 'center',
          marginLeft: Tokens.Dimensions.Standard
        }}>
          <Text style={{
            ...Tokens.Typography.BodyLarge,
            fontWeight: 'bold',
            paddingBottom: Tokens.Dimensions.Smallest
          }}>
            {title}
          </Text>
          <Text
            numberOfLines={1}
            style={{
              ...Tokens.Typography.Body,
              color: Theme.Colors.GameBodyLabel,
              paddingBottom: Tokens.Dimensions.Smaller
            }}
          >
            {body}
          </Text>
          <View style={{
            flexDirection: 'row'
          }}>
            <>
              <EmojiAvatar
                color={profile.color}
                emoji={profile.emoji}
                size={28}
              />
              <EmojiAvatar
                size={28}
                color="#E2E2E2"
                emoji="robot_face"
                style={{
                  zIndex: -1,
                  marginLeft: -Tokens.Dimensions.Smaller
                }}
              />
            </>
          </View>
        </View>
      </Pressable>
    </Animated.View>
  )
});