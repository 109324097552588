import React from 'react';
import { View } from 'react-native-web';
import { PIECE_KING } from '../../constants';
import Square from './Square';
import { BOARD_LETTERS as letters, BOARD_NUMBERS as numbers } from '../../constants';
import { ThemeContext } from '../../contexts/ThemeContext';

export default function HighlightLayer({
  board,
  squareSize,
  pgn,
  selectedPiece,
  moves,
  lastMove,
  turn,
  inCheck,
  playerOneColor,
  showPossibleMoves = false,
  showSelectedPiece = false
}) {
  const Theme = React.useContext(ThemeContext);

  return (
    <View style={{ position: 'absolute' }}>
      {board.map((row, numberIndex) => (
        <View key={numbers[numberIndex]} style={{ flexDirection: 'row' }}>
          {row.map((piece, letterIndex) => {
            const filteredMoves = moves.filter(it => it.from === selectedPiece);
            const squareName = letters[letterIndex] + numbers[numberIndex];
            const isPossibleMove = filteredMoves.reduce((acc, move) => {
              if (move.to === squareName) {
                return true;
              }
              return acc;
            }, false);
            const isSelectedPiece = squareName === selectedPiece;
            const isPartOfLastMove = [lastMove.from, lastMove.to].includes(squareName);

            const isKingChecked = piece?.type === PIECE_KING && (turn === piece?.color) && inCheck;

            // moves.forEach(it => {
            //   console.log(it)
            //   if (it.piece === PIECE_KING) {
            //     console.log(it);
            //   }
            // })
            // console.log(it)

            // const isKingChecked = [lastMove.from, lastMove.to].includes(squareName);

            let color;
            if (isPartOfLastMove) {
              color = Theme.Colors.ChessboardSquareLastMove
            }

            if (isSelectedPiece && showSelectedPiece) {
              color = Theme.Colors.ChessboardSquarePossibleMove
            }

            if (isPossibleMove && showPossibleMoves) {
              color = Theme.Colors.ChessboardSquarePossibleMove
            }

            if (isKingChecked) {
              color = Theme.Colors.ChessboardSquareCheck
            }

            return (
              <Square
                key={squareName}
                name={squareName}
                size={squareSize}
                color={color}
                pgn={pgn}
              />
            );
          })}
        </View>
      ))}
    </View>
  )
}