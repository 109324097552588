import { Text, TouchableHighlight, View } from "react-native-web";
import { useDesignSystem } from "../hooks";

export default function SettingsItem({ label, rightLabel, onPress = () => {}, textStyle = {}, style = {}}) {
  const Tokens = useDesignSystem();

  return (
    <TouchableHighlight
      activeOpacity={0.6}
      underlayColor="#DDDDDD"
      style={{
        paddingHorizontal: Tokens.Dimensions.Standard,
        paddingVertical: Tokens.Dimensions.Smaller,
        marginBottom: 2
      }}
      onPress={onPress}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={[{
          ...Tokens.Typography.Settings
        }, textStyle]}>
          {label}
      </Text>
        <Text style={[{
          ...Tokens.Typography.Settings
        }, textStyle]}>
          {rightLabel}
        </Text>
      </View>
    </TouchableHighlight>
  )
}