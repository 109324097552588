import emojiJson from 'emoji-datasource/emoji.json'
import { Image } from 'react-native-web'

export default function EmojiImage({ name = 'skull', size = 64 }) {
  const emoji = emojiJson.find(it => it.short_name === name);
  if (!emoji) {
    return null;
  }

  return (
    <Image
      source={{
        uri: `https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/64/${emoji.image}`
      }}
      style={{
        width: size,
        height: size,
      }}
    />
  )
}