import { View, Text } from 'react-native-web';
import { useDesignSystem } from "../hooks";

export default function PortraitOnlyScreen() {
  const Tokens = useDesignSystem();

  return (
    <View style={{
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Text style={{ ...Tokens.Typography.Title }}>
        switch to portrait mode
      </Text>
    </View>
  )
}