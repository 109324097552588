
import React from 'react';
import { View } from 'react-native-web';
import Square from './Square';
import { BOARD_LETTERS as letters, BOARD_NUMBERS as numbers } from '../../constants';
import { ThemeContext } from '../../contexts/ThemeContext';

export default function LayerSquares({
  board,
  squareSize,
  pgn
}) {
  const Theme = React.useContext(ThemeContext);

  return (
    <View style={{ position: 'absolute' }}>
      {board.map((row, numberIndex) => (
        <View key={numbers[numberIndex]} style={{ flexDirection: 'row' }}>
          {row.map((piece, letterIndex) => {
            const squareName = letters[letterIndex] + numbers[numberIndex];
            const color = (numberIndex + letterIndex) % 2
              ? Theme.Colors.ChessboardSquareBlack
              : Theme.Colors.ChessboardSquareWhite;

            return (
              <Square
                key={squareName}
                name={squareName}
                size={squareSize}
                color={color}
                pgn={pgn}
              />
            );
          })}
        </View>
      ))}
    </View>
  )
}