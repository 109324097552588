import React from 'react';
import PropTypes from 'prop-types';
import { Text as RNText } from 'react-native';
import { Colors, ThemeContext, Typography } from '../contexts/ThemeContext';

export default function Text({ style = {}, variant = "Body", color = "Label", ...rest}) {
  const Theme = React.useContext(ThemeContext)

  return (
    <RNText
      style={{
        ...Theme.Typography[variant],
        color: Theme.Colors[color],
        ...style
      }}
      {...rest}
    />
  )
}

Text.propTypes = {
  variant: PropTypes.oneOf(Object.keys(Typography)),
  color: PropTypes.oneOf(Object.keys(Colors.Light)),
};