import React from 'react';
import { ScrollView } from "react-native-web";
import Table from "../../components/Table";
import { ThemeContext } from "../../contexts/ThemeContext";
import Text from "../../primitives/Text";

export default function TypographyScreen() {
  const Theme = React.useContext(ThemeContext);

  return (
    <ScrollView style={{ paddingTop: Theme.Dimensions.Large }}>
      <Table type="inset" style={{ marginBottom: Theme.Dimensions.Large}}>
        {Object.entries(Theme.Typography).map(([variant, value]) => (
          <Table.Item
            key={variant}
            title={variant}
            renderRightComponent={() => <Text variant={variant}>Preview</Text>}
          />
        ))}
      </Table>
    </ScrollView>
  )
}