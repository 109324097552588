import React from "react";

import { ScrollView } from "react-native-web";
import Text from "../../primitives/Text";
import HStack from "../../primitives/HStack";
import VStack from "../../primitives/VStack";
import { ThemeContext } from "../../contexts/ThemeContext";
import IconButton from '../../components/IconButton';

export default function ComponentIconButtonScreen() {
  const Theme = React.useContext(ThemeContext);

  return (
    <ScrollView style={{ paddingTop: Theme.Dimensions.Large }}>
      <VStack
        style={{
          alignItems: 'flex-start',
          margin: 20
        }}
      >
        <Text style={{
          ...Theme.Typography.Heading,
          marginBottom: 20,
        }}>
          Sizes
        </Text>
        {Object.keys(IconButton.Size).map(it => (
          <HStack style={{
            marginBottom: 20,
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <IconButton key={it} type="plus" size={IconButton.Size[it]} />
            <Text style={{
              flex: 1,
              ...Theme.Typography.HeadingSmall,
              marginLeft: Theme.Dimensions.Small
            }}>
              {it}
            </Text>
          </HStack>
        ))}
        <Text style={{
          ...Theme.Typography.Heading,
          marginBottom: 20,
        }}>
          Types
        </Text>
        {Object.keys(IconButton.Type).map(it => (
          <HStack style={{
            marginBottom: 20,
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <IconButton
              key={it}
              type={IconButton.Type[it]}
            />
            <Text style={{
              flex: 1,
              ...Theme.Typography.HeadingSmall,
              marginLeft: Theme.Dimensions.Small
            }}>{it}</Text>
          </HStack>
        ))}

      </VStack>
    </ScrollView>
  )
}