import React from 'react';
import ContentSheet from '../components/ContentSheet';

export const ContentSheetContext = React.createContext();

export const ContentSheetProvider = ({ children }) => {

  const [ contentSheet, setContentSheet ] = React.useState({
    isVisible: false,
    title: null,
    message: null,
    buttons: [],
    options: { cancelable: true }
  });

  const showContentSheet = (title, message, buttons = [], options = { cancelable: true }) =>
  setContentSheet({
    isVisible: true,
    title,
    message,
    buttons,
    options
  })

  const dismissContentSheet = () => {
    setContentSheet({
      ...contentSheet,
      isVisible: false
    })
  }

  return (
    <ContentSheetContext.Provider value={{ showContentSheet, dismissContentSheet, contentSheet }}>
      {children}
      <ContentSheet
        {...contentSheet}
        {...contentSheet.options}
      >
        {contentSheet?.options?.children}
      </ContentSheet>
    </ContentSheetContext.Provider>
  );
};