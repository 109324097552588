import { View } from 'react-native';

export default function HStack({ children, style = {} }) {
  return (
    <View style={[{
      flexDirection: 'row'
    }, style]}>
      {children}
    </View>
  )
}