import React from "react";
import PropTypes from 'prop-types'
import { Pressable } from "react-native-web";
import { ThemeContext } from "../contexts/ThemeContext";

import { ReactComponent as ChevronBackIcon } from '../images/icons/chevron.backward.svg';
import { ReactComponent as ChevronForwardIcon } from '../images/icons/chevron.right.svg';
import { ReactComponent as CloseIcon } from '../images/icons/multiply.svg';
import { ReactComponent as PlusIcon } from '../images/icons/plus.svg';
import { ReactComponent as MenuIcon } from '../images/icons/ellipsis.svg';
import { ReactComponent as SettingsIcon } from '../images/icons/gearshape.fill.svg';
import { ReactComponent as CheckmarkIcon } from '../images/icons/checkmark.svg';
import { withAlpha } from "../utils";

export default function Icon({
  type = 'menu',
  tint,
  onPress,
  style = {},
  withBackground = false,
  size = 24
}) {
  const Theme = React.useContext(ThemeContext);
  const Icon = getComponentForIcon(type);

  return (
    <Pressable
      style={({ pressed }) => ({
        width: size,
        height: size,
        borderRadius: size,
        justifyContent: 'center',
        alignItems: 'center',
        background: withBackground && withAlpha(tint, 0.15),
        opacity: pressed && onPress ? 0.7 : 1,
        ...style
      })}
      onPress={(e) => onPress && onPress(e)}
    >
      <Icon style={{ color: tint || Theme.Colors.Label }} />
    </Pressable>
  )
}

Icon.Types = [
  'close',
  'plus',
  'settings',
  'menu',
  'chevron-back',
  'chevron-forward',
  'checkmark',
]

Icon.propTypes = {
  type: PropTypes.oneOf(Icon.Types)
}

function getComponentForIcon(type) {
  switch (type) {
    case 'plus':
      return PlusIcon;
    case 'close':
      return CloseIcon;
    case 'settings':
      return SettingsIcon;
    case 'chevron-forward':
      return ChevronForwardIcon;
    case 'chevron-back':
      return ChevronBackIcon;
    case 'checkmark':
      return CheckmarkIcon;
    default:
      return MenuIcon;
  }
}
