import React from "react";

import { Pressable, Text } from "react-native-web";
import { ThemeContext } from "../contexts/ThemeContext";

export default function Button({
  title = "Button",
  tint,
  disabled = false,
  type = Button.Type.Primary,
  onPress = () => {},
  style = {}
}) {
  const Theme = React.useContext(ThemeContext);

  const backgroundColor = (() => {
    switch (type) {
      case Button.Type.Primary:
        return Theme.Colors.Primary
      case Button.Type.Destructive:
        return Theme.Colors.Failure
      case Button.Type.Secondary:
        return Theme.Colors.Background
      case Button.Type.Tertiary:
        return 'transparent'
      default:
        return Theme.Colors.Primary
    }
  })();

  const color = (() => {
    switch (type) {
      case Button.Type.Primary:
        return Theme.SystemColors.White
      case Button.Type.Destructive:
        return Theme.SystemColors.White
      case Button.Type.Secondary:
        return Theme.Colors.Label
      case Button.Type.Tertiary:
        return tint || Theme.Colors.SecondaryLabel
      default:
        return Theme.Colors.Primary
    }
  })();

  const shadows = type === Button.Type.Primary || type === Button.Type.Destructive
    ? Theme.Shadows.Standard
    : Theme.Shadows.None

  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [{
        backgroundColor: backgroundColor,
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: Theme.Dimensions.Small,
        borderRadius: 8,
      },
      shadows,
      disabled && {
        ...Theme.Shadows.None,
        opacity: 0.5
      },
      pressed && Theme.Shadows.None,
      style
    ]}>
      <Text
        style={[
          {
            ...Theme.Typography.Heading,
            color: tint || color
          },
          (type === Button.Type.Secondary || type === Button.Type.Tertiary) && {
            fontWeight: '600'
          }
        ]}
      >
        {title}
      </Text>
    </Pressable>
  )
}

Button.Type = {
  Primary: 'primary',
  Destructive: 'destructive',
  Secondary: 'secondary',
  Tertiary: 'tertiary'
}