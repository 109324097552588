import React from 'react';
import { Animated } from 'react-native-web';
import { useChess, useMaxContentWidth } from '../../hooks';
import LayerSquares from './LayerSquares';
import LayerHighlight from './LayerHighlight';
import LayerPiecesInactive from './LayerPiecesInactive';

export default function ChessboardReadOnly({
  size,
  pgn = "",
  style = {}
}) {
  const maxContentWidth = useMaxContentWidth();
  const boardSize = size || maxContentWidth;
  const squareSize = boardSize / 8;
  const chess = useChess(pgn);
  const board = chess.board();
  const turn = chess.turn();
  const moves = chess.moves({ verbose: true });
  const inCheck = chess.in_check();
  const history = chess.history({ verbose: true });
  const lastMove = history && history.length && history[history.length - 1];

  return (
    <Animated.View
      style={[{
        position: 'relative',
        width: boardSize,
        height: boardSize,
      }, style]}
    >
      <LayerHighlight
        board={board}
        squareSize={squareSize}
        moves={moves}
        pgn={pgn}
        turn={turn}
        inCheck={inCheck}
        lastMove={lastMove}
      />
      <LayerSquares
        board={board}
        squareSize={squareSize}
        pgn={pgn}
        turn={turn}
      />
      <LayerPiecesInactive
        board={board}
        squareSize={squareSize}
        moves={moves}
        pgn={pgn}
        turn={turn}
      />
    </Animated.View>
  )
}