import { SafeAreaView } from "react-native-safe-area-context";
import { useMaxContentWidth, useScreenData } from "../hooks";

export default function ScreenWrapper({ style = {}, children }) {
  const maxContentWidth = useMaxContentWidth();
  const { isPhone } = useScreenData();

  return (
    <SafeAreaView
      style={[
        {
          flex: 1,
          overflow: 'hidden',
          width: maxContentWidth
        },
        !isPhone && {
          marginHorizontal: 'auto',
          justifyContent: 'center',
        },
        style
      ]}
    >
      {children}
    </SafeAreaView>
  );
}