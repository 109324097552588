import React from 'react';
import { Appearance, ScrollView, Switch, View } from 'react-native-web';
import Table from '../components/Table';
import { setSettings, useSharedState } from '../state';
import { capitalize } from '../utils';
import Icon from '../components/Icon';
import { ThemeContext } from '../contexts/ThemeContext';

export default function SettingsDisplayScreen() {
  const [sharedState, dispatch] = useSharedState();
  const { settings } = sharedState;
  const Theme = React.useContext(ThemeContext);

  return (
    <View style={{
      flex: 1,
    }}>
      <ScrollView style={{
        paddingTop: 20
      }}>

        <Table
          title="Theme"
          type="inset"
          style={{ marginBottom: 40 }}
        >
          {[
            <Table.Item
              key="match-system"
              title="Match System"
              renderRightComponent={() => (
                <Switch
                  value={settings.matchSystemColorScheme}
                  onValueChange={(e) => {
                    dispatch(setSettings({
                      ...settings,
                      matchSystemColorScheme: !settings.matchSystemColorScheme,
                      theme: !settings.matchSystemColorScheme === true
                        ? Appearance.getColorScheme()
                        : settings.theme
                    }));
                  }}
                />
              )}
            />,
            ...['light', 'dark'].map(it => (
              <Table.Item
                key={it}
                title={capitalize(it)}
                tint={settings.matchSystemColorScheme && Theme.Colors.TertiaryLabel}
                renderRightComponent={() => {
                  if (settings.theme === it) {
                    return (
                      <Icon
                        type="checkmark"
                        tint={Theme.Colors.Primary}
                        withBackground={true}
                        style={{
                          marginVertical: -Theme.Dimensions.Smaller
                        }}
                      />
                    )
                  }
                }}
                onPress={settings.matchSystemColorScheme ? null : () => {
                  dispatch(setSettings({
                    ...settings,
                    theme: it
                  }))
                }}
              />
            ))
          ]}
        </Table>
        <Table
          title="Bubble Color"
          type="inset"
          style={{ marginBottom: 40 }}
        >
          {['green', 'blue'].map(it => (
            <Table.Item
              key={it}
              title={`${capitalize(it)} Bubble`}
              renderRightComponent={() => {
                if (settings.bubbleColor === it) {
                  return (
                    <Icon
                      type="checkmark"
                      tint={Theme.Colors.Primary}
                      withBackground={true}
                      style={{
                        marginVertical: -Theme.Dimensions.Smaller
                      }}
                    />
                  )
                }
              }}
              onPress={() => {
                dispatch(setSettings({
                  ...settings,
                  bubbleColor: it
                }))
              }}
            />
          ))}
        </Table>
      </ScrollView>
    </View>
  )
}

