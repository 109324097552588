import React from 'react';

import { useSharedState } from '../state';
import { withAlpha } from '../utils';
export const ThemeContext = React.createContext();

const Shadows = {
  None: {
    // web
    boxShadow: '0 0 0 rgba(0, 0, 0, 0)',

    // iOS
    shadowColor: null,
    shadowOffset: null,
    shadowOpacity: null,
    shadowRadius: null, // blur
  },
  Avatar: {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',

  },
  Standard: {
    // web
    boxShadow: '0 10px 60px rgba(0, 0, 0, 0.2)',

    // iOS
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 10
    },
    shadowOpacity: 0.2,
    shadowRadius: 60, // blur
  }
}

const fontFamily = 'ui-rounded, "SF Pro Rounded", system-ui, sans-serif';
export const Typography = {
  Title: {
    fontFamily,
    fontSize: 24,
    fontWeight: 'bold'
  },
  Subtitle: {
    fontFamily,
    fontSize: 20,
    fontWeight: '500'
  },
  Heading: {
    fontFamily,
    fontSize: 20,
    fontWeight: 'bold'
  },
  HeadingSmall: {
    fontFamily,
    fontSize: 18,
    fontWeight: 'bold'
  },
  Body: {
    fontFamily,
    fontSize: 17,
  },
  BodySmall: {
    fontFamily,
    fontSize: 15,
    fontWeight: '500'
  },
  Caption: {
    fontFamily,
    fontSize: 13
  },
  Emoji: {
    fontFamily: '"SFRoundedFallback", system-ui',
    fontSize: 32
  },
  Icon: {
    fontFamily: 'ui-rounded, "SF Pro Rounded", "SFRoundedFallback", system-ui, sans-serif'
  }
};

export const Dimensions = {
  Smallest: 4,
  Smaller: 8,
  Small: 16,
  Standard: 24,
  Large: 32,
  Larger: 48,
  Largest: 64
};

// https://noahgilmore.com/blog/dark-mode-uicolor-compatibility/
export const SystemColors = {
  Light: {
    Red: 'rgba(255, 59, 48, 1)',
    Orange: 'rgba(255, 149, 0, 1)',
    Yellow: 'rgba(255, 204, 0, 1)',
    Green: 'rgba(52, 199, 89, 1)',
    Mint: 'rgba(0, 199, 190, 1)',
    Teal: 'rgba(48, 176, 199, 1)',
    Cyan: 'rgba(50, 173, 230, 1)',
    Blue: 'rgba(0, 122, 255, 1)',
    Indigo: 'rgba(88, 86, 214, 1)',
    Purple: 'rgba(175, 82, 222, 1)',
    Pink: 'rgba(255, 45, 85, 1)',
    Brown: 'rgba(162, 132, 94, 1)',
    White: 'rgba(255, 255, 255, 1)',
    Gray: 'rgba(142, 142, 147, 1)',
    Gray2: 'rgba(174, 174, 178, 1)',
    Gray3: 'rgba(199, 199, 204, 1)',
    Gray4: 'rgba(209, 209, 214, 1)',
    Gray5: 'rgba(229, 229, 234, 1)',
    Gray6: 'rgba(242, 242, 247, 1)',
    Black: 'rgba(0, 0, 0, 1)'
  },
  Dark: {
    Red: 'rgba(255, 69, 58, 1)',
    Orange: 'rgba(255, 159, 10, 1)',
    Yellow: 'rgba(255, 214, 10, 1)',
    Green: 'rgba(48, 209, 88, 1)',
    Mint: 'rgba(102, 212, 207, 1)',
    Teal: 'rgba(64, 200, 224, 1)',
    Cyan: 'rgba(100, 210, 255, 1)',
    Blue: 'rgba(10, 132, 255, 1)',
    Indigo: 'rgba(94, 92, 230, 1)',
    Purple: 'rgba(191, 90, 242, 1)',
    Pink: 'rgba(255, 55, 95, 1)',
    Brown: 'rgba(172, 142, 104, 1)',
    White: 'rgba(255, 255, 255, 1)',
    Gray: 'rgba(142, 142, 147, 1)',
    Gray2: 'rgba(99, 99, 102, 1)',
    Gray3: 'rgba(72, 72, 74, 1)',
    Gray4: 'rgba(58, 58, 60, 1)',
    Gray5: 'rgba(44, 44, 46, 1)',
    Gray6: 'rgba(28, 28, 30, 1)',
    Black: 'rgba(0, 0, 0, 1)'
  }
}

export const Colors = {
  Light: {
    Overlay: withAlpha(SystemColors.Dark.Black, 0.25),

    Background: SystemColors.Light.Gray6,
    Foreground: SystemColors.Light.White,
    Primary: SystemColors.Light.Blue,
    Success: SystemColors.Light.Green,
    Danger: SystemColors.Light.Red,
    Failure: SystemColors.Light.Red,

    Label: SystemColors.Light.Black,
    SecondaryLabel: withAlpha('rgb(60, 60, 67)', 0.6),
    TertiaryLabel: withAlpha('rgb(60, 60, 67)', 0.3),
    QuaternaryLabel: withAlpha('rgb(60, 60, 67)', 0.2),
    PlaceholderText: withAlpha('rgb(60, 60, 67)', 0.2),
    Separator: 'rgba(60, 60, 67, 0.3)',
    OpaqueSeparator: 'rgba(198, 198, 200, 1)',
    Link: 'rgba(0, 122, 255, 1)',

    // Components
    ChessboardSquareWhite: 'transparent',
    ChessboardSquareBlack: withAlpha(SystemColors.Light.Black, 0.15),
    ChessboardSquareCheck: withAlpha(SystemColors.Light.Red, 0.85),
    ChessboardSquarePossibleMove: withAlpha(SystemColors.Light.Blue, 0.3),
    ChessboardSquareLastMove: withAlpha(SystemColors.Light.Yellow, 0.3),

    GameBodyLabel: SystemColors.Light.Black
  },
  Dark: {
    Overlay: withAlpha(SystemColors.Dark.Black, 0.75),
    Background: SystemColors.Dark.Gray6,
    Foreground: SystemColors.Dark.Gray5,
    Primary: SystemColors.Dark.Blue,
    Success: SystemColors.Dark.Green,
    Danger: SystemColors.Dark.Red,
    Failure: SystemColors.Dark.Red,

    Label: SystemColors.Dark.White,
    SecondaryLabel: withAlpha('rgb(235, 235, 245)', 0.6),
    TertiaryLabel: withAlpha('rgb(235, 235, 245)', 0.3),
    QuaternaryLabel: withAlpha('rgb(235, 235, 245)', 0.2),
    PlaceholderText: withAlpha('rgb(235, 235, 245)', 0.3),
    Separator: 'rgba(84, 84, 88, 0.3)',
    OpaqueSeparator: 'rgba(56, 56, 58, 1)',
    Link: 'rgba(9, 132, 255, 1)',

    // Components
    ChessboardSquareWhite: withAlpha(SystemColors.Dark.White, 0.15),
    ChessboardSquareBlack: 'transparent',
    ChessboardSquareCheck: withAlpha(SystemColors.Dark.Red, 0.85),
    ChessboardSquarePossibleMove: withAlpha(SystemColors.Dark.Blue, 0.3),
    ChessboardSquareLastMove: withAlpha(SystemColors.Dark.Yellow, 0.3),

    GameBodyLabel: withAlpha('rgb(235, 235, 245)', 0.6)
  }
}

export const ThemeProvider = ({ children }) => {
  const [sharedState] = useSharedState();
  const { settings } = sharedState;
  const { theme } = settings;

  return (
    <ThemeContext.Provider value={{
      Typography,
      Dimensions,
      Colors: theme === 'light' ? Colors.Light : Colors.Dark,
      SystemColors: theme === 'light' ? SystemColors.Light : SystemColors.Dark,
      Shadows,
      ...(theme === 'light' ? Colors.Light : Colors.Dark) // temp during refactor
    }}>
      {children}
    </ThemeContext.Provider>
  );
};