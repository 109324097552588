import React from 'react';
import { View, Text, ScrollView } from 'react-native-web';
import IconButton from '../components/IconButton';
import { useDesignSystem } from '../hooks';
import ScreenWrapper from '../components/ScreenWrapper';

export default function DesignSystemScreen() {
  const Tokens = useDesignSystem();

  return (
    <ScreenWrapper style={{
      flex: 1,
    }}>
      <ScrollView style={{
        padding: Tokens.Dimensions.Standard
      }}>
          <ComponentSection label="button" style={{
            alignItems: 'flex-start'
          }}>
            <IconButton
              label="primary"
              type="primary"
              style={{
              backgroundColor: Tokens.Functional.Colors.Primary,
                marginBottom: Tokens.Dimensions.Small
              }}
            />
            <IconButton
              label="secondary"
              type="secondary"
              style={{
                backgroundColor: Tokens.Colors.Transparent,
                marginBottom: Tokens.Dimensions.Small
              }}
              textStyle={{
                color: Tokens.Colors.Black,
              }}
            />
            <IconButton
              label="destructive"
              type="destructive"
              style={{
                backgroundColor: Tokens.Functional.Colors.Destructive,
                marginBottom: Tokens.Dimensions.Small
              }}
            />
            <IconButton
              label="debug"
              type="debug"
              style={{
                backgroundColor: Tokens.Functional.Colors.Debug,
                marginBottom: Tokens.Dimensions.Small
              }}
            />
          </ComponentSection>
          <ComponentSection label="typography">
            {Object.entries(Tokens.Typography).map(([tokenName, tokenValue]) => (
              <TypographyRow key={tokenName} name={tokenName} token={tokenValue} />
            ))}

          </ComponentSection>
          <ComponentSection label="colors">
            {Object.entries(Tokens.Colors).map(([tokenName, tokenValue]) => (
              <ColorRow key={tokenName} name={tokenName} token={tokenValue} />
            ))}
          </ComponentSection>
          <ComponentSection label="dimensions">
            {Object.entries(Tokens.Dimensions).map(([tokenName, tokenValue]) => (
              <DimensionRow key={tokenName} name={tokenName} token={tokenValue} />
            ))}
          </ComponentSection>
      </ScrollView>
    </ScreenWrapper>
  );
}

function TypographyRow({ name, token }) {
  const Tokens = useDesignSystem();

  return (
    <View style={{
      flex: 1,
      justifyContent: 'stretch',
      marginBottom: Tokens.Dimensions.Standard
    }}>
      <Text style={{
        marginBottom: 10,
        fontSize: 18,
        fontWeight: '800',
        letterSpacing: -0.75
      }}>
        {name}
      </Text>
      <Text style={[{
        // borderWidth: 3,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        padding: Tokens.Dimensions.Smaller
      }, token]}>
        Text example
      </Text>
    </View>
  )
}

function ColorRow({ name, token }) {
  const Tokens = useDesignSystem();

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: Tokens.Dimensions.Small
      }}
    >
      <View
        style={{
          width: Tokens.Dimensions.Large,
          height: Tokens.Dimensions.Large,
          backgroundColor: token,
          borderWidth: 3
        }}
      />
      <Text style={{
        marginLeft: 20,
        fontSize: 18,
        fontWeight: '800',
        letterSpacing: -0.75
      }}>
        {name}
      </Text>
    </View>
  )
}

function DimensionRow({ name, token }) {
  const Tokens = useDesignSystem();
  const largestDimensionName = Object.keys(Tokens.Dimensions)[Object.keys(Tokens.Dimensions).length - 1];

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: Tokens.Dimensions.Small
      }}
    >
      <View style={{
        alignItems: 'flex-end',
        width: Tokens.Dimensions[largestDimensionName]
      }}>
        <View
          style={{
            width: token,
            height: token,
            borderWidth: 3,
            borderStyle: 'dashed',
          }}
        />
      </View>
      <Text style={{
        marginLeft: 20,
        fontSize: 18,
        fontWeight: '800',
        letterSpacing: -0.75
      }}>
        {name}
      </Text>
    </View>
  )
}

function ComponentSection({ label, children, style = {} }) {
  const Tokens = useDesignSystem();

  return (
    <View style={[{
      marginBottom: Tokens.Dimensions.Large
    }, style]}>
      <Text style={{
        ...Tokens.Typography.Heading,
        marginBottom: Tokens.Dimensions.Small
      }}>
        {label}
      </Text>
      {children}
    </View>
  )
}