import React from 'react';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, Easing } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Text from "../primitives/Text";
import Button from './Button';
import VStack from '../primitives/VStack';
import IconButton from './IconButton';
import Overlay from './Overlay';
import { ThemeContext } from "../contexts/ThemeContext";
import { ContentSheetContext } from '../contexts/ContentSheetContext';
import { useMeasure } from 'react-use';

export default function ContentSheet({
  title,
  message,
  buttons = [],
  cancellable = true,
  children = null,
  onDismiss = () => {},
  isVisible = false,
  style,
}) {
  const [pointerEvents, setPointerEvents] = React.useState('none');
  const safeAreaInsets = useSafeAreaInsets()
  const Theme = React.useContext(ThemeContext);
  const { dismissContentSheet } = React.useContext(ContentSheetContext);
  const cardRadius = 39;

  const [ref, { height }] = useMeasure();
  const animationCallbackRef = React.useRef(() => {});
  const contentHeight = (height || 0) + 20; // Adding 20px as a workaround to avoid dealing with shadow
  const overlayOpacity = useSharedValue(0);
  const translateY = useSharedValue(1000); // setting 1000 as initial position
  const config = {
    duration: 400,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
  };

  const animatedContentStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withTiming(
            translateY.value,
            config,
            () => {
              if (!isVisible) {
                animationCallbackRef.current();
                animationCallbackRef.current = () => {}
              }
            }
          )
        }
      ]
    };
  });
  const animatedOverlayStyle = useAnimatedStyle(() => {
    return {
      opacity: withTiming(
        overlayOpacity.value,
        config
      ),
    }
  });

  React.useEffect(() => {
    translateY.value = contentHeight;

    function animateIn() {
      overlayOpacity.value = 1;
      translateY.value = 0;
    }

    function animateOut() {
      const offset = (Theme.Dimensions.Standard * 2) + safeAreaInsets.bottom;
      translateY.value = (contentHeight + offset);

      overlayOpacity.value = 0;
    }

    setPointerEvents(isVisible ? 'auto' : 'none');
    if (isVisible) {
      animateIn();
    } else {
      animateOut();
    }
  }, [
    isVisible,
    overlayOpacity,
    translateY,
    contentHeight,
    Theme.Dimensions.Small,
    Theme.Dimensions.Standard,
    safeAreaInsets.bottom,
  ]);

  function handleDismiss() {
    if (!cancellable) {
      return;
    }

    if (onDismiss) {
      animationCallbackRef.current = onDismiss
    }

    dismissContentSheet();
  }

  return (
    <VStack style={{
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents
    }}>
      <Overlay
        style={animatedOverlayStyle}
        onPress={handleDismiss}
      />
        <Animated.View
          ref={ref}
          style={[{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            background: Theme.Colors.Foreground,
            padding: Theme.Dimensions.Standard,
            paddingBottom: Theme.Dimensions.Standard + safeAreaInsets.bottom,
            borderTopRightRadius: cardRadius,
            borderTopLeftRadius: cardRadius,

            ...Theme.Shadows.Standard,
            zIndex: 12
          },
          style,
          animatedContentStyle,
        ]}
        >
          {cancellable && (
            <IconButton
              type={IconButton.Type.XMark}
              size={IconButton.Size.Small}
              withShadow={false}
              backgroundColor={Theme.Colors.Background}
              tint={Theme.Colors.SecondaryLabel}
              onPress={handleDismiss}
              style={{
                marginLeft: 'auto'
              }}
            />
          )}
          <VStack style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {title && (
              <Text style={{
                ...Theme.Typography.Title,
                textAlign: 'center'
              }}>
                {title}
              </Text>
            )}
            {message && (
              <Text style={{
                ...Theme.Typography.Subtitle,
                marginTop: Theme.Dimensions.Smaller,
                color: Theme.Colors.SecondaryLabel,
                textAlign: 'center'
              }}>
                {message}
              </Text>
            )}
          </VStack>
          <VStack>
            {children}
          </VStack>
          {buttons.length > 0 && (
            <VStack style={{
              marginTop: Theme.Dimensions.Large - Theme.Dimensions.Small,
            }}>
              {buttons.map(it => (
                <Button
                  key={it.title}
                  title={it.title}
                  type={it.type}
                  tint={it.tint}
                  disabled={it.disabled}
                  onPress={() => {
                    if (it.onPress) {
                      animationCallbackRef.current = it.onPress
                    }

                    dismissContentSheet();
                  }}
                  style={[
                    {
                      marginTop: Theme.Dimensions.Small,
                      marginHorizontal: Theme.Dimensions.Small
                    },
                    it.type === Button.Type.Tertiary && {
                      marginTop: Theme.Dimensions.Smallest,
                      marginBottom: -Theme.Dimensions.Small,
                    }
                  ]}
                />
              ))}
            </VStack>
          )}
        </Animated.View>
    </VStack>
  )
}