import React from "react";
import { ChessboardReadOnly } from "../../components/Chessboard/Chessboard";
import VStack from "../../primitives/VStack";

export default function ComponentGameScreen() {
  return (
    <VStack style={{
      flex: 1,
      justifyContent: 'center'
    }}>
      <ChessboardReadOnly
        pgn="1. e4 b5 2. d4 h5 3. Bxb5 f5 4. exf5 g5 5. Qe2 a5 6. h3 Rh7 7. g4 hxg4 8. Qxg4 Rxh3 9. Qxh3 Na6 10. Qh5#"
      />
    </VStack>
  )
}