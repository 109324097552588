import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View } from 'react-native-web';
import ChessboardNext from '../components/Chessboard/ChessboardNext';
import IconButton from '../components/IconButton';
import { ThemeContext } from '../contexts/ThemeContext';
import { useGameDialogs } from '../hooks';
import HStack from '../primitives/HStack';
import Avatars from '../components/Avatars';
import { useSharedState } from '../state';
import { MODE_SINGLE_PLAYER } from '../constants';


const BOT_PLAYER = {
  id: "",
  emoji: "robot_face",
  color: "#E2E2E2",
  stats: {
    wins: 0,
    losses: 0,
    draws: 0
  }
};

export default function PlaygroundScreen() {
  const [sharedState] = useSharedState();
  const { profile, settings } = sharedState;
  const { bubbleColor } = settings;
  const game = {
    id: "",
    pgn: "",
    mode: MODE_SINGLE_PLAYER,
    winner: null,
    gameOverBy: null,
    dateCompleted: null,
    dateCreated: null,
    dateModified: null,
    players: {
      white: {
        id: "",
        emoji: profile.emoji || 'skull',
        color: profile.color || 'blue',
        bubbleColor,
        stats: {
          wins: 0,
          losses: 0,
          draws: 0
        }
      },
      black: BOT_PLAYER
    }
  };
  const { showGameActionsDialog } =  useGameDialogs();
  const safeAreaInsets = useSafeAreaInsets()

  const Theme = React.useContext(ThemeContext);
  const chessboardRef = React.useRef();

  // const totalMoves = chessboardRef?.current?.getTotalMoves()
  // const currentIndex = chessboardRef?.current?.getCurrentMoveIndex();
  return (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <Avatars
          pgn={"pgn"}
          pendingMove={null}
          bubbleColor={bubbleColor}
          playerOne={game.players.white}
          playerTwo={game.players.black}
        />
      </View>
      <ChessboardNext
        ref={chessboardRef}
        pgn={game.pgn}
      />

      <HStack style={{
        justifyContent: 'space-between',
        paddingTop: Theme.Dimensions.Small,
        paddingBottom: safeAreaInsets.bottom ? safeAreaInsets.bottom + Theme.Dimensions.Small : Theme.Dimensions.Small,
        paddingHorizontal: Theme.Dimensions.Standard
      }}>
        <IconButton
          type={IconButton.Type.Line3Horizontal}
          onPress={() => {
            showGameActionsDialog()
          }}
        />
        <IconButton
          type={IconButton.Type.GoBackward}
          onPress={() => {
            // console.log({
            //   isTT: chessboardRef?.current?.isTimeTraveling()
            // });
            chessboardRef.current.undo();
          }}
        />
        <IconButton
          type={IconButton.Type.ChevronLeft}
          onPress={() => {
            chessboardRef.current.stepBack();
          }}
        />
        <IconButton
          type={IconButton.Type.ChevronRight}
          onPress={() => {
            chessboardRef.current.stepForward();
          }}
        />
      </HStack>
    </View>
  )
}

