import React from 'react';
import { ScrollView } from "react-native-web";
import Table from "../../components/Table";
import { ContextMenuContext } from '../../contexts/ContextMenuContext';
import { ThemeContext } from "../../contexts/ThemeContext";

export default function ComponentActionSheetScreen() {
  const Theme = React.useContext(ThemeContext);
  const { showContextMenu } = React.useContext(ContextMenuContext);

  return (
    <ScrollView style={{
      paddingTop: Theme.Dimensions.Large
    }}>
      <Table
        type="inset"
        title="Variants"
      >
        <Table.Item
          title="Title"
          tint={Theme.Colors.Link}
          onPress={() => showContextMenu(
            "Title"
          )}
        />
        <Table.Item
          title="Title & Message"
          tint={Theme.Colors.Link}
          onPress={() => showContextMenu(
            "Title",
            "Message"
          )}
        />
        <Table.Item
          title="Message"
          tint={Theme.Colors.Link}
          onPress={() => showContextMenu(
            null,
            "Message"
          )}
        />
        <Table.Item
          title="Actions"
          tint={Theme.Colors.Link}
          onPress={() => showContextMenu(
            null,
            null,
            [
              {
                text: "Standard Action 1",
              },
              {
                text: "Standard Action 2",
              }
            ]
          )}
        />
        <Table.Item
          title="Disabled Action"
          tint={Theme.Colors.Link}
          onPress={() => showContextMenu(
            null,
            null,
            [
              {
                text: "Destructive Action",
                type: 'destructive',
                disabled: true
              },
            ]
          )}
        />
        <Table.Item
          title="Destructive Action"
          tint={Theme.Colors.Link}
          onPress={() => showContextMenu(
            null,
            null,
            [
              {
                text: "Destructive Action",
                type: 'destructive'
              },
            ]
          )}
        />
        <Table.Item
          title="All Options"
          tint={Theme.Colors.Link}
          onPress={() => showContextMenu(
            "Title",
            "Message",
            [
              {
                text: "Standard Action",
              },
              {
                text: "Destructive Action",
                type: 'destructive'
              },
            ]
          )}
        />
      </Table>
    </ScrollView>
  )
}