import { Pressable } from "react-native-web";
import VStack from "../primitives/VStack";
import HStack from '../primitives/HStack';
import React from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import Text from "../primitives/Text";
export default function Table({
  type = "plain",
  title,
  description,
  children = [],
  style = {}
}) {
  const Theme = React.useContext(ThemeContext);

  return (
    <>
      {title && (
        <Text
          variant="Caption"
          color="SecondaryLabel"
          style={{
            paddingHorizontal: type === 'inset'
              ? Theme.Dimensions.Standard + Theme.Dimensions.Small
              : Theme.Dimensions.Small,
            paddingBottom: Theme.Dimensions.Smaller
          }}
        >
          {title.toUpperCase()}
        </Text>
      )}
      <VStack style={[
        type === "inset" && {
          marginHorizontal: Theme.Dimensions.Standard,
          borderRadius: 10,
          overflow: 'hidden'
        },
        style
      ]}>
        {(children.length ? children : [children]).map((child, index) => {
          const nextChild = React.cloneElement(child, {
            style: {
              ...child.style,
              borderBottomWidth: index === (children.length || 1) - 1
                ? 0
                : 0.5,
            }
          })


          return nextChild
        })}
      </VStack>
      {description && (
        <Text
          variant="Caption"
          color="SecondaryLabel"
          style={{
            paddingHorizontal: type === 'inset'
            ? Theme.Dimensions.Standard + Theme.Dimensions.Small
            : Theme.Dimensions.Small,
            paddingTop: Theme.Dimensions.Smaller,
            paddingBottom: Theme.Dimensions.Smaller
          }}
        >
          {description}
        </Text>
      )}
    </>
  )
}

Table.Item = function Item({
  title,
  subtitle,
  renderLeftComponent,
  renderRightComponent,
  onPress,
  tint,
  style = {}
}) {
  const Theme = React.useContext(ThemeContext);

  return (
    <Pressable
      onPress={onPress ? onPress : () => {}}
      style={({ pressed }) => ({
        backgroundColor: Theme.Foreground,
        opacity: (pressed && onPress) ? 0.7 : 1
      })}
    >
      <HStack
        style={{
          flex: 1,
          alignItems: 'center',
        }}
      >
        {renderLeftComponent && (
          <VStack style={{ marginLeft: Theme.Dimensions.Small, justifyContent: 'center', }}>
            {renderLeftComponent()}
          </VStack>
        )}
        <HStack style={[{
          flex: 1,
          paddingVertical: Theme.Dimensions.Small,
          paddingRight: Theme.Dimensions.Small,
          marginLeft: Theme.Dimensions.Small,
          // backgroundColor: 'yellow',
          borderColor: Theme.Colors.Separator
        }, style]}>
          <VStack style={[
            {
              flex: 1,
              justifyContent: 'center',
              // backgroundColor: 'pink'
            },
            renderRightComponent && { marginRight: Theme.Dimensions.Small }
          ]}>
            <Text color="Label" style={{ color: tint || Theme.Label }}>
              {title}
            </Text>
            {subtitle && (
              <Text
                color="SecondaryLabel"
                variant="Caption"
                style={{ paddingTop: 2 }}
              >
                {subtitle}
              </Text>
            )}
          </VStack>
          {renderRightComponent && (
            <VStack style={{ justifyContent: 'center', }}>
              {renderRightComponent()}
            </VStack>
          )}
        </HStack>
      </HStack>
    </Pressable>
  )
}