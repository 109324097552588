import React from "react";
import { ScrollView } from "react-native-web";
import { ThemeContext } from "../../contexts/ThemeContext";
import Table from "../../components/Table";
import Avatar from "../../components/Avatar";
export default function ComponentAvatarScreen() {
  const Theme = React.useContext(ThemeContext);

  return (
    <ScrollView style={{
      paddingTop: Theme.Dimensions.Large
    }}>
      <Table
        type="inset"
        title="Types"
        style={{
          marginBottom: Theme.Dimensions.Standard
        }}
      >
        <Table.Item
          title="bot"
          renderRightComponent={() => (
            <Avatar type="bot" />
          )}
        />
        <Table.Item
          title="user"
          renderRightComponent={() => (
            <Avatar type="user" />
          )}
        />
      </Table>
      <Table
        type="inset"
        title="Props"
        style={{
          marginBottom: Theme.Dimensions.Standard
        }}
      >
      <Table.Item
          title="size"
          renderRightComponent={() => (
            <Avatar type="user" size={64} />
          )}
        />
      </Table>
    </ScrollView>
  )
}