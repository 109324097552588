import React from 'react';
import ContextMenu from '../components/ContextMenu';

export const ContextMenuContext = React.createContext();

export const ContextMenuProvider = ({ children, navigation }) => {
  const [ contextMenu, setContextMenu ] = React.useState({
    isVisible: false,
    title: null,
    message: null,
    buttons: [],
    options: { cancelable: true }
  });

  const showContextMenu = (title, message, buttons = [], options = { cancelable: true }) =>
  setContextMenu({
    isVisible: true,
    title,
    message,
    buttons,
    options
  })

  const dismissContextMenu = () => {
    setContextMenu({
      ...contextMenu,
      isVisible: false
    })
  }

  return (
    <ContextMenuContext.Provider value={{ showContextMenu, dismissContextMenu, contextMenu }}>
      {children}
      <ContextMenu />
    </ContextMenuContext.Provider>
  );
};