import React from 'react';
import {
  // ScrollView,
  FlatList,
  InteractionManager,
  Pressable,
  View
} from 'react-native-web';
import { ThemeContext } from '../contexts/ThemeContext';
import VStack from '../primitives/VStack';
import EmojiPicker from '../components/EmojiPicker';
import EmojiAvatar from '../components/EmojiAvatar';
import { updateProfile, useSharedState } from '../state';
import { useEffectOnce, useMeasure } from 'react-use';

const COLORS = [
  '#E2E2E2',
  '#D1EECE',
  '#F8EAB3',
  '#F3DCB0',
  '#EBBFBB',
  '#EFCBD4',
  '#D7D1F5',
  '#D4E9F9'
];

const AVATAR_SIZE = 100;
export default function EditAvatarSheet() {
  const [sharedState, dispatch] = useSharedState()
  const { profile: { emoji, color } } = sharedState;
  // const [nickname, setNickname] = React.useState('naoufal');
  const Theme = React.useContext(ThemeContext);
  const flatListRef = React.useRef();
  const initialIndex = React.useRef(COLORS.reduce((acc, item, index) => {
    if (item === color) {
      return index
    }

    return acc;
  }, 0))

  const [isReady, setIsReady] = React.useState(false);

  useEffectOnce(() => {
    console.log('initialindex', initialIndex.current)

    // Needed otherwise offset scroll doesn't always work
    // Reference: https://stackoverflow.com/questions/60374750/flatlist-is-not-scrolling-to-desired-offset-when-scrolltooffset-is-used
    InteractionManager.runAfterInteractions(() => {
      flatListRef.current.scrollToOffset({
        animated: false,
        offset: ((AVATAR_SIZE + Theme.Dimensions.Standard) * initialIndex.current)
      });

      setIsReady(true)
    });
  })

  const [ref, { width }] = useMeasure();
  // const offset = (width / 2 - AVATAR_SIZE / 2);
  return (
    <View ref={ref} style={{
      marginHorizontal: -Theme.Dimensions.Standard,
    }}>
      <VStack style={{
        marginTop: Theme.Dimensions.Standard
      }}>
        <FlatList
          ref={flatListRef}
          getItemLayout={(_, index) => ({
            index,
            length: AVATAR_SIZE,
            offset: AVATAR_SIZE * index
          })}
          showsHorizontalScrollIndicator={false}
          onScroll={(e) => {
            if (!isReady) {
              return;
            }

            const offset = e.nativeEvent.contentOffset.x
            const index = parseInt(offset / (AVATAR_SIZE + Theme.Dimensions.Standard));

            if (color === COLORS[index]) {
              return;
            }

            dispatch(updateProfile({
              color: COLORS[index]
            }))
          }}
          horizontal
          pagingEnabled
          keyExtractor={item => item}
          data={COLORS}
          renderItem={({ item, index }) => (
            <Pressable
              key={item}
              style={{
                paddingLeft: Theme.Dimensions.Standard
              }}
              onPress={() => {
                flatListRef.current.scrollToOffset({
                  offset: ((AVATAR_SIZE + Theme.Dimensions.Standard) * index)
                })
              }}
            >
              <EmojiAvatar color={item} emoji={emoji} />
            </Pressable>
          )}
          ListFooterComponent={() => (
            <View style={{
              width: width - AVATAR_SIZE - Theme.Dimensions.Standard,
            }} />
          )}
        />
        <View style={{
          width: 10,
          height: 10,
          borderRadius: 10,
          background: 'black',
          marginTop: Theme.Dimensions.Small,
          // marginTop: -(AVATAR_SIZE / 2),
          marginLeft: Theme.Dimensions.Standard + AVATAR_SIZE / 2 - (10 / 2)
        }} />
        <EmojiPicker
          onSelect={(emoji) => {
            dispatch(updateProfile({ emoji }))
          }}
          style={{
            marginTop: Theme.Dimensions.Standard,
            maxHeight: 300
          }}
        />
      </VStack>
    </View>
  )
}