import { useRoute } from '@react-navigation/native';
import React from 'react';
import { Pressable, View } from 'react-native-web';
import { BOARD_LETTERS as letters, BOARD_NUMBERS as numbers, MODE_TWO_PLAYER } from '../../constants';
import { useChess, useGameDialogs } from '../../hooks';
import { updateGame, useSharedState } from '../../state';
import { findGameById } from '../../utils';
import Piece from './Piece';

export default function LayerPieces({
  gesturesEnabled = false,
  pieceStyles = {},
  board,
  squareSize,
  moves,
  pgn,
  turn,
  selectedPiece = () => {},
  setSelectedPiece = () => {},
  setReadyForInteraction = () => {},
  onPickupPiece = () => {},
  onDropPiece = () => {},
  isActiveLayer = false
}) {
  const chess = useChess(pgn);
  const [sharedState, dispatch] = useSharedState();
  const { games } = sharedState;
  const route = useRoute();
  const currentGame = findGameById(games, route?.params?.id);
  const { showPromotionDialog } = useGameDialogs();

  return (
    <View style={{ position: 'absolute' }}>
      {board.map((row, numberIndex) => (
        <View key={numbers[numberIndex]} style={{ flexDirection: 'row', position: 'absolute' }}>
          {row.map((piece, letterIndex) => {
            const squareName = letters[letterIndex] + numbers[numberIndex];

            if (!piece && !selectedPiece) {
              return null;
            }

            // Renders an invisible square with a click handler
            // when a piece is selected and the square is a possible move.
            // This is for the scenario where a user is clicking vs dragging
            // pieces.
            if (!piece && selectedPiece) {
              const possibleMoves = chess.moves({ square: selectedPiece, verbose: true });
              const move = possibleMoves.find(it => it.to === squareName);

              if (move) {
                return (
                  <Pressable
                    key={squareName}
                    style={{
                      position: 'absolute',
                      left: letterIndex * squareSize,
                      top: numberIndex * squareSize,
                      width: squareSize,
                      height: squareSize,
                    }}
                    onPress={() => {

                      // TODO: merge with drag and drop handler in Piece.js
                      function executeMove(move) {
                        chess.move(move)

                        dispatch(updateGame({
                          ...currentGame,
                          pgn: chess.pgn()
                        }))

                        setSelectedPiece(null);


                        // In two player mode, we rotate the board -- so the board
                        // isn't ready for interaction.

                        // The callback of that animation will
                        // invoke setReadyForInteraction
                        if (currentGame?.mode === MODE_TWO_PLAYER) {
                          return;
                        }

                        setReadyForInteraction(true);
                      }

                      const isPromotion = moves.piece === chess.PAWN && (move.to.includes('8'));
                      if (isPromotion) {
                        showPromotionDialog(move, executeMove)

                        return;
                      }

                      executeMove(move);
                    }}
                  />
                );
              }

              // render a touch point to click out of selectedPiece
              // player can cancel a selectedPiece by pressing on any square
              // that isn't a piece or a possible move
              if (!move && isActiveLayer === true) {
                return (
                  <Pressable
                    onPress={() => {
                      setSelectedPiece(null)
                      onDropPiece({ success: false });
                      setReadyForInteraction(true);
                    }}
                    key={squareName}
                    style={{
                      position: 'absolute',
                      left: letterIndex * squareSize,
                      top: numberIndex * squareSize,
                      width: squareSize,
                      height: squareSize,
                      // background: 'rgba(255, 0, 0, 0.5)',
                    }}
                  />
                );
              }

              return null;
            }

            return (
              <Piece
                key={squareName}
                gesturesEnabled={gesturesEnabled}
                type={piece.type}
                color={piece.color}
                squareName={squareName}
                size={squareSize}
                moves={moves.filter(it => it.from === squareName)}
                turn={turn}
                pgn={pgn}
                setSelectedPiece={setSelectedPiece}
                setReadyForInteraction={setReadyForInteraction}
                onPickup={onPickupPiece}
                onDrop={onDropPiece}
                style={{
                  position: 'absolute',
                  left: letterIndex * squareSize,
                  top: numberIndex * squareSize,
                  width: squareSize,
                  height: squareSize,
                  ...pieceStyles
                }}
              />
            );
          })}
        </View>
      ))}
    </View>
  )
}