import React from "react";
import { ScrollView, View } from "react-native-web";
import Table from "../../components/Table";
import { ThemeContext } from "../../contexts/ThemeContext";
import Text from "../../primitives/Text";

export default function DimensionsScreen() {
  const Theme = React.useContext(ThemeContext);

  return (
    <ScrollView style={{ paddingTop: Theme.Dimensions.Large }}>
      <View style={{ marginBottom: Theme.Dimensions.Large }}>
        <Table type="inset">
          {Object.entries(Theme.Dimensions).map(([dimension, value]) => (
            <Table.Item
              key={dimension+value}
              title={dimension}
              renderRightComponent={() => <Text>{value}</Text>}
            />
          ))}
        </Table>
      </View>
    </ScrollView>
  )
}