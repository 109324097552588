import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { ScrollView, Switch, View } from 'react-native-web';
import Icon from '../components/Icon';
import Table from '../components/Table';
import { ThemeContext } from '../contexts/ThemeContext';
import { setSettings, useSharedState } from '../state';
import { capitalize } from '../utils';

export default function SettingsGameScreen() {
  const [sharedState, dispatch] = useSharedState();
  const { settings } = sharedState;
  const Theme = React.useContext(ThemeContext);
  const navigation = useNavigation();
  const title = useRoute()?.params?.title;


  useFocusEffect(React.useCallback(() => {
    if (title) {
      navigation.setOptions({ title });
    }
  }, [title, navigation]))

  return (
    <View style={{
      flex: 1,
    }}>
      <ScrollView style={{
        paddingTop: 20
      }}>
        <Table
          title="CPU Difficulty"
          type="inset"
          style={{ marginBottom: 40 }}
        >
          {['easy', 'normal', 'hard'].map(it => (
            <Table.Item
              key={it}
              title={capitalize(it)}
              renderRightComponent={() => {
                if (settings.difficulty === it) {
                  return (
                    <Icon
                      type="checkmark"
                      tint={Theme.Colors.Primary}
                      withBackground={true}
                      style={{
                        marginVertical: -Theme.Dimensions.Smaller
                      }}
                    />
                  )
                }
              }}
              onPress={() => {
                dispatch(setSettings({
                  ...settings,
                  difficulty: it
                }))
              }}
            />
          ))}
        </Table>
        <Table
          type="inset"
          title="CPU Speed"
          style={{ marginBottom: 40 }}
        >
          {['slow', 'normal', 'fast'].map(it => (
            <Table.Item
              key={it}
              title={capitalize(it)}
              renderRightComponent={() => {
                if (settings.cpuSpeed === it) {
                  return (
                    <Icon
                      type="checkmark"
                      tint={Theme.Colors.Primary}
                      withBackground={true}
                      style={{
                        marginVertical: -Theme.Dimensions.Smaller
                      }}
                    />
                  )
                }
              }}
              onPress={() => {
                dispatch(setSettings({
                  ...settings,
                  cpuSpeed: it
                }))
              }}
            />
          ))}
        </Table>
        <Table
          type="inset"
          title="Two Player"
          description="In two player mode, this will flip the board so players face up on their turn."
        >
          <Table.Item
            title="Flip Board"
            renderRightComponent={() => (
              <Switch
                value={settings.flipBoardEnabled}
                onValueChange={(e) => {
                  dispatch(setSettings({
                    ...settings,
                    flipBoardEnabled: !settings.flipBoardEnabled
                  }));
                }}
              />
            )}
          />
        </Table>
      </ScrollView>
    </View>
  )
}

