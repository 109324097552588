import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ScrollView, View } from 'react-native-web';
import Table from '../components/Table';

export default function SettingsDesignSystemScreen() {
  const navigation = useNavigation();

  const TABLES = [
    {
      title: 'Foundations',
      options: [
        {
          title: 'Typography',
          onPress: () => navigation.navigate('DesignSystemTypography')
        },
        {
          title: 'Colors',
          onPress: () => navigation.navigate('DesignSystemColors')
        },
        {
          title: 'Dimensions',
          onPress: () => navigation.navigate('DesignSystemDimensions')
        }
      ]
    },
    {
      title: 'App Components',
      options: [
        {
          title: 'Button',
          onPress: () => {
            navigation.navigate('ComponentButton')
          }
        },
        {
          title: 'IconButton',
          onPress: () => {
            navigation.navigate('ComponentIconButton')
          }
        },
        {
          title: 'Avatar',
          onPress: () => navigation.navigate('ComponentAvatar')
        },
        {
          title: 'ChatBubble',
          onPress: () => { }
        },
        {
          title: 'Game',
          onPress: () => navigation.navigate('ComponentGame')
        },
        {
          title: 'Chessboard',
          onPress: () => navigation.navigate('ComponentChessboard')
        },
        {
          title: 'Confetti',
          onPress: () => navigation.navigate('ComponentConfetti')
        }
      ]
    },
    {
      title: 'System Components',
      options: [
        {
          title: 'Icon',
          onPress: () => navigation.navigate('ComponentIcon')
        },
        {
          title: 'ActionSheet',
          onPress: () => navigation.navigate('ComponentActionSheet')
        },
        {
          title: 'ContentSheet',
          onPress: () => navigation.navigate('ComponentContentSheet')
        },
        {
          title: 'Table',
          onPress: () => navigation.navigate('ComponentTable')
        }
      ]
    }
  ];

  return (
    <View style={{
      flex: 1,
    }}>
      <ScrollView style={{
        paddingTop: 20
      }}>
        {TABLES.map((it, index) => (
          <Table
            key={it.title}
            type="inset"
            title={it.title}
            style={{
              marginBottom: 40
            }}
          >
            {it.options.map((it) => (
              <Table.Item
                key={it.title}
                title={it.title}
                onPress={it.onPress}
              />
            ))}
          </Table>
        ))}
      </ScrollView>
    </View>
  )
}

