import { ReactComponent as King } from '../../images/b-k.svg';
import { ReactComponent as Queen } from '../../images/b-q.svg';
import { ReactComponent as Rook } from '../../images/b-r.svg';
import { ReactComponent as Bishop } from '../../images/b-b.svg';
import { ReactComponent as Knight } from '../../images/b-n.svg';
import { ReactComponent as Pawn } from '../../images/b-p.svg';
import {
  COLOR_BLACK,
  PIECE_BISHOP,
  PIECE_KING,
  PIECE_KNIGHT,
  PIECE_PAWN,
  PIECE_QUEEN,
  PIECE_ROOK,
} from '../../constants';
import { View } from 'react-native-web';

export default function PieceReadOnly({
  color,
  size,
  type,
  style = {}
}) {
  const PieceIcon = getPieceComponent(type);

  return (
    <View style={{
      width: size,
      height: size,
      justifyContent: 'center',
      alignItems: 'center',
      ...style
    }}>
      <PieceIcon
        style={{
          color: color === COLOR_BLACK ? 'black' : 'white',
          stroke: color === COLOR_BLACK ? 'white' : 'black',
          strokeWidth: 1,
          width: size * 0.8,
          height: size * 0.8
        }}
      />
    </View>
  );
}

function getPieceComponent(type) {
  switch (type){
    case PIECE_PAWN:
        return Pawn;
    case PIECE_KNIGHT:
        return Knight;
    case PIECE_BISHOP:
        return Bishop;
    case PIECE_ROOK:
        return Rook;
    case PIECE_QUEEN:
        return Queen;
    case PIECE_KING:
        return King;
    default:
      return null
  }
}