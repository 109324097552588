import React from 'react';
import { InteractionManager, Pressable, SafeAreaView, View } from 'react-native-web';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ChessboardReadOnly } from '../components/Chessboard/Chessboard';
import { useFocusEffect, useRoute } from '@react-navigation/native';

import {
  useScreenData,
  useMaxContentWidth,
} from '../hooks';
import { useSharedState } from '../state';
import { findGameById } from '../utils';
import { ThemeContext } from '../contexts/ThemeContext';
import Text from '../primitives/Text';
const Chess = require('chess.js');

export default function ReplayScreen() {
  const Theme = React.useContext(ThemeContext);
  const maxContentWidth = useMaxContentWidth()
  // const navigation = useNavigation();
  const route = useRoute();
  const { isPhone } = useScreenData();
  const [sharedState] = useSharedState();
  const { games } = sharedState;

  const [currentMoveIndex, setCurrentMoveIndex] = React.useState(0);
  const currentGame = findGameById(games, route?.params?.id);
  const [allPgns, setAllPgns] = React.useState([currentGame.pgn]);
  // const sliderRef = React.useRef(null);

  // const handleSliderChange = React.useCallback((value) => {
  //   setCurrentMoveIndex(value);
  // }, [setCurrentMoveIndex])

  //tbh if you keep these
  const currentPgn = allPgns[currentMoveIndex];
  // const chess = useChess(currentPgn);

  const safeAreaInset = useSafeAreaInsets()

  useFocusEffect(
    React.useCallback(() => {
      const task = InteractionManager.runAfterInteractions(async () => {

        // TODO: - replace with chess.moves()
        const allPgns = getAllPgns([currentGame.pgn])
        setAllPgns(allPgns);

        if (route?.params?.startFromEnd) {
          setCurrentMoveIndex(allPgns.length - 1)
        }
      });

      return () => task.cancel();
    }, [setAllPgns, currentGame?.pgn, route?.params?.startFromEnd])
  );

  return (
    <SafeAreaView
      style={[
        {
          flex: 1,
          alignItems: 'center',
        },
        safeAreaInset.bottom !== 0 && {
          marginBottom: Theme.Dimensions.Standard
        }
      ]}
    >
      <View style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: Theme.Dimensions.Largest,
      }}>
        <Text
          variant="Display"
          style={{
            marginBottom: Theme.Dimensions.Smaller,
          }}
        >
          Instant Replay
        </Text>
        <Text style={{
          ...Theme.Typography.Body
        }}>
          Tap the board to step through moves
        </Text>
      </View>
      <View
        style={[
          {
            flex: 1,
            justifyContent: 'center',
          },
            !isPhone && {
              justifyContent: 'center',
              alignItems: 'center'
            }
          ]}
        >
        <View style={[{
          position: 'relative',
          width: maxContentWidth,
          height: maxContentWidth,
          justifyContent: 'center',
          alignItems: 'center'
        }]}>
          <ChessboardReadOnly
            size={maxContentWidth - (Theme.Dimensions.Standard * 2)}
            pgn={currentPgn}
          />
          <View style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            flexDirection: 'row'
          }}>
            <Pressable
              onPress={() => {
                if (currentMoveIndex === 0) {
                  return;
                }

                setCurrentMoveIndex(currentMoveIndex - 1)
                // sliderRef.current.slideToIndex(currentMoveIndex - 1)
              }}
              onLongPress={() => {
                // skip to first move
                setCurrentMoveIndex(0)
              }}
              style={{
                flex: 1,
                alignSelf: 'stretch',
                // backgroundColor: 'red'
              }}
            />
            <Pressable
              onPress={() => {
                if (currentMoveIndex === allPgns.length - 1) {
                  return;
                }

                setCurrentMoveIndex(currentMoveIndex + 1)
                // sliderRef.current.slideToIndex(currentMoveIndex + 1)
              }}
              onLongPress={() => {
                // skip to last move
                setCurrentMoveIndex(allPgns.length - 1)
              }}
              style={{
                flex: 1,
                alignSelf: 'stretch',
                // backgroundColor: 'blue'
              }}
            />
          </View>
        </View>
      </View>
      {/* <View style={{
        width: maxContentWidth
      }}>
        <Slider
          ref={sliderRef}
          moves={allPgns}
          style={{
            margin: Theme.Dimensions.Standard,
            marginBottom: Theme.Dimensions.Large,
          }}
          onValueChange={handleSliderChange}
        />
      </View> */}
    </SafeAreaView>
  )
}

function getPreviousPgn(pgn) {
  const chess = new Chess();
  chess.load_pgn(pgn)
  chess.undo();

  return chess.pgn();
}

function getAllPgns(pgns = ['']) {
  if (pgns[0] === '') {
    return pgns;
  }

  const prevPgn = getPreviousPgn(pgns[0]);
  return getAllPgns([prevPgn, ...pgns]);
}