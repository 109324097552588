import React from "react";
import { ScrollView } from "react-native-web";
import { ThemeContext } from "../../contexts/ThemeContext";
import Game from '../../components/Game';

export default function ComponentGameScreen() {
  const Theme = React.useContext(ThemeContext);

  return (
    <ScrollView style={{
      paddingTop: Theme.Dimensions.Large
    }}>
      <Game
        title="Active Game"
        body="started some time ago"
        pgn=""
      />
      <Game
        title="Game Over"
        body="completed some time ago"
        pgn="1. e4 b5 2. d4 h5 3. Bxb5 f5 4. exf5 g5 5. Qe2 a5 6. h3 Rh7 7. g4 hxg4 8. Qxg4 Rxh3 9. Qxh3 Na6 10. Qh5#"
        isDeletable
      />
    </ScrollView>
  )
}