import { View } from "react-native-web";
import EmojiImage from "./EmojiImage";

const AVATAR_SIZE = 100;

export default function EmojiAvatar({ color, emoji, size = AVATAR_SIZE, style = {} }) {
  return (
    <View
      style={[{
        width: size,
        height: size,
        borderRadius: size,
        backgroundColor: color,
        justifyContent: 'center',
        alignItems: 'center'
      }, style]}
    >
      <EmojiImage name={emoji} size={size / 1.6 } />
    </View>
  )
}